import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import image from "../../../../Assests/image 1.png";
import ArrayImg from "../../../../Assests/Frame 997.png";
import { NavLink } from "react-router-dom";

import axios from "../../../../utils/axios";

// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import TableUser from "./TableUser";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const Selected = styled.input`
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  /* Base/Grey 80 */

  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 4px;
`;
const HeaderTitle = styled.div``;

const BodyUsersContent = styled.div`
  margin: 0px;
  padding: 12px 24px;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const IconSection = styled.div``;

const PaginationSection = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HataListesiBody = ({data,getErrors,loading}) => {


  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row">
       
          <HeaderTitle className="col">Kullaıcı Adı</HeaderTitle>
          <HeaderTitle className="col">Tarih</HeaderTitle>
          <HeaderTitle className="col">Kaynak</HeaderTitle>
          <HeaderTitle className="col">Mesaj</HeaderTitle>
          <HeaderTitle className="col">Detay</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? <NoneDataError /> : (
          data.map((i, index) => (
            <TableUser  key={index} item={i} index={index}  getErrors={getErrors}   />
          ))
        )}

      </Container>
    </>
  );
};

export default HataListesiBody;
