import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BagislarBody from "./BagislarBody";
// import MakeDonation from "./MakeDonation";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import Add from "../../../Assests/Add2.png";
import Heart from "../../../Assests/Heart.png";
import Headers from "../../../Components/PageComponents/HeadersMain";
import NavbarNew from "../../../Components/PageComponents/NavbarNew";
import axios from "../../../utils/axios";

const Container = styled.div`
  height: 100%;
  position: relative;
`;
const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;
const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  &:hover {
    background: #246cf9;
  }

  &:focus {
    background: #246cf9;
  }

  &:active {
    background: #246cf9;
  }
`;
const Bagislar = () => {
  const navigate = useNavigate();
  const [Props, setProps] = useState({
    title: "Bağışlar",
    img: Heart,
    navbarButtonTitle: "",
    path: "",
  });

  // const [checkedAll, setCheckedAll] = useState(false)
  // const CheckProps = {
  //   checkedAll : checkedAll,
  //   setCheckedAll: setCheckedAll
  // }

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  // Dropdowns
  const [responseDataLocation, setResponseDataLocation] = useState([]);
  const [responseDataTypes, setResponseDataTypes] = useState([]);
  const [responseDataChannels, setResponseDataChannels] = useState([]);
  const [responseDataStatus, setResponseDataStatus] = useState([]);

  // Locaiton - Genel Yardım Merkezi
  const getLocaitonDropdowns = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationLocations", {
      params: params,
    });
    try {
      await response;
      setResponseDataLocation(Object.values(response.data));
    } catch (error) {}
  };

  // DonationTypes - Bebek bezi, Bebek maması
  const getDonationTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationTypes", {
      params: params,
    });
    try {
      await response;
      setResponseDataTypes(Object.values(response.data));
    } catch (error) {}
  };

  // DonationTypesChannel - Mobil Web
  const getDonationRequestChannels = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestChannels", {
      params: params,
    });
    try {
      await response;
      setResponseDataChannels(Object.values(response.data));
    } catch (error) {}
  };

  // Donations Status - Beklemede, İptal
  const getDonationStatus = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestStatus", {
      params: params,
    });
    try {
      await response;
      setResponseDataStatus(Object.values(response.data));
    } catch (error) {}
  };

  useEffect(() => {
    getDonationStatus();
    getDonationRequestChannels();
    getDonationTypes();
    getLocaitonDropdowns();
  }, []);

  useEffect(() => {
    getDonations(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getDonations = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 20 };
      setFilterParams(params);
      const response = await axios.get("donations/list", {
        params: params,
      });
      await response;

      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      name: "",
      tc: "",
      publishTitle: "",
      donationRequestChannelId: "",
      donationTypeId: "",
      donationStatuId: "",
      donationLocationId: "",
      isPublished: "",
      createdDate: dayjs().format("YYYY-MM-DD"),
    },
    getFilterData: getDonations,
    setPage: setPage,
    adInput: "Ad ile Arama Yapın",
    tcInput: "T.C. İle Arama Yapın",
    baslikInput: "Başlık ile Arama Yapın",
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
    kanalDropDown: responseDataChannels,
    tipDropDown: responseDataTypes,
    konumDropDown: responseDataLocation,
    durumDropDown: responseDataStatus,
    createdDate: responseData?.createdDate,
  };

  return (
    <Container>
      <Headers Props={Props} />
      {/* <Navbars Props={Props} FilterCategories={FilterCategories} /> */}
      <Stack
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "end",
          gap: "20px",
        }}
      >
        <StyledButton onClick={() => navigate("bagis/yeni")}>
          <DropdownIcon src={Add} alt="" />
          Bağış Ekle
        </StyledButton>

        <NavbarNew Props={Props} FilterCategories={FilterCategories} />
      </Stack>
      <BagislarBody
        data={responseData.length > 0 ? responseData : []}
        getData={(filterParams) => getDonations(filterParams)}
        // checkFunc={onCheckBoxClick}
        // checkProps={checkProps}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </Container>
  );
};

export default Bagislar;
