import React, { useState } from 'react'
import HeadersPageInside from "../../../../Components/PageComponents/HeadersPagesInside"
import Location from "../../../../Assests/Location.png"
import BagisNoktalariDetaylariBody from "../BagisNoktalariDetaylari/BagisNoktalariDetaylariBody"

const BagisNoktalariDetaylariHome = () => {

  const [Props, setProps] = useState({
    img: Location,
    title: "Bağış Noktası Detayları",
    main: "Bağış Noktaları",
    titleText: "Bağış Noktası Detayları",
  })

  return (
    <div>
      <HeadersPageInside Props={Props} />
      <BagisNoktalariDetaylariBody />
    </div>
  )
}

export default BagisNoktalariDetaylariHome