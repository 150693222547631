import React, { useState } from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import image from "../../../../Assests/image 1.png";
import BBB_Logo_Small from "../../../../Assests/BBB_Logo_Small.png";
import TableUser from "./TableUser";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const Selected = styled.input`
  box-sizing: border-box;

  width: 20px;
  height: 20px;

  /* Base/Grey 80 */

  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 4px;
`;
const HeaderTitle = styled.div``;

const BodyUsersContent = styled.div`
  margin: 0px;
  padding: 12px 24px;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const IconSection = styled.div``;

const PaginationSection = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 8px;
  gap: 8px;
  background: #1e1f25;
  border-radius: 10px;
`;

const CardLogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  background: #246cf9;
  border-radius: 50px;
`;

const CardLogo = styled.img``;

const CardLogoTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* Main Color/Royalblue 100 */

  color: #246cf9;
`;

const CardMessage = styled.div`
  text-align: start;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const InsideCardContainer = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const CardTitle = styled.div`
  padding-top: 8px;
  width: 100%;
  border-top: 1px solid #282c38;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const InsideRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3px;
  text-align: start;
`;

const InsideRowLeft = styled.div`
  background: #282c38;
  border-radius: 10px;
`;

const InsideCardTitle = styled.div`
  overflow: hidden; // Alanın dışına taşanları gizle
  white-space: nowrap; // Alt satıra geçmeyi engelle
  text-overflow: ellipsis;

  padding: 8px;
  gap: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const InsideRowRight = styled.div`
  background: #282c38;
  border-radius: 10px;
`;

const BildirimListesiBody = ({ data, getNotificaitons, loading }) => {
  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row">
          <HeaderTitle className="col-2 text-start">Log Tipi</HeaderTitle>
          <HeaderTitle className="col">Gönderilen</HeaderTitle>
          <HeaderTitle className="col">Tarih</HeaderTitle>
          <HeaderTitle className="col">Detay</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? <NoneDataError /> : (
          data.map((i, index) => (
            <TableUser
              key={index}
              item={i}
              index={index}
              getNotificaitons={getNotificaitons}
            />
          ))
        )}
      </Container>
    </>
  );
};

export default BildirimListesiBody;
