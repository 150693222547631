import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../utils/axios";

import Lock from "../../../../Assests/Lock.png";
import NoneImage from "../../../../Assests/Image 7.png";

// Checkbox React Bootstrap
// import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";

// Formik for Form
import { useFormik } from "formik";

import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import Alerts from "../../../../Components/PageComponents/Alerts";
import { validationSchemaDonationRequest } from "../../../../Components/FormValidations/FomikYup";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";

const Container = styled.div`
  /* height: 100%; */
  margin-top: 12px;
`;

const HeaderTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.small ? "10px" : "16px")};
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin: 8px 0px;
`;

const Row = styled.form``;

const RowInside = styled.div`
  position: relative;
  /* height: 530px; */
  padding: 16px;
  gap: 8px;

  background: #1e1f25;
  border-radius: 20px;
`;

const InputContainer = styled.div``;

const InputContainerInside = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;

const InputLeft = styled.input`
  width: 100%;

  padding: 16px;
  gap: 8px;

  background: #282c38;

  border: 1px solid #3c4254;
  border-radius: 10px;
  /* Base Color/Grey 60 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const InputIcon = styled.img`
  width: 24px;
  height: 24px;

  position: absolute;
  right: 8px;

  top: ${(props) => (props.textarea ? "12px" : "")};
`;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const TextareaContainer = styled.div``;

const TextareaContainerInside = styled.div`
  position: relative;
  display: flex;
`;

const TextareaLeft = styled.textarea`
  padding: 16px 32px 16px 16px;
  gap: 8px;

  height: 130px;

  width: 100%;

  resize: none;

  background: #282c38;
  /* Base Color/Grey 60 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::-webkit-scrollbar {
    width: 0px;
  }

  border: 1px solid #3c4254;
  border-radius: 10px;

  &::placeholder {
    color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};
  }
`;

const RowLeft = styled.div``;

const RowRight = styled.div``;

const RowInsideTop = styled.div``;

const RowBottom = styled.div`
  padding: 16px;
  /* gap: 16px; */

  background: #1e1f25;
  border-radius: 20px;
`;

const ButtonContainer = styled.button`
  /* position: absolute; */
  height: 42px;
  /* bottom: 8px; */
  display: flex;
  align-items: center;
  justify-content: center;

  background: #246cf9;
  border-radius: 10px;
  border: none;

  width: 95%;
`;

const Button = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  padding: 9px 10px;
  gap: 8px;

  color: #ffffff;
`;

const SelectDropDown = styled.select`
  cursor: pointer;
  width: 100%;

  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #ffffff;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
`;

const UploadImagesSection = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: scroll;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #282c38;
    border-radius: 12px;
    border: solid 5px #1e1f25;
  }
`;

const UploadImagesContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const UploadImages = styled.img`
  width: 200px;
  height: 200px;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;

const NoneImgContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const NoneImg = styled.img``;

const NoneImgText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const TalepDetaylariBody = () => {
  const [responseData, setResponseData] = useState({});
  const [responseDataLocation, setResponseDataLocation] = useState([]);
  const [responseDataTypes, setResponseDataTypes] = useState([]);
  const [responseDataRequestStatus, setResponseDataRequestStatus] = useState(
    []
  );

  const parse = require("html-react-parser");

  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getDonationLocaiton();
    getDonationTypes();
    getDonationRequestStatus();
    getDonationId();
    // UpdateDonationForm();
  }, []);

  const getDonationId = async () => {
    const params = {
      id: id,
    };

    try {
      const response = await axios.get("donationRequests/donationRequest", {
        params: params,
      });

      setResponseData(response.data);
      donationForm.setValues({ tc: Number(responseData.tc) });
    } catch (error) {
      console.log(error);
    }
  };

  const donationForm = useFormik({
    initialValues: {
      id: 0,
      donationRequestStatuId: 0,
      result: "",
      tc: 0,
    },

    validationSchema: validationSchemaDonationRequest,

    onSubmit: (values) => {
      const payload = { tc: Number(values.tc) };

      UpdateDonationForm(values);
      console.log(error);
    },
  });

  const getDonationLocaiton = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationLocations", {
      params: params,
    });
    try {
      await response;
      setResponseDataLocation(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const getDonationTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationTypes", {
      params: params,
    });
    try {
      await response;
      setResponseDataTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Dropdown Reequest Ulaşıldı..
  const getDonationRequestStatus = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestStatus", {
      params: params,
    });
    try {
      await response;
      setResponseDataRequestStatus(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // DontaionForm Update
  const UpdateDonationForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      const payload = {
        ...responseData,
        ...values,

        tc: parseInt(values.tc) || 0,
        images: null,
      };

      const response = await axios.put(
        `donationRequests/donationRequest?id=${id} `,
        payload
      );
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };
  function cleanHtmlTags(htmlString) {
    if (!htmlString) {
      return "";
    }
    return htmlString.replace(/<[^>]*>/g, " ").replace(/&nbsp;/g, " ");
  }

  useEffect(() => {
    donationForm.setValues({
      id: id,
      donationRequestStatuId: responseData.donationRequestStatuId,
      result: responseData.result,
      images: responseData.images,
      tc: Number(responseData.tc),
    });
  }, [responseData]);

  return (
    <Container>
      <Row className="row" onSubmit={donationForm.handleSubmit}>
        {loading && <OverlayLoading />}
        {error ? (
          <Alerts result={false} />
        ) : success ? (
          <Alerts result={true} />
        ) : (
          <></>
        )}
        <RowLeft className="col-6">
          <HeaderTitle>Bağışçının Kişisel Bilgileri</HeaderTitle>
          <RowInside>
            <InputContainer>
              <InputTitle>Ad Soyad</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.name}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>T.C. Kimlik No</InputTitle>
              <InputContainerInside>
                <InputLeft
                  name="tc"
                  placeholder={`${
                    responseData.tc === null ? "" : responseData.tc
                  }`}
                  value={donationForm.values.tc || ""}
                  onChange={donationForm.handleChange}
                />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>Telefon Numarası</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.tel}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>E-Mail Adresi</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.email}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>Adres</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  disabled
                  placeholder={`${responseData.address}`}
                />
                <InputIcon textarea src={Lock} />
              </TextareaContainerInside>
            </InputContainer>
          </RowInside>
        </RowLeft>

        <RowRight className="col-6">
          <HeaderTitle>Talep Bilgileri</HeaderTitle>

          <RowInside>
            <RowInsideTop className="row">
              <InputContainer className="col">
                <InputTitle>Talep Tipi</InputTitle>
                <InputContainerInside>
                  <InputLeft
                    disabled
                    placeholder={responseDataTypes
                      .map((i) =>
                        i.id === responseData.donationTypeId ? `${i.name}` : ``
                      )
                      .join("")}
                  />
                  <InputIcon src={Lock} />
                </InputContainerInside>
              </InputContainer>

              <InputContainer className="col">
                <InputTitle>Talep İstek Durumu</InputTitle>

                <SelectDropDown
                  aria-label="Default select example"
                  onChange={donationForm.handleChange}
                  name="donationRequestStatuId"
                  value={donationForm.values.donationRequestStatuId}
                >
                  {responseDataRequestStatus.map((i) => (
                    <SelectOption key={i.id} value={i.id}>
                      {i.id === 3 ? "Ulaşılamadı" : `${i.name} `}
                    </SelectOption>
                  ))}
                </SelectDropDown>
              </InputContainer>
            </RowInsideTop>

            <TextareaContainer>
              <InputTitle>Talep Açıklaması</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  disabled
                  placeholder={`${cleanHtmlTags(responseData.explanation)}`}
                />
                <InputIcon textarea src={Lock} />
              </TextareaContainerInside>
            </TextareaContainer>

            <TextareaContainer style={{ margin: "48px 0px 14px 0px" }}>
              <InputTitle>Talep Sonucu (Editör/Görevli Görüşü)</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  name="result"
                  value={cleanHtmlTags(donationForm.values.result) || ""}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </TextareaContainerInside>
              {donationForm.touched.result && donationForm.errors.result ? (
                <AlertTextYup Props={donationForm.errors.result} />
              ) : null}
            </TextareaContainer>

            <ButtonContainer type="submit">
              <Button>Talep Sonucumu Kaydet</Button>
            </ButtonContainer>
          </RowInside>
        </RowRight>

        <HeaderTitle>İletilen Fotoğraflar</HeaderTitle>
        <RowBottom className="col-6">
          {donationForm.values.images?.length ? (
            <UploadImagesContainer>
              <UploadImagesSection>
                {donationForm.values.images.map((image, index) => (
                  <UploadImages
                    key={image.id}
                    src={image.image}
                    alt={`Image ${index}`}
                  />
                ))}
              </UploadImagesSection>
            </UploadImagesContainer>
          ) : (
            <NoneImgContainer>
              <NoneImg src={NoneImage} />
              <NoneImgText>
                Talebi oluşturan kişi tarafından iletilen herhangi bir fotoğraf
                bulunmamaktadır.
              </NoneImgText>
            </NoneImgContainer>
          )}
        </RowBottom>
      </Row>
    </Container>
  );
};

export default TalepDetaylariBody;
