import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Delete from "../../../../Assests/Delete.png";
import Dots from "../../../../Assests/Dots.png";
import Edit from "../../../../Assests/Edit2.png";
import Upload from "../../../../Assests/ImageUpload.png";
import { HOST_API_URL } from "../../../../config";
// Bootstrap Dropdown
import Dropdown from "react-bootstrap/Dropdown";

import * as Yup from "yup";
import axios from "../../../../utils/axios";

// CK Editor
// import parse from "html-react-parser";

// Modal Bootstrap
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;
const TitleImg = styled.img`
  /* width: 75%; */
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;
const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: start;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

// Dropdown Islemler

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const UploadContainer = styled.div`
  margin-top: 16px;
  padding: 0px;
  gap: 18px;
  background: #282c38;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Base Color/Grey 40 */

  border: 1px solid ${({ hasError }) => (hasError ? "#ff0000" : "#757b8c")};
  border-radius: 10px;
`;

const UploadIcon = styled.img`
  /* width: 56px;
height: 56px; */

  width: ${(props) => (props.small ? "32px" : "42px")};
  height: ${(props) => (props.small ? "32px" : "42px")};
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

// Dropdown PopUp Section Inside

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const StyledButton = styled(Button)`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;

  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const HeaderTitle = styled.div`
  margin: 32px 0px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const HeaderTitleSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 60 */
  color: #3c4254;
`;
const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;
const StyledLabel = styled.label`
  background: #246cf9;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;
const UploadImgBig = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: grayscale(100%);

  border: 1px solid #757b8c;
  border-radius: 10px;
`;
const UploadImgSmall = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
`;
const StyledLabelLeft = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;
const ModalHeader = styled.div`
  margin-bottom: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid ${({ hasError }) => (hasError ? "#ff0000" : "#3c4254")};
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;

    &:focus {
      outline: none;
      border: 1px solid ${({ hasError }) => (hasError ? "#ff0000" : "#3c4254")};
    }
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CkEditorSection = styled.div``;

const InputContainer = styled.div``;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const TableUser = ({ i, index, checkedAll, getSlider }) => {
  // const [checked, setChecked] = useState(false);
  const parse = require("html-react-parser");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [checked, setChecked] = useState(false);
  const [isLocalFile, setIsLocalFile] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setChecked(checkedAll === true ? true : false);
  }, [checkedAll]);

  const deleteItem = async () => {
    const params = {
      id: i.id,
    };
    const response = await axios.delete("Sliders/slider", {
      params: params,
    });
    try {
      await response;

      getSlider();
    } catch (error) {
      console.error(error);
    }
  };

  // Dropdown Section

  const [responseData, setResponseData] = useState({});

  useEffect(() => {
    // getAnnouncementId();
  }, []);

  useEffect(() => {
    sliderForm.setValues({
      id: responseData.id ?? "",
      description: responseData?.description || "",
      isActive: responseData.isActive ?? "",
      // image: null,
      imageExtension: null,
      image: responseData?.image,
    });
  }, [responseData]);

  const getAnnouncementId = async () => {
    const params = {
      id: i.id,
    };

    try {
      const response = await axios.get("Sliders/Slider", {
        params: params,
      });
      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const sliderForm = useFormik({
    initialValues: {
      id: "",
      description: "",
      image: "",
      isActive: false,
    },

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Zorunlu alana"),
      image: Yup.string().required("Zorunlu alana"),
    }),

    onSubmit: async (values) => {
      await UpdateSliderForm(values);
      // await getSlider();
      // handleClose();
    },
  });

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      sliderForm.setFieldValue("image", reader.result.split(",")[1]);

      sliderForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);

    setIsLocalFile(true);
  };

  useEffect(() => {
    sliderForm.setValues({
      id: responseData.id ?? "",
      description: responseData?.description || "",
      isActive: responseData.isActive ?? "",
      image: responseData?.image ?? "",
    });
  }, [responseData]);

  // DontaionForm Update
  const UpdateSliderForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`Sliders/slider?id=${i.id}`, params);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
      handleClose();
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getSlider();
    setIsLocalFile(false);
  };

  const handleShow = () => setShow(true);

  return (
    <BodyUsersContent
      className="row text-start gx-5 w-100 justify-content-between"
      key={i.id}
      style={{
        background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
      }}
    >
      <TitleImg className="object-fit-contain p-0" src={`${HOST_API_URL}/files/${i.image}`} alt="" />
      <TitleName className="col-5 text-end"> {i.description} </TitleName>
      <TitleName className="col"></TitleName>
      {i.isActive ? (
        <TitleButton className="col-1">Aktif</TitleButton>
      ) : (
        <TitleButtonRed className="col-1">Pasif</TitleButtonRed>
      )}
      <DropdownSection className="col-1 ">
        <DropDownButton id="dropdown-basic">
          <IconDots src={Dots} />
        </DropDownButton>

        <DropDownMenu>
          <DropDownMenuItem>
            <StyledButton
              onClick={() => {
                getAnnouncementId();
                handleShow();
              }}
            >
              <DropdownIcon src={Edit} alt="" />
              <DropdownText>Düzenle</DropdownText>
            </StyledButton>
          </DropDownMenuItem>

          <DropDownMenuItem>
            <DeleteSection
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              <DropdownIcon src={Delete} alt="" />
              <DropdownText>Sil</DropdownText>
            </DeleteSection>
          </DropDownMenuItem>
        </DropDownMenu>
      </DropdownSection>

      {/* Open Modal */}
      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container onSubmit={sliderForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
          <ModalHeader>Slider Düzenle</ModalHeader>
          <ModalInputContainer>
            <ModalTitle>Slider Adı</ModalTitle>
            <ModalInput
              name="description"
              value={sliderForm.values.description}
              onChange={sliderForm.handleChange}
              hasError={(sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)}
            />
            {((sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)) && (
              <span style={{ color: "#ff0000", fontSize: "12px" }}>
                {(sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)}
              </span>
            )}
          </ModalInputContainer>

          <UploadContainer>
            <StyledLabelLeft htmlFor="fileUpload">
              <UploadImgBig
                src={isLocalFile ? `data:image/png;base64,${sliderForm.values.image}` : responseData?.image}
              />
              <UploadImgSmall src={Upload} />
            </StyledLabelLeft>

            <InputUpload type="file" id="fileUpload" onChange={handleImgUpload} />
          </UploadContainer>
          <ModalInputContainer>
            <ModalTitle className="mt-4">Slider Durumu</ModalTitle>
            <ModalInputSection>
              <Form.Check
                type="checkbox"
                name="isActive"
                checked={sliderForm.values.isActive}
                onChange={() => {
                  sliderForm.setFieldValue("isActive", !sliderForm.values.isActive);
                }}
              />

              <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
            </ModalInputSection>
          </ModalInputContainer>
          <div>
            <ModalButton type="submit" onClick={sliderForm.handleSubmit}>
              Slider Düzenle
            </ModalButton>
          </div>
        </Container>
      </OpenModal>

      <OpenModal show={deleteModal} size="md" centered onHide={() => setDeleteModal(false)} animation={false}>
        <Container>
          <h4 style={{ color: "#ffff", marginBottom: "32px" }}>Silme İstediğinize Emin Misiniz?</h4>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px" }}>
            <button
              onClick={() => setDeleteModal(false)}
              style={{
                cursor: "pointer",
                border: "none",
                border: "1px solid #ffff",
                backgroundColor: "transparent",
                borderRadius: "10px",
                color: "#ffff",
                width: "120px",
                padding: "6px",
              }}
            >
              Hayır
            </button>

            <button
              onClick={() => deleteItem()}
              style={{
                cursor: "pointer",
                border: "1px solid #ff0000",
                background: "#ff0000",
                borderRadius: "10px",
                width: "120px",
                padding: "6px",
                color: "#ffff",
              }}
            >
              Evet
            </button>
          </div>
        </Container>
      </OpenModal>
    </BodyUsersContent>
  );
};

export default TableUser;
