import React, { useEffect, useState } from "react";

import Headers from "../../../../Components/PageComponents/HeadersMain";
import Navbars from "../../../../Components/PageComponents/NavbarNew";

import SliderImg2 from "../../../../Assests/Slider2.png";
import axios from "../../../../utils/axios";
import SliderBody from "./SliderBody";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import NewSliderPopUp from "../Modals/NewSliderPopUp";

import styled from "styled-components";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Slider = () => {
  //   const [Props, setProps] = useState({
  //     title: "Slider",
  //     img: SliderImg2
  // })

  const [Props, setProps] = useState({
    title: "Slider",
    img: SliderImg2,
    navbarFilter: false,
    popUp: <NewSliderPopUp getSlider={(values) => getSlider(values)} />,
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getSlider(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  //ALL SLIDERS
  const getSlider = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 20 };
      setFilterParams(params);
      const response = await axios.get("Sliders/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      title: "",
      isActive: "",
    },
    getFilterData: getSlider,
    setPage: setPage,
    // titleInput: "Başlık İçinde Ara",
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
  };

  return (
    <div>
      <Headers Props={Props} />
      {/* <SliderNavbar /> */}
      <Navbars Props={Props} FilterCategories={FilterCategories} />

      <SliderBody
        data={responseData.length > 0 ? responseData : []}
        getSlider={(filterParams) => getSlider(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default Slider;
