import React, { useState } from "react";
import styled from "styled-components";
// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import TableUser from "./TableUser";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const HeaderTitle = styled.div`
  text-align: left;
`;

const DuyurularMainBody = ({ data, getAnnouncements, loading }) => {
  // const [checked, setChecked] = useState(false);

  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row text-center gx-5">
          {/* <div className="col-1 p-0">
            <Form.Check
              type="checkbox"
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              id={`default-checkbox`}
            />
          </div> */}

          <HeaderTitle className="col-4">Başlık</HeaderTitle>
          <HeaderTitle className="col">İçerik</HeaderTitle>

          <HeaderTitle className="col-1" style={{ textAlign: "center" }}>
            Yayin Durumu
          </HeaderTitle>
          <HeaderTitle className="col-1" style={{ textAlign: "center" }}>
            İşlemler
          </HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? <NoneDataError /> : (
          data.map((i, index) => (
            <TableUser
              key={index}
              i={i}
              index={index}
              getAnnouncements={getAnnouncements}
            />
          ))
        )}
      </Container>
    </>
  );
};

export default DuyurularMainBody;
