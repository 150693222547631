import React from "react";
import { Doughnut } from "react-chartjs-2";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  width: 150px;
  height: 150px;
  position: relative;
`;

const ChartTitle = styled.div`
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const DoughnutChartBagislar = ({ percent }) => {
  const prcnt = percent * 100;

  return (
    <Container>
      <Row>
        <ChartTitle>%{percent.toFixed(2).replace("0.", "")}</ChartTitle>
        <Doughnut
          options={{
            cutout: "85%",
          }}
          data={{
            datasets: [
              {
                label: "Users Gained",
                // data: [100, percent.toFixed(2).replace("0.","")],
                data: [prcnt, 100 - prcnt],
                backgroundColor: ["#FFB545", "transparent"],
                borderColor: ["#FFB545", "transparent"],
              },
            ],
          }}
        />
      </Row>
    </Container>
  );
};

export default DoughnutChartBagislar;
