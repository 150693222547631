import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Alert from "../../../../Assests/Danger Circle.png";
import Upload from "../../../../Assests/ImageUpload.png";

// ckEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// Checkbox React Bootstrap
// import Form from "react-bootstrap/Form";
import { useFormik } from "formik";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationMayorMessage } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const Container = styled.div``;

const Row = styled.form`
  display: flex;
  margin-top: 16px;
  gap: 8px;
  height: 100%;
`;

const RowLeft = styled.div`
  max-width: 50%;
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
  flex: 5;
`;

const RowRight = styled.div`
  max-width: 50%;
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
  flex: 7;
  display: flex;
  flex-direction: column;
  row-gap: 5rem;
  height: 619px;
`;

const AlertContainer = styled.div`
  box-sizing: border-box;
  padding: 8px;
  gap: 8px;
  background: rgba(255, 181, 69, 0.3);
  /* Accent Color/Orange */

  border: 1px solid #ffb545;
  border-radius: 10px;
`;

const AlertIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 8px;

  display: flex;
  justify-content: center;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;
  height: 300px !important;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 6px;
`;

const InputTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 16px;

  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CkEditorSection = styled.div`
  height: 500px important;
`;

const UploadContainer = styled.div`
  margin-top: 16px;
  padding: 0px;
  gap: 18px;
  background: #282c38;

  width: 100%;
  // height: 403px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Base Color/Grey 40 */

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;

const UploadImgBig = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  border: 1px solid #757b8c;
  border-radius: 10px;
`;
const UploadImgSmall = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
`;

const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const BaskaninMesajiBody = () => {
  const [responseData, setResponseData] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getMayorMsg();
  }, []);

  const getMayorMsg = async () => {
    const response = await axios.get("mayorMessage/mayorMessage");
    try {
      await response;
      setResponseData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const mayorMsgForm = useFormik({
    initialValues: {
      text: "",
      image: "",
      imageExtension: "",
      updatedInfo: "",
    },
    validationSchema: validationMayorMessage,

    onSubmit: (values) => {
      updateMayorMsg(values);
    },
  });

  useEffect(() => {
    mayorMsgForm.setValues({
      text: responseData.text ?? "",
      image: null,
      imageExtension: null,
      updatedInfo: responseData.updatedInfo ?? "",
    });
  }, [responseData]);

  // updateMayorMsg Update
  const updateMayorMsg = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`mayorMessage/mayorMessage`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const URLimg = "data:image/png;base64,";

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      mayorMsgForm.setFieldValue("image", reader.result.split(",")[1]);

      mayorMsgForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <Container>
        {loading && <OverlayLoading />}
        {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
        <Row onSubmit={mayorMsgForm.handleSubmit} className="d-flex align-items-start ">
          <RowLeft>
            <AlertContainer>
              <AlertIconContainer>
                <AlertIcon src={Alert} />
              </AlertIconContainer>
              <AlertText>
                Yüklediğiniz görsel en az Genişlik(1000px) x Yükseklik(675px) boyutlarında ve yüksek kaliteli olmalıdır.
                Aksi taktirde mobil uygulamada düşük çözünürlükte görünür.
              </AlertText>
            </AlertContainer>

            {/* <ImgContainer>
              <UploadIcon src={Upload} />
            </ImgContainer> */}

            <UploadContainer>
              <StyledLabel htmlFor="fileUpload">
                <UploadImgBig
                  src={mayorMsgForm.values.image ? URLimg + mayorMsgForm.values.image : responseData.image}
                />
                <UploadImgSmall src={Upload} />
              </StyledLabel>

              <InputUpload type="file" id="fileUpload" onChange={handleImgUpload} />
            </UploadContainer>
          </RowLeft>
          <RowRight>
            <InputContainer>
              <InputTitle>Sayfa Başlığı</InputTitle>
              <CkEditorSection>
                <CKEditor
                  editor={ClassicEditor}
                  data={mayorMsgForm.values.text || ""}
                  style={{ height: "400px !important" }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    mayorMsgForm.setFieldValue("text", data);
                  }}
                />
              </CkEditorSection>
              {mayorMsgForm.touched.text && mayorMsgForm.errors.text ? (
                <AlertTextYup Props={mayorMsgForm.errors.text} />
              ) : null}
              <ModalButton type="submit">Sayfayı Güncelle</ModalButton>
            </InputContainer>
          </RowRight>
        </Row>
      </Container>
    </>
  );
};

export default BaskaninMesajiBody;
