import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Dots from "../../../../Assests/Dots.png";
import Edit from "../../../../Assests/Edit2.png";

import Delete from "../../../../Assests/Delete.png";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaAnnouncement } from "../../../../Components/FormValidations/FomikYup";

// Bootstrap Dropdown
import Dropdown from "react-bootstrap/Dropdown";

import axios from "../../../../utils/axios";

// CK Editor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// Modal Bootstrap
import { useFormik } from "formik";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: start;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

// Dropdown Islemler

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

// Dropdown PopUp Section Inside

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const StyledButton = styled(Button)`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;

  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const HeaderTitle = styled.div`
  margin: 32px 0px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const HeaderTitleSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 60 */
  color: #3c4254;
`;

const ModalHeader = styled.div`
  margin-bottom: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CkEditorSection = styled.div``;

const InputContainer = styled.div``;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const TableUser = ({ i, index, checkedAll, getAnnouncements }) => {
  // const [checked, setChecked] = useState(false);
  const parse = require("html-react-parser");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);

  const deleteItem = async () => {
    const params = {
      id: i.id,
    };
    const response = await axios.delete("announcements/announcement", {
      params: params,
    });
    try {
      await response;

      getAnnouncements();
    } catch (error) {
      console.error(error);
    }
  };

  const [responseData, setResponseData] = useState({});

  const getAnnouncementId = async () => {
    const params = {
      id: i.id,
    };

    try {
      const response = await axios.get("announcements/announcement", {
        params: params,
      });

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const announcementForm = useFormik({
    initialValues: {
      id: "",
      title: "",
      text: "",
      isActive: false,
    },

    validationSchema: validationSchemaAnnouncement,

    onSubmit: async (values) => {
      await UpdateAnnouncementForm(values);
      // await getAnnouncements();
      // handleClose();
    },
  });

  useEffect(() => {
    announcementForm.setValues({
      id: responseData.id ?? "",
      title: responseData.title ?? "",
      text: responseData.text ?? "",
      isActive: responseData.isActive ?? "",
    });
  }, [responseData]);

  // DontaionForm Update
  const UpdateAnnouncementForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`announcements/announcement?id=${i.id}`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
      handleClose();
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getAnnouncements();
  };
  const handleShow = () => setShow(true);

  return (
    <>
      {" "}
      <BodyUsersContent
        className="row text-start gx-5"
        key={i.id}
        style={{
          background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
        }}
      >
        <TitleName className="col-4"> {i.title} </TitleName>
        <TitleName className="col"> {parse(i.text)} </TitleName>
        {i.isActive ? (
          <TitleButton className="col-1">Aktif</TitleButton>
        ) : (
          <TitleButtonRed className="col-1">Pasif</TitleButtonRed>
        )}

        <DropdownSection className="col-1 ">
          <DropDownButton id="dropdown-basic">
            <IconDots src={Dots} />
          </DropDownButton>

          <DropDownMenu>
            <DropDownMenuItem>
              {/* Duyuru Duzenle Modal */}
              <StyledButton
                onClick={() => {
                  getAnnouncementId();
                  handleShow();
                }}
              >
                <DropdownIcon src={Edit} alt="" />
                <DropdownText>Düzenle</DropdownText>
              </StyledButton>
            </DropDownMenuItem>

            <DropDownMenuItem>
              <DeleteSection
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                <DropdownIcon src={Delete} alt="" />
                <DropdownText>Sil</DropdownText>
              </DeleteSection>
            </DropDownMenuItem>
          </DropDownMenu>
        </DropdownSection>

        {/* Open Modal */}

        <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
          <Container onSubmit={announcementForm.handleSubmit}>
            {loading && <OverlayLoading />}
            {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
            <ModalHeader>Duyuruyu/Bildirimi Düzenle</ModalHeader>
            <HeaderTitle>
              <HeaderTitleSpan>Düzenlenen Duyuru:</HeaderTitleSpan> {i.title}
            </HeaderTitle>
            <ModalInputContainer>
              <ModalTitle>Duyuru Başlığı</ModalTitle>
              <ModalInput
                name="title"
                value={announcementForm.values.title}
                onChange={announcementForm.handleChange}
                onBlur={announcementForm.handleBlur}
              />
              {announcementForm.touched.title && announcementForm.errors.title ? (
                <AlertTextYup Props={announcementForm.errors.title} />
              ) : null}
            </ModalInputContainer>
            <InputContainer className="mb-4">
              <InputTitle>Duyuru İçeriği</InputTitle>
              <CkEditorSection className="editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={announcementForm.values.text || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();

                    announcementForm.setFieldValue("text", data);
                  }}
                />
              </CkEditorSection>
              {announcementForm.touched.text && announcementForm.errors.text ? (
                <AlertTextYup Props={announcementForm.errors.text} />
              ) : null}
            </InputContainer>
            <ModalInputContainer>
              <ModalTitle>Duyuru Durumu</ModalTitle>
              <ModalInputSection>
                <Form.Check
                  type="checkbox"
                  name="isActive"
                  checked={announcementForm.values.isActive}
                  onChange={() => {
                    announcementForm.setFieldValue("isActive", !announcementForm.values.isActive);
                  }}
                />

                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>

            <div>
              <ModalButton type="submit" onClick={announcementForm.handleSubmit}>
                Duyuruyu Düzenle
              </ModalButton>
            </div>
          </Container>
        </OpenModal>
      </BodyUsersContent>
      <OpenModal show={deleteModal} size="md" centered onHide={() => setDeleteModal(false)} animation={false}>
        <Container>
          <h4 style={{ color: "#ffff", marginBottom: "32px" }}>Silme İstediğinize Emin Misiniz?</h4>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px" }}>
            <button
              onClick={() => setDeleteModal(false)}
              style={{
                cursor: "pointer",
                border: "none",
                border: "1px solid #ffff",
                backgroundColor: "transparent",
                borderRadius: "10px",
                color: "#ffff",
                width: "120px",
                padding: "6px",
              }}
            >
              Hayır
            </button>

            <button
              onClick={() => deleteItem()}
              style={{
                cursor: "pointer",
                border: "1px solid #ff0000",
                background: "#ff0000",
                borderRadius: "10px",
                width: "120px",
                padding: "6px",
                color: "#ffff",
              }}
            >
              Evet
            </button>
          </div>
        </Container>
      </OpenModal>
    </>
  );
};

export default TableUser;
