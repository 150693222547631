import React, { useEffect, useState } from "react";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Voice from "../../../../Assests/Voice 3.png";
import BagisTipleriBody from "./BagisTipleriBody";
import styled from "styled-components";

import axios from "../../../../utils/axios";

// import Modals from "../Modals/KullaniciPopUp";
import BagisTipleriEkleModal from "../Modals/BagisTipleriEkleModal";

// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import Navbars from "../../../../Components/PageComponents/NavbarNew";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BagisTipleriMain = () => {
  const [Props, setProps] = useState({
    title: "Bağış Tipleri",
    img: Voice,
    navbarFilter: false,
    popUp: (
      <BagisTipleriEkleModal
        getDonationsTypes={(values) => getDonationsTypes(values)}
      />
    ),
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getDonationsTypes(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getDonationsTypes = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 20 };
      setFilterParams(params);
      const response = await axios.get("donationTypes/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      name: "",
      isActive: "",
    },
    getFilterData: getDonationsTypes,
    setPage: setPage,
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
  };

  return (
    <div>
      <HeadersMain Props={Props} />
      <Navbars Props={Props} FilterCategories={FilterCategories} />

      <BagisTipleriBody
        data={responseData.length > 0 ? responseData : []}
        getDonationsTypes={(filterParams) => getDonationsTypes(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            count={endPage}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default BagisTipleriMain;
