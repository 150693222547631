import React, { useEffect } from "react";
import Add from "../../../../Assests/Add2.png";
import Danger from "../../../../Assests/Danger.png";
import Upload from "../../../../Assests/ImageUpload.png";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

// Checkbox React Bootstrap
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import * as Yup from "yup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const Row = styled.div``;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  &:hover {
    background: #246cf9;
  }

  &:focus {
    background: #246cf9;
  }

  &:active {
    background: #246cf9;
  }
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const IconSection = styled.div``;

const ModalHeader = styled.div`
  margin-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-top: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid ${({ hasError }) => (hasError ? "#ff0000" : "#3c4254")};

  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }

  &:focus {
    outline: none;
    border: 1px solid ${({ hasError }) => (hasError ? "#ff0000" : "#3c4254")};
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelected = styled.input`
  width: 20px;
  height: 20px;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const HaberEkleContent = styled.div`
  display: flex;
  align-items: center;

  height: 42px;

  padding: 9px 10px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;
`;

const HaberEkleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
`;

const HaberEkleIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const AlertSection = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const AddImgContainer = styled.div`
  background: #282c38;
  margin-left: 10px;
  width: 130px;
  height: 130px;
  position: relative;
  border: 1px dashed #e5e6ed;
  /* border: 1px solid white;
border-radius: 20px;
border-style: dashed; */
  border-radius: 20px;
  margin-bottom: 16px;
`;

const ImgBackgroungd = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 50%;
  border-radius: 20px;
`;

const AddImgIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const UploadContainer = styled.div`
  padding: 0px 20%;
  /* margin-top: 16px; */
  /* margin-left: 12px; */
  /* gap: 18px; */
  background: #282c38;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Base Color/Grey 40 */

  border: 1px dashed ${({ hasError }) => (hasError ? "#ff0000" : "#e5e6ed")};

  border-radius: 10px;

  width: 100%;
  height: 200px;
`;

const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label`
  background: #141518;
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #757b8c; */
  /* border-radius: 10px; */
  cursor: pointer;
`;

const UploadImgBig = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  filter: grayscale(100%);
  /* border: 1px solid #757b8c; */
  /* border-radius: 10px; */
`;

const UploadImgSmall = styled.img`
  position: absolute;
  width: 48px;
  height: 48px;
`;

const CodeSection = styled.div`
  overflow-y: scroll;
  max-height: 100px;
  border: 1px solid white;
`;

const NewSliderPopUp = ({ item, getSlider }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getSlider();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    sliderForm.setValues({
      description: "",
      image: "",
      imageExtension: null,
      isActive: false,
    });
    setError("");
    setSuccess("");
  }, [show]);

  const sliderForm = useFormik({
    initialValues: {
      description: "",
      image: "",
      imageExtension: null,
      isActive: false,
    },

    validationSchema: Yup.object().shape({
      description: Yup.string().required("Zorunlu alana"),
      image: Yup.string().required("Zorunlu alana"),
    }),

    onSubmit: async (values) => {
      await UpdateSlider(values);
      // await getSlider();
      // handleClose();
    },
  });

  // DontaionForm Update
  const UpdateSlider = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...values,
      };

      const response = await axios.post(`Sliders/slider`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
      handleClose();
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      sliderForm.setFieldValue("image", reader.result.split(",")[1]);

      sliderForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <StyledButton onClick={handleShow}>
        <DropdownIcon src={Add} alt="" />
        <DropdownText>Yeni Slider Ekle</DropdownText>
      </StyledButton>

      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container className="container-fluid" onSubmit={sliderForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

          <Row>
            <ModalHeader>Yeni Slider Ekle</ModalHeader>
            <ModalTitle>Slider Görseli</ModalTitle>

            <UploadContainer
              hasError={(sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)}
            >
              <StyledLabel htmlFor="fileUpload">
                {sliderForm.values.image && <UploadImgBig src={`data:image/png;base64,${sliderForm.values.image}`} />}

                <UploadImgSmall src={Upload} />
              </StyledLabel>

              <InputUpload type="file" id="fileUpload" onChange={handleImgUpload} />
            </UploadContainer>

            {((sliderForm.touched.image && sliderForm.errors.image) || (error && !loading)) && (
              <span style={{ color: "#ff0000", fontSize: "12px" }}>
                {(sliderForm.touched.image && sliderForm.errors.image) || (error && !loading)}
              </span>
            )}

            <AlertSection>
              <AlertIcon src={Danger} />
              <AlertText>Desteklenen format sadece PNG,JPG’dir.</AlertText>
            </AlertSection>

            <ModalInputContainer>
              <ModalTitle>Slider Açıklaması</ModalTitle>
              <ModalInput
                name="description"
                value={sliderForm.values.description}
                onChange={sliderForm.handleChange}
                hasError={(sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)}
              />

              {((sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)) && (
                <span style={{ color: "#ff0000", fontSize: "12px" }}>
                  {(sliderForm.touched.description && sliderForm.errors.description) || (error && !loading)}
                </span>
              )}
            </ModalInputContainer>
            <ModalInputContainer>
              <ModalTitle>Durumu</ModalTitle>
              <ModalInputSection>
                {/* <ModalInputSelected type="checkbox" /> */}
                <Form.Check
                  type="checkbox"
                  name="isActive"
                  checked={sliderForm.values.isActive}
                  onChange={() => sliderForm.setFieldValue("isActive", !sliderForm.values.isActive)}
                />
                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>

            {/* ***************************************** */}

            <ModalButton type="submit" onClick={sliderForm.handleSubmit}>
              Yeni Slider Ekle
            </ModalButton>
          </Row>
        </Container>
      </OpenModal>
    </>
  );
};

export default NewSliderPopUp;
