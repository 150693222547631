import React, { useState } from 'react'
import HeadersPagesInside from '../../../../Components/PageComponents/HeadersPagesInside'

import Location from "../../../../Assests/Location.png"
import BagisNoktalariEkleBody from './BagisNoktalariEkleBody'

const BagisNoktalariEkleHome = () => {

    const [Props, setProps] = useState({
        img: Location,
        title: "Bagış Noktası Ekleme",
        main : "Bağış Noktaları",
        titleText: "Yeni Bağış Noktası Ekle",
        navbarInputTitle: "Bağış Noktaları İçinde Ara",
        navbarFilter: true,
        navbarButtonTitle: "Yeni Bağış Noktası Oluştur",
        path: "/bagisnoktalari/ekle"
      })

  return (
    <div>
        <HeadersPagesInside Props={Props} />
        <BagisNoktalariEkleBody />
    </div>
  )
}

export default BagisNoktalariEkleHome