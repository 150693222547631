import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Dots from "../../../../Assests/Dots.png";

import Danger from "../../../../Assests/Danger.png";
import Delete from "../../../../Assests/Delete.png";
import Edit from "../../../../Assests/Edit2.png";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import { useFormik } from "formik";

import axios from "../../../../utils/axios";

// Bootstrap Dropdown
import Dropdown from "react-bootstrap/Dropdown";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaUsers } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

// Modal PopUp Bootstrap

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
  border-radius: 20px;
`;

const Container = styled.form`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const Row = styled.div``;

const StyledButton = styled(Button)`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;

  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const AlertSection = styled.div`
  display: flex;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const SelectDropDown = styled.select`
  cursor: pointer;
  width: 100%;

  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #ffffff;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
`;

const TableUser = ({ i, index, checkedAll, getUsers }) => {
  // const [checked, setChecked] = useState(false);
  // useEffect(() => {
  //   setChecked(checkedAll === true ? true : false);
  // }, [checkedAll]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const deleteItem = async () => {
    const params = {
      id: i.id,
    };
    const response = await axios.delete(`users/user?id=${i.id}`, {
      params: params,
    });
    try {
      await response;
      getUsers();
    } catch (error) {
      console.error(error);
    }
  };

  // PopUp Modal Bootstrap

  const [responseData, setResponseData] = useState({});
  const [responseUserTypes, setResponseUserTypes] = useState([]);

  // useEffect(() => {
  //   getUserTypes()
  //   getUser();
  // }, []);

  const getUser = async () => {
    const params = {
      id: i.id,
    };

    try {
      const response = await axios.get("users/user", {
        params: params,
      });
      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const userForm = useFormik({
    initialValues: {
      id: "",
      name: "",
      lastname: "",
      email: "",
      tel: "",
      isActive: "",
      userTypeId: "",
    },
    validationSchema: validationSchemaUsers,

    onSubmit: async (values) => {
      await UpdateUserForm(values);
      // await getUsers();
      // handleClose();
    },
  });

  useEffect(() => {
    userForm.setValues({
      id: responseData.id ?? "",
      name: responseData.name ?? "",
      lastname: responseData.lastname ?? "",
      email: responseData.email ?? "",
      tel: responseData.tel ?? "",
      isActive: responseData.isActive ?? "",
      userTypeId: responseData.userTypeId ?? "",
    });
  }, [responseData]);

  const getUserTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/userTypes", {
      params: params,
    });
    try {
      await response;
      setResponseUserTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // DontaionForm Update
  const UpdateUserForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };
      const response = await axios.put(`users/user?id=${i.id}`, params);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getUser();
  };
  const handleShow = () => setShow(true);

  return (
    <BodyUsersContent
      className="row text-center"
      key={i.id}
      style={{
        background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
      }}
      // style={{
      //   background:
      //     checked === true
      //       ? "rgba(36, 108, 249, 0.2)"
      //       : index % 2 === 0
      //       ? "rgba(47, 50, 65, 0.5)"
      //       : "#282C38",
      // }}
    >
      {/* <div className="col-1 p-0">
        <Form.Check
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          type="checkbox"
          id={`default-checkbox`}
        />
      </div> */}
      <TitleName className="col-1"> {i.name} </TitleName>
      <TitleName className="col-1"> {i.lastname} </TitleName>
      <TitleName className="col-3"> {i.email} </TitleName>
      <TitleName className="col"> {i.tel} </TitleName>
      <TitleName className="col"> {i.userTypeName} </TitleName>
      {i.isActive ? (
        <TitleButton className="col">Aktif</TitleButton>
      ) : (
        <TitleButtonRed className="col">Pasif</TitleButtonRed>
      )}
      <DropdownSection className="col-1 ">
        <DropDownButton id="dropdown-basic">
          <IconDots src={Dots} />
        </DropDownButton>

        <DropDownMenu>
          <DropDownMenuItem>
            {/* <KullaniciDuzenleModal
              item={i}
              getUsers={getUsers}
            /> */}

            <StyledButton
              onClick={() => {
                getUserTypes();
                getUser();
                handleShow();
              }}
            >
              <DropdownIcon src={Edit} alt="" />
              <DropdownText>Düzenle</DropdownText>
            </StyledButton>
          </DropDownMenuItem>

          <DropDownMenuItem>
            <DeleteSection onClick={deleteItem}>
              <DropdownIcon src={Delete} alt="" />
              <DropdownText>Sil</DropdownText>
            </DeleteSection>
          </DropDownMenuItem>
        </DropDownMenu>
      </DropdownSection>

      {/* OpenModal */}
      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container className="container-fluid" onSubmit={userForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

          <Row className="row">
            <ModalHeader>Sistem Kullanıcısını Düzenle</ModalHeader>

            <ModalInputContainer className="col">
              <ModalTitle>Ad</ModalTitle>
              <ModalInput
                name="name"
                value={userForm.values.name}
                onChange={userForm.handleChange}
                onBlur={userForm.handleBlur}
              />
              {userForm.touched.name && userForm.errors.name ? <AlertTextYup Props={userForm.errors.name} /> : null}
            </ModalInputContainer>
            <ModalInputContainer className="col">
              <ModalTitle>Soyad</ModalTitle>
              <ModalInput
                name="lastname"
                value={userForm.values.lastname}
                onChange={userForm.handleChange}
                onBlur={userForm.handleBlur}
              />
              {userForm.touched.lastname && userForm.errors.lastname ? (
                <AlertTextYup Props={userForm.errors.lastname} />
              ) : null}
            </ModalInputContainer>
            <ModalInputContainer className="col-12">
              <ModalTitle>E-Mail Adresi</ModalTitle>
              <ModalInput
                name="email"
                value={userForm.values.email}
                onBlur={userForm.handleBlur}
                onChange={userForm.handleChange}
              />
              {userForm.touched.email && userForm.errors.email ? <AlertTextYup Props={userForm.errors.email} /> : null}
            </ModalInputContainer>
            <ModalInputContainer className="col">
              <ModalTitle>Telefon Numarası</ModalTitle>
              <ModalInput
                name="tel"
                type="tel"
                value={userForm.values.tel}
                onBlur={userForm.handleBlur}
                maxLength="10"
                onChange={(event) => {
                  const inputPhoneNumber = event.target.value;

                  const numericPhoneNumber = inputPhoneNumber.replace(/[^0-9]/g, "");

                  const trimmedPhoneNumber = numericPhoneNumber.slice(0, 10);
                  userForm.setFieldValue("tel", trimmedPhoneNumber);
                }}
              />
              {userForm.touched.tel && userForm.errors.tel ? <AlertTextYup Props={userForm.errors.tel} /> : null}
            </ModalInputContainer>

            <ModalInputContainer className="col">
              <ModalTitle>Kullanıcı Tipi</ModalTitle>
              <SelectDropDown
                aria-label="Default select example"
                onChange={userForm.handleChange}
                name="userTypeId"
                value={userForm.values.userTypeId}
              >
                {responseUserTypes.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            </ModalInputContainer>

            <ModalInputContainer>
              <ModalTitle>Durumu</ModalTitle>
              <ModalInputSection>
                {/* <ModalInputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    name="isActive"
                    checked={userForm.values.isActive}
                    onChange={() => userForm.setFieldValue("isActive", !userForm.values.isActive)}
                  />
                </div>
                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>

            <AlertSection className="col-12">
              <AlertIcon src={Danger} />
              <AlertText>
                Kullanıcı oluşturulduktan sonra Kullanıcı Şifresi ve gerekli giriş bilgileri girdiğiniz E-Mail adresine
                otomatik olarak gönderilcektir.
              </AlertText>
            </AlertSection>

            <ModalButton type="submit" onClick={userForm.handleSubmit}>
              Değişiklikleri Kaydet
            </ModalButton>

            {/* <code>{JSON.stringify(userForm.values)}</code> */}
          </Row>
        </Container>
      </OpenModal>
    </BodyUsersContent>
  );
};

export default TableUser;
