import React, { useEffect, useState } from "react";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import DuyuruIcon from "../../../../Assests/Voice 3.png";
import DuyurularMainBody from "./DuyurularMainBody";
import PopUpNewAnnoucement from "../Modals/YeniDuyuruModall";

import axios from "../../../../utils/axios";
import styled from "styled-components";

// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Navbars from "../../../../Components/PageComponents/NavbarNew";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const DuyurularMain = () => {
  const [Props, setProps] = useState({
    title: "Duyurular",
    img: DuyuruIcon,
    navbarFilter: false,
    // path: "/haberler/haberlerEkle"
    popUp: (
      <PopUpNewAnnoucement
        getAnnouncements={(values) => getAnnouncements(values)}
      />
    ),
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getAnnouncements(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getAnnouncements = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 13 };
      setFilterParams(params);
      const response = await axios.get("announcements/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      title: "",
      isActive: "",
    },
    getFilterData: getAnnouncements,
    setPage: setPage,
    titleInput: "Başlık İçinde Ara",
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
  };

  return (
    <>
      <HeadersMain Props={Props} />
      {/* <DuyurularMainNavbar /> */}
      <Navbars Props={Props} FilterCategories={FilterCategories} />

      <DuyurularMainBody
        data={responseData.length > 0 ? responseData : []}
        getAnnouncements={(filterParams) => getAnnouncements(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            count={endPage}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Stack>
      </PaginationSection>
    </>
  );
};

export default DuyurularMain;
