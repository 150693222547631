import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Dots from "../../../../Assests/Dots.png";
import Form from "react-bootstrap/Form";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
`;



const TableUser = ({ item, index }) => {

  return (
    <BodyUsersContent
            className="row text-center"
            key={index}
            style={{
              background:
                index % 2 === 0
                  ? "rgba(47, 50, 65, 0.5)"
                  : "#282C38",
            }}
          >
            <TitleName className="col"> {item.userName} </TitleName>
            <TitleName className="col"> {item.date} </TitleName>
            <TitleName className="col"> {item.source} </TitleName>
            <TitleName className="col"> {item.message} </TitleName>
            <TitleName className="col text-start"> {item.details} </TitleName>
          </BodyUsersContent>
  )
}

export default TableUser