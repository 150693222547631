import React, { useEffect, useState } from "react";
import Location from "../../../../Assests/Location.png";
import Headers from "../../../../Components/PageComponents/HeadersMain";
import Navbars from "../../../../Components/PageComponents/NavbarNew";
import BagisNoktalariBody from "./BagisNoktalariBody";

import axios from "../../../../utils/axios.js";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import styled from "styled-components";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BagisNoktalariHome = () => {
  const [Props, setProps] = useState({
    title: "Bagış Noktaları",
    img: Location,
    navbarButtonTitle: "Yeni Bağış Noktası Oluştur",
    path: "/bagisnoktalari/ekle",
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getDonationLocations(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getDonationLocations = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 5 };
      setFilterParams(params);
      const response = await axios.get("donationLocations/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      name: "",
      isActive: "",
    },
    getFilterData: getDonationLocations,
    setPage: setPage,
    adInput: "Ad ile Arama Yapın",
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
  };

  return (
    <div>
      <Headers Props={Props} />
      <Navbars Props={Props} FilterCategories={FilterCategories} />
      <BagisNoktalariBody
        data={responseData.length > 0 ? responseData : []}
        getData={(filterParams) => getDonationLocations(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default BagisNoktalariHome;
