import React from "react";
import styled from "styled-components";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";


import SpinnerImg from "../../Assests/loadingAnim640.gif"

const Container = styled.div`
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const LoadingImg = styled.img`
  width: 100%;
  height: 100%;
`;


const OverlayLoading = () => {

  const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);


  return (

     <OpenModal
        show={show}
        size="lg"
        centered
        // onHide={handleClose}
        animation={false}
      >
        <Container>
            <LoadingImg src={SpinnerImg} />
            {/* <Spinner style={{width: "100px" , height: "100px", color: "red"}} animation="grow"  /> */}
        </Container>
      </OpenModal>

  );
};

export default OverlayLoading;
