import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CloseSquare from "../../../../Assests/CloseSquare.png";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Navbars from "../../../../Components/PageComponents/NavbarNew";
import HataListesiBody from "./HataListesiBody";

import axios from "../../../../utils/axios";

// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HataListesiHome = () => {
  const [Props, setProps] = useState({
    title: "Hata Listesi",
    img: CloseSquare,
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getErrors(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getErrors = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = {
        date1: "2023-02-21",
        date2: "2023-02-21",
        ...valuesFilter,
        page: page,
        take: 8,
      };

      setFilterParams(params);
      // reports/errorLogList?date1=2023-02-21&date2=2023-02-21&page=1&take=1
      const response = await axios.get(
        `reports/errorLogList?date1=${params.date1}&date2=${params.date2}&page=${params.page}&take=${params.take}`
      );
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
      // setEndPage(Math.ceil(response.data.length / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const FilterCategories = {
    initialValues: {
      date1: yesterday,
      date2: today,
    },
    getFilterData: getErrors,
    setPage: setPage,
  };

  return (
    <div>
      <HeadersMain Props={Props} />
      <Navbars Props={Props} FilterCategories={FilterCategories} />
      <HataListesiBody
        data={responseData.length > 0 ? responseData : []}
        getErrors={(filterParams) => getErrors(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={Number(endPage)} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default HataListesiHome;
