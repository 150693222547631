import React, { useState } from 'react'
import styled from 'styled-components'
import HeadersPagesInside from '../../../../Components/PageComponents/HeadersPagesInside'
import Edit from "../../../../Assests/Edit2.png"
import HaberlerEkleBody from './HaberlerEkleBody'

const Container = styled.div`
`

const HaberlerEkle = () => {

  const [Props, setProps] = useState({
    title: "Yeni Bir Haber Ekle",
    main:"Haberler",
    titleText: "Yeni Bir Haber Ekle",
    titleTextExtra: "Düzenlenen Haber: Commodo malesuada id mollis nibh fames et elit, eget nibh",
    img : Edit
  })

  return (
    <Container>
      <HeadersPagesInside Props={Props} />
      <HaberlerEkleBody />
    </Container>
  )
}

export default HaberlerEkle