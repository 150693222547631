import React from "react";
import styled from "styled-components";

// Checkbox React Bootstrap
import NoneDataEror from "../../../../Components/PageComponents/NoneDataError";
import TableUser from "./TableUser";

import LoadingTable from "../../../../Components/PageComponents/LoadingTable";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const HeaderTitle = styled.div``;

const SliderBody = ({ data, getSlider, loading }) => {
  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row text-center">
          {/* <div className="col-1 p-0">
            <Form.Check type="checkbox" checked={checked} onChange={(event) => setChecked(event.target.checked)} id={`default-checkbox`} />
          </div> */}
          <HeaderTitle className="col text-start">Görsel</HeaderTitle>
          <HeaderTitle className="col-8 text-center">Açıklama</HeaderTitle>
          <HeaderTitle className="col">Durum</HeaderTitle>
          <HeaderTitle className="col-1">İşlemler</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? (
          <NoneDataEror />
        ) : (
          data.map((i, index) => <TableUser key={index} i={i} index={index} getSlider={getSlider} />)
        )}
      </Container>
    </>
  );
};

export default SliderBody;
