import React, { useEffect, useState } from "react";
import styled from "styled-components";

import Danger from "../../../../Assests/Danger.png";
import Upload from "../../../../Assests/ImageUpload.png";

import axios from "../../../../utils/axios";

import GoogleMapReact from "google-map-react";

import { FaMapMarkerAlt } from "react-icons/fa";

// Checkbox React Bootstrap
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaDonationLocation } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const Container = styled.div``;

const Row = styled.form``;

const RowTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const RowContainer = styled.div`
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
`;

const UploadContainer = styled.div`
  margin-top: 16px;
  padding: 0px;
  gap: 18px;
  background: rgba(20, 21, 24, 0.7);
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Base Color/Grey 40 */

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const RowBottom = styled.div``;

const RowContainerTitle = styled.div`
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const Input = styled.input`
  width: 100%;
  margin-top: 8px;
  padding: 16px;
  gap: 8px;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HaberDurumuContainer = styled.div``;

const SelectedText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ButtonBottom = styled.button`
  width: 100%;
  flex: 1;
  padding: 16px 8px;
  gap: 8px;

  width: 661.5px;
  height: 51px;

  /* Main Color/Royalblue 100 */

  /* background: #246CF9; */
  background: ${(props) => (props.blue ? "#246CF9" : "#3C4254")};
  border-radius: 10px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const TextareaContainer = styled.div``;

const TextareaContainerInside = styled.div`
  position: relative;
  display: flex;
`;

const TextareaLeft = styled.textarea`
  padding: 16px 32px 16px 16px;
  gap: 8px;

  height: 130px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  width: 100%;

  resize: none;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #ffffff;

  &::placeholder {
    color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};
  }
`;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const WarningContainer = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 8px;
  width: 359px;
  height: 30px;
  align-items: center;
`;

const WarningIcon = styled.img`
  width: 15px;
  height: 15px;
  object-fit: cover;
`;

const WarningText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  /* Accent Color/Orange */

  color: #ffb545;
`;

const GoogleMapSection = styled.div`
  border-radius: 10px;
  height: 300px;
  position: relative;
  overflow: hidden;
`;

const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;

const UploadImgBig = styled.img`
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const UploadImgSmall = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
`;

const CodeSection = styled.div`
  overflow-y: scroll;
  max-height: 100px;
  border: 1px solid white;
`;

const BagisNoktalariDetaylariBody = () => {
  const [responseData, setResponseData] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const { id } = useParams();

  useEffect(() => {
    getDonationRequestId();
  }, []);

  const getDonationRequestId = async () => {
    setLoading(true);

    try {
      const params = {
        id: id,
      };
      const response = await axios.get("api/DonationLocations/" + id, {
        params: params,
      });

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // requestForm Update
  const UpdateDonationRequestForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`donationLocations/donationLocation?id=${id}`, params);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const requestForm = useFormik({
    initialValues: {
      id: "",
      name: "",
      address: "",
      location: "",
      image: "",
      isActive: "",
      submitType: "",
    },

    validationSchema: validationSchemaDonationLocation,

    onSubmit: (values, { setSubmitting }) => {
      if (values.submitType === "send") {
        UpdateDonationRequestForm(values);
      }
      if (values.submitType === "cancel") {
        // deleteImgForm();
        getDonationRequestId();
      }

      setSubmitting(false);
    },
  });

  // google maps
  const [marker, setMarker] = useState(null);
  const [latRes, setLatRes] = useState(null);
  const [lngRes, setLngRes] = useState(null);

  useEffect(() => {
    requestForm.setValues({
      id: id ?? "",
      address: responseData?.address ?? "",
      name: responseData?.name ?? "",
      location: responseData?.location ?? "",
      isActive: responseData?.isActive ?? "",
      image: null,
      imageExtension: null,
    });

    var string = responseData.location ?? "";
    var index = string.indexOf(",");
    var lat = string.substring(0, index);
    var lng = string.substring(index + 1);
    setMarker({ lat, lng });

    setLatRes(parseFloat(lat));
    setLngRes(parseFloat(lng));
  }, [responseData]);

  const defaultCenter = responseData ? { lat: latRes, lng: lngRes } : { lat: 37.7749, lng: -122.4194 };

  const URLimg = "data:image/png;base64,";

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      requestForm.setFieldValue("image", reader.result.split(",")[1]);

      requestForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);
  };

  const handleMapClick = (event) => {
    const lat = event.lat;
    const lng = event.lng;
    setMarker({ lat, lng });
    requestForm.setFieldValue("location", lat + "," + lng);
  };

  return (
    <Container>
      {loading && <OverlayLoading />}
      {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

      <Row className="row" onSubmit={requestForm.handleSubmit}>
        <RowBottom>
          <RowTitle>Bağış Noktası Bilgileri</RowTitle>
          <RowContainer>
            <RowContainerTitle>Ad</RowContainerTitle>
            <Input
              onChange={requestForm.handleChange}
              name="name"
              value={requestForm.values.name}
              onBlur={requestForm.handleBlur}
            />
            {requestForm.touched.name && requestForm.errors.name ? (
              <AlertTextYup Props={requestForm.errors.name} />
            ) : null}

            <TextareaContainer>
              <InputTitle id="address">Adres</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  onChange={requestForm.handleChange}
                  name="address"
                  value={requestForm.values.address}
                  onBlur={requestForm.handleBlur}
                />
              </TextareaContainerInside>
              {requestForm.touched.address && requestForm.errors.address ? (
                <AlertTextYup Props={requestForm.errors.address} />
              ) : null}
            </TextareaContainer>

            <HaberDurumuContainer>
              <InputTitle>Durumu</InputTitle>
              <SelectedContainer>
                {/* <InputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    onChange={requestForm.handleChange}
                    name="isActive"
                    checked={requestForm.values.isActive}
                  />
                </div>
                <SelectedText>Akitf</SelectedText>
              </SelectedContainer>
            </HaberDurumuContainer>

            <InputTitle>Konum</InputTitle>

            {responseData !== null && requestForm.values.location.length > 0 && (
              <GoogleMapSection>
                <GoogleMapReact
                  onClick={handleMapClick}
                  // defaultCenter={defaultCenter}
                  // defaultCenter={{ lat: 37.7749, lng: -122.4194 }}
                  defaultCenter={{
                    lat: requestForm?.values?.location ? latRes : 37.7749,
                    lng: requestForm?.values?.location ? latRes : -122.4194,
                  }}
                  defaultZoom={15}
                  bootstrapURLKeys={{
                    key: "AIzaSyC-W01cZQc3tf0-U9ncIeZYhMLuAwcKthc",
                  }}
                >
                  {marker && <Marker lat={marker.lat} lng={marker.lng} />}
                </GoogleMapReact>
              </GoogleMapSection>
            )}

            <InputTitle>Küçük Resim</InputTitle>

            <UploadContainer>
              <StyledLabel htmlFor="fileUpload">
                <UploadImgBig src={requestForm.values.image ? URLimg + requestForm.values.image : responseData.image} />
                <UploadImgSmall src={Upload} />
              </StyledLabel>

              <InputUpload type="file" id="fileUpload" onChange={handleImgUpload} />
            </UploadContainer>

            <WarningContainer>
              <WarningIcon src={Danger} />
              <WarningText>
                Eğer küçük resmi değiştirmek istemiyorsanız seçim yapmayınız.Desteklenen format sadece PNG’dir.
              </WarningText>
            </WarningContainer>

            {/* ***************************************** */}

            {/* <div>
              <CodeSection className="mt-5">
                <code>name : {JSON.stringify(requestForm.values.name)}</code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  address: {JSON.stringify(requestForm.values.address)}
                </code>
              </CodeSection>
              <CodeSection>
                <code>image : {JSON.stringify(requestForm.values.image)}</code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  imageExtension :{" "}
                  {JSON.stringify(requestForm.values.imageExtension)}
                </code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  location : {JSON.stringify(requestForm.values.location)}
                </code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  isActive: {JSON.stringify(requestForm.values.isActive)}
                </code>
              </CodeSection>
            </div> */}

            <ButtonContainer>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="cancel"
                onClick={() => {
                  requestForm.setFieldValue("submitType", "cancel");
                }}
              >
                Düzenlemeyi İptal Et
              </ButtonBottom>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="send"
                onClick={() => {
                  requestForm.setFieldValue("submitType", "send");
                }}
                blue
              >
                Değişiklikleri Kaydet
              </ButtonBottom>
            </ButtonContainer>
          </RowContainer>
        </RowBottom>
      </Row>
    </Container>
  );
};

const Marker = () => (
  <div style={{ color: "red", fontSize: "24px" }}>
    <FaMapMarkerAlt />
  </div>
);

export default BagisNoktalariDetaylariBody;
