import React from 'react'
import BagisNoktalariHome from './BagisNoktalariHome/BagisNoktalariHome'

const BagisNoktalari = () => {
  return (
    <div>
      <BagisNoktalariHome />
    </div>
  )
}

export default BagisNoktalari