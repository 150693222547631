import React, { useState } from "react";
import styled from "styled-components";

import { useNavigate } from "react-router-dom";
import Alert from "../../../../Assests/Danger Circle.png";
import Delete from "../../../../Assests/Delete3.png";
import Upload from "../../../../Assests/ImageUpload.png";
import axios from "../../../../utils/axios";
// CK Editor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// Checkbox React Bootstrap
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaNews } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const Container = styled.div`
  /* height: 100%; */
`;

const Row = styled.form``;

const RowLeft = styled.div``;

const RowTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const RowContainer = styled.div`
  padding: 16px;
  /* max-height: 336px; */
  max-height: ${(props) => (props.up ? "336px" : "100%")};
  background: #1e1f25;
  border-radius: 20px;
`;

const AlertContainer = styled.div`
  box-sizing: border-box;
  padding: 8px;
  gap: 8px;
  background: rgba(255, 181, 69, 0.3);
  /* Accent Color/Orange */

  border: 1px solid #ffb545;
  border-radius: 10px;
`;

const AlertIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 8px;

  display: flex;
  justify-content: center;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;
`;

const UploadContainer = styled.div`
  margin-top: 16px;
  padding: 0px;
  gap: 18px;
  background: #282c38;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  /* Base Color/Grey 40 */

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const UploadImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const UploadIcon = styled.img`
  /* width: 56px;
height: 56px; */

  width: ${(props) => (props.small ? "32px" : "42px")};
  height: ${(props) => (props.small ? "32px" : "42px")};
`;

const UploadText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const RowRight = styled.div`
  max-width: 53%;
`;

const UploadContainerRight = styled.div`
  width: 25%;
  box-sizing: border-box;
  padding: 0px;

  /* Base Color/Grey 40 */

  border: 1px solid #757b8c;
  border-radius: 10px;
  height: 200px;

  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

const UploadImagesContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const UploadImages = styled.img`
  width: 200px;
  height: 200px;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;

const RowBottom = styled.div``;

const RowContainerTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const Input = styled.input`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 16px;
  gap: 8px;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const SelectedContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const HaberDurumuContainer = styled.div``;

const SelectedText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ButtonContainer = styled.button`
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  width: 100%;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
  margin-top: 16px;
`;

const CkEditorSection = styled.div``;

const InputContainer = styled.div``;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const UploadImagesSection = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: scroll;
  max-width: 75%;

  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #282c38;
    border-radius: 12px;
    border: solid 5px #1e1f25;
  }
`;
const UploadImgContainer = styled.div`
  position: relative;
`;

const DeleteImgSection = styled.button`
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  background: #fa2256;
  border-radius: 0px 10px 0px 10px;
`;

const DeleteImg = styled.img``;

const UploadImgBig = styled.img`
  filter: grayscale(100%);
  width: 100%;
  height: 100%;
  object-fit: cover;

  border: 1px solid #757b8c;
  border-radius: 10px;
`;
const UploadImgSmall = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
`;

const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label`
  background: #246cf9;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;

const StyledLabelLeft = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;

const CodeSection = styled.div`
  overflow-y: scroll;
  max-height: 100px;
  border: 1px solid white;
`;

const HaberlerEkleBody = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const newsForm = useFormik({
    initialValues: {
      isActive: false,
      title: "",
      text: "",
      image: "",
      imageExtension: "",
      images: "",
      submitType: "",
    },
    validationSchema: validationSchemaNews,

    onSubmit: (values, { setSubmitting }) => {
      if (values.submitType === "send") {
        updateNews(values);
      } else if (values.submitType === "deleteImg") {
        // handleDelete();
      }
      setSubmitting(false);
    },
  });

  // updateNews Update
  const updateNews = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`news/new`, values);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
      alert("Kayıt Başarılı");
      navigate("/Haberler");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }

    setLoading(false);
  };

  // image upload

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      newsForm.setFieldValue("image", reader.result.split(",")[1]);

      newsForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);
  };

  const [temp, setTemp] = useState(0);
  const handleImagesUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    setTemp(temp + 1);
    reader.onloadend = () => {
      newsForm.setValues({
        ...newsForm.values,
        images: [
          ...newsForm.values.images,
          {
            // image: reader.result,
            id: temp,
            image: reader.result.split(",")[1],
            imageExtension:
              file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", ""),
          },
        ],
      });
    };

    reader.readAsDataURL(file);
  };

  const handleDelete = (itemId) => {
    const updatedListItems = newsForm.values.images.filter((item) => item.id !== itemId);

    newsForm.setValues({
      ...newsForm.values,
      images: updatedListItems,
    });
  };

  return (
    <Container>
      {loading && <OverlayLoading />}
      {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
      <Row className="row" onSubmit={newsForm.handleSubmit}>
        <RowLeft className="col">
          <RowTitle>Haber Kapak Görseli</RowTitle>
          <RowContainer up>
            <AlertContainer>
              <AlertIconContainer>
                <AlertIcon src={Alert} />
              </AlertIconContainer>
              <AlertText>
                Yüklediğiniz görsel en az Genişlik(1000px) x Yükseklik(675px) boyutlarında ve yüksek kaliteli olmalıdır.
                Aksi taktirde mobil uygulamada düşük çözünürlükte görünür.
              </AlertText>
            </AlertContainer>
            <UploadContainer>
              <StyledLabelLeft htmlFor="fileUploadLeft">
                {newsForm.values.image && <UploadImgBig src={`data:image/png;base64,${newsForm.values.image}`} />}

                <UploadImgSmall src={Upload} />
              </StyledLabelLeft>

              <InputUpload type="file" id="fileUploadLeft" onChange={handleImgUpload} />
            </UploadContainer>
          </RowContainer>
        </RowLeft>

        <RowRight className="col">
          <RowTitle>Haber Albümü</RowTitle>
          <RowContainer up>
            <AlertContainer>
              <AlertIconContainer>
                <AlertIcon src={Alert} />
              </AlertIconContainer>
              <AlertText>
                Yüklediğiniz görsel en az Genişlik(1000px) x Yükseklik(675px) boyutlarında ve yüksek kaliteli olmalıdır.
                Aksi taktirde mobil uygulamada düşük çözünürlükte görünür.
              </AlertText>
            </AlertContainer>

            <UploadImagesContainer>
              <UploadContainerRight>
                <StyledLabel htmlFor="fileInput">
                  <UploadIcon small src={Upload} />
                </StyledLabel>

                <InputUpload type="file" id="fileInput" onChange={handleImagesUpload} />
              </UploadContainerRight>

              <UploadImagesSection>
                {newsForm.values.images &&
                  newsForm.values.images.map((image, index) => (
                    <UploadImgContainer>
                      <DeleteImgSection
                        type="submit"
                        name="submitType"
                        value="deleteImg"
                        onClick={() => {
                          newsForm.setFieldValue("submitType", "deleteImg");
                          handleDelete(image.id);
                        }}
                      >
                        <DeleteImg src={Delete} />
                      </DeleteImgSection>
                      <UploadImages key={index} src={`data:image/png;base64,${image.image}`} alt={`Image ${index}`} />
                    </UploadImgContainer>
                  ))}
              </UploadImagesSection>
            </UploadImagesContainer>
          </RowContainer>
        </RowRight>

        <RowBottom>
          <RowTitle>Haber Detayları</RowTitle>
          <RowContainer>
            <RowContainerTitle>Haber Başlığı</RowContainerTitle>
            <Input
              name="title"
              value={newsForm.values.title}
              onChange={newsForm.handleChange}
              onBlur={newsForm.handleBlur}
            />
            {newsForm.touched.title && newsForm.errors.title ? <AlertTextYup Props={newsForm.errors.title} /> : null}
            <InputContainer className="mt-2">
              <InputTitle>Haber İçeriği</InputTitle>
              <CkEditorSection className="editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={newsForm.values.text || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    newsForm.setFieldValue("text", data);
                  }}
                />
              </CkEditorSection>
            </InputContainer>
            {newsForm.touched.text && newsForm.errors.text ? <AlertTextYup Props={newsForm.errors.text} /> : null}

            <HaberDurumuContainer>
              <RowTitle>Haber Durumu</RowTitle>
              <SelectedContainer>
                {/* <InputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    onChange={newsForm.handleChange}
                    name="isActive"
                    checked={newsForm.values.isActive}
                  />
                </div>

                <SelectedText>Aktif</SelectedText>
              </SelectedContainer>

              {/* <ButtonContainer type="submit">Haber Ekle</ButtonContainer> */}

              <ButtonContainer
                type="submit"
                name="submitType"
                value="send"
                onClick={() => {
                  newsForm.setFieldValue("submitType", "send");
                }}
              >
                Haber Ekle
              </ButtonContainer>
              {/* ***************************************** */}
            </HaberDurumuContainer>
          </RowContainer>
        </RowBottom>
      </Row>
    </Container>
  );
};

export default HaberlerEkleBody;
