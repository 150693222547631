import React, { useState } from "react";
import styled from "styled-components";
import DashboardBody from "./DashboardBody";

import Profile from "../../../Assests/Profile.png";
import HeadersMain from "../../../Components/PageComponents/HeadersMain";

const Container = styled.div`
  height: 100%;
`;

const Dashboard = () => {
  // const { state } = useLocation();
  // console.log(state);

  const [Props, setProps] = useState({
    title: "Dashboard",
    img: Profile,
  });

  return (
    <Container>
      <HeadersMain Props={Props} />
      {/* <DashboardHeader /> */}
      <DashboardBody />

      {/* <Profilim /> */}
    </Container>
  );
};

export default Dashboard;
