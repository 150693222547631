import React, { useState } from "react";
import styled from "styled-components";
// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import TableUser from "./TableUser";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #757b8c;
  background: #1e1f25;
`;

const HeaderTitle = styled.div``;

const YardimTalepleriBody = ({ data, getData, loading }) => {
  // const [checked, setChecked] = useState(false);

  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row text-center">
          {/* <div className="col p-0">
            <Form.Check
              type="checkbox"
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              id={`default-checkbox`}
            />
          </div> */}
          <HeaderTitle className="col">Ad</HeaderTitle>
          <HeaderTitle className="col">Telefon</HeaderTitle>
          <HeaderTitle className="col">Talep Durumu</HeaderTitle>
          <HeaderTitle className="col">Talep Durum Tarihi</HeaderTitle>
          <HeaderTitle className="col">Talep Tipi</HeaderTitle>
          <HeaderTitle className="col">Oluşturulma Tarihi</HeaderTitle>
          <HeaderTitle className="col-3">Açıklama</HeaderTitle>
          <HeaderTitle className="col">Adres</HeaderTitle>
          <HeaderTitle className="col">İşlemler</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? (
          <NoneDataError />
        ) : (
          data.map((i, index) => (
            <TableUser key={index} i={i} index={index} getData={getData} />
          ))
        )}
      </Container>
    </>
  );
};

export default YardimTalepleriBody;
