import React, { useState } from "react";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Heart from "../../../../Assests/needHelp.png";
import ProjeninAmaciBody from "./ProjeninAmaciBody";

const ProjeninAmaciHome = () => {
  const [Props, setProps] = useState({
    title: "Projenin Amacı",
    img: Heart,
  });

  return (
    <div>
      <HeadersMain Props={Props} />
      <ProjeninAmaciBody />
    </div>
  );
};

export default ProjeninAmaciHome;
