import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import axios from "../../../utils/axios";
import DoughnutChartBagislar from "./Charts/DoughnutChartBagislar";
import LineChartHelpRqst from "./Charts/LineChartHelpRqst";
import LineChartYardim from "./Charts/LineChartYardim";

import LoadingTable from "../../../Components/PageComponents/LoadingTable";

const Container = styled.div`
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 4rem;
`;

const HeaderTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  padding: 16px 16px;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const RowUpper = styled.div`
  height: 250px;
  /* height: 30vh; */
  gap: 8px;
`;

const ContentBagislar = styled.div`
  background: #1e1f25;
  border-radius: 20px;
`;

const Hr = styled.hr`
  background: #282c38;
  height: 2px;
`;

const BagislarText = styled.div`
  height: 40%;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 58px;
  /* identical to box height */

  /* Accent Color/Light Green */

  /* color: #30e0a1; */
  color: ${(props) => (props.purple ? "#A276FF" : "#30e0a1")};
`;

const TalepText = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  text-decoration: none;
  /* padding-top: 2%; */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Accent Color/Red Primary */

  color: #fd0;

  &:hover {
    color: #fd0;
  }

  &.active {
    text-decoration: none;
    background: none;
    color: #fa2256;
  }
`;

const ContentYardimTalepleri = styled.div`
  background: #1e1f25;
  border-radius: 20px;
`;

const ContentIhtiyac = styled.div`
  background: #1e1f25;
  border-radius: 20px;
`;

const RowBottom = styled.div`
  /* padding: 16px 0px; */
  /* gap: 16px; */
  margin-top: 16px;
  /* column-gap: 2px; */
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  /* gap: 16px; */
`;

const BagislarContent = styled.div`
  background: #1e1f25;
  border-radius: 20px;
  /* max-width: 50%; */
  height: 100%;
  width: 49%;
`;

const YardimContent = styled.div`
  background: #1e1f25;
  border-radius: 20px;
`;

const DashboardBody = () => {
  const [responseData, setResponseData] = useState([]);
  const [percent, setPercent] = useState(0);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDonations();
  }, []);

  const getDonations = async () => {
    setLoading(true);

    try {
      const response = await axios.get("account/dashboard");
      await response;
      setResponseData(response.data);
      setPercent(
        (response.data.donationRequestComplatedCount +
          response.data.donationRequestSpecialDayCount +
          response.data.donationRequestRegularCount) /
          (response.data.donationRequestCount -
            (response.data.donationRequestImproperCount + response.data.donationRequestCancelledCount))
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <Container>
          <RowUpper className="row">
            {/* <OverlayLoading /> */}
            {/* <AlertSuccess result={true} /> */}

            <ContentBagislar className="col">
              <HeaderTitle>Bağışlar</HeaderTitle>
              <BagislarText purple>{responseData.donationCount}</BagislarText>
              <Hr />
              <TalepText
                to={{
                  pathname: "/bagislar",
                }}
              >
                {responseData.donationWaitingCount} Bekleyen Bağış --{">"}
              </TalepText>
            </ContentBagislar>

            <ContentYardimTalepleri className="col mx-2">
              <HeaderTitle>Yardım Talepleri</HeaderTitle>
              <BagislarText purple>{responseData.donationRequestCount}</BagislarText>
              <Hr />
              <TalepText
                to={{
                  pathname: "/yardimtalepleri",
                }}
              >
                {responseData.donationRequestWaitingCount} Bekleyen Yardım Talebi --{">"}
              </TalepText>
            </ContentYardimTalepleri>

            <ContentIhtiyac className="col">
              <HeaderTitle>İhtiyaçlara Cevap Oranları</HeaderTitle>
              <DoughnutChartBagislar percent={percent} />
            </ContentIhtiyac>
          </RowUpper>

          <RowBottom className="row justify-content-between gx-2 ">
            <BagislarContent className="">
              <HeaderTitle>Bağışlar</HeaderTitle>

              <LineChartYardim responseData={responseData.donations} />
            </BagislarContent>

            <BagislarContent className="">
              <HeaderTitle>Yardım Talepleri</HeaderTitle>
              <LineChartHelpRqst responseData={responseData.donationRequests} />
            </BagislarContent>
          </RowBottom>
        </Container>
      )}
    </>
  );
};

export default DashboardBody;
