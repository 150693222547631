import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import styled from "styled-components";
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const Row = styled.div`
  width: 100%;
  height: 100%;
`;
const LineChartYardim = ({ responseData }) => {
  const convertDateToISO = (dateStr) => {
    const parts = dateStr.split(".").map((z) => z.padStart(2, "0"));

    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };
  const getLastSevenDays = () => {
    return [...Array(7).keys()]
      .map((days) => {
        const date = new Date();
        date.setDate(date.getDate() - days);
        return date.toISOString().slice(0, 10);
      })
      .reverse();
    //reverse yapıldı çünkü en son gün ilk olarak sıralanması istenilmişti
  };

  const lastSevenDays = getLastSevenDays();

  let countsByDate = new Map(lastSevenDays.map((date) => [date, 0]));
  //bir obje oluşturduk değerleri ilk olarak 0 verildi sonradan setledik
  responseData?.map(({ date, count }) => {
    const formattedDate = convertDateToISO(date);

    if (countsByDate.has(formattedDate)) {
      countsByDate.set(formattedDate, count);
    }
  });
  const labels = [...countsByDate.keys()].map((date) =>
    new Date(date).toLocaleDateString("tr-TR", { weekday: "long" })
  );
  const data = [...countsByDate.values()];
  const userData = {
    labels,
    datasets: [
      {
        label: "Bağışlar",
        data,
        backgroundColor: "#A276FF",
        fill: true,
        borderColor: "#A276FF",
        borderWidth: 3,
      },
    ],
  };
  return (
    <Container>
      <Row>
        <Line data={userData} />
      </Row>
    </Container>
  );
};
export default LineChartYardim;
