import React, { useState } from "react";
import Chart from "../../../../Assests/Chart.png";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import TabloListesiBody from "./TabloListesiBody";

import axios from "../../../../utils/axios";
// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import styled from "styled-components";
import Navbars from "../../../../Components/PageComponents/NavbarNew";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TabloListesiHome = () => {
  const [Props, setProps] = useState({
    title: "Tablo Listesi",
    img: Chart,
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");
  const [responseTableLogTypes, setResponseTableLogTypes] = useState([]);
  const [responseTables, setResponseTables] = useState([]);
  const [responseUsers, setResponseUsers] = useState([]);

  useEffect(() => {
    getTableLogTypes();
    getTables();
    getUsers();
  }, []);

  useEffect(() => {
    getTable(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getTable = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = {
        date1: "2023-02-21",
        date2: "2023-02-21",
        tableLogTypeId: "",
        userId: "",
        tableId: "",
        tableRecordId: "",
        ...valuesFilter,
        page: page,
        take: 2,
      };

      setFilterParams(params);
      //  reports/tableLogList?tableLogTypeId={tableLogTypeId}&userId={userId}&tableId={tableId}&tableRecordId={tableRecordId}&date1=${params.date1}&date2=${params.date2}&page=${params.page}&take=${params.take}
      const response = await axios.get(
        `reports/tableLogList?tableLogTypeId=${params.tableLogTypeId}&userId=${params.userId}&tableId=${params.tableId}&tableRecordId=${params.tableRecordId}&date1=${params.date1}&date2=${params.date2}&page=${params.page}&take=${params.take}`
      );
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const FilterCategories = {
    initialValues: {
      date1: yesterday,
      date2: today,
      tableLogTypeId: "",
      userId: "",
      tableId: "",
      tableRecordId: "",
    },
    getFilterData: getTable,
    setPage: setPage,
    TableLogTypes: responseTableLogTypes,
    Tables: responseTables,
    Users: responseUsers,
  };

  // Kayıt Ekleme, Kayıt Güncelleme
  const getTableLogTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/tableLogTypes", {
      params: params,
    });
    try {
      await response;
      setResponseTableLogTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Duyurular, İletişim
  const getTables = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/tables", {
      params: params,
    });
    try {
      await response;
      setResponseTables(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const getUsers = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/users", {
      params: params,
    });
    try {
      await response;
      setResponseUsers(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeadersMain Props={Props} />
      <Navbars Props={Props} FilterCategories={FilterCategories} />

      <TabloListesiBody
        data={responseData.length > 0 ? responseData : []}
        getTable={(filterParams) => getTable(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default TabloListesiHome;
