import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

import ArrowDownCircle from "../../Assests/Arrow - Down Circle.png";
import ArrowRightCircle from "../../Assests/Arrow - Right Circle.png";

// Mui Popover
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

import { FiArrowRight } from "react-icons/fi";

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const ArrowRightIcon = styled(FiArrowRight)`
  width: 16px;
  height: 16px;
  color: #fff;
`;

const SubMenuText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const MenuTitle = styled.div`
  width: 90%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */
  /* color: #757b8c; */
`;

const MenuNotification = styled.div`
  width: 10%;
`;

const MenuNotificationSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 15px;
  height: 15px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;

  /* padding-bottom: 35%;
  padding-right: 15%; */

  /* Accent Color/Red Primary */

  background: #fa2256;
  border-radius: 12px;
`;

const MenuNotificationSpanActive = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;

  /* Accent Color/Red Primary */

  background: #fa2256;
  border-radius: 12px;
`;

const MenuMoreCategories = styled.div``;

const MenuMoreCategoriesImg = styled.img``;

const SubLinkSection = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px !important;
  background: rgba(47, 50, 65, 0.5);
  padding: 8px;
  gap: 8px;
`;

const SubLink = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-left: 24px !important;
  background: rgba(47, 50, 65, 0.5);
  padding: 8px;
  gap: 8px;

  &.active {
    ${SubMenuText} {
      color: #246cf9;
    }
    ${ArrowRightIcon} {
      color: #246cf9;
    }
  }
`;

const SubLinkOpenRight = styled(NavLink)`
  text-decoration: none;
`;

const SubMenuIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropDownSection = styled.div`
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 44px;
  padding: 0px 16px;
  gap: 16px;
  background: #282c38;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #ffffff;
`;

const SubMenuContainer = styled.div``;

const PopOverButton = styled(Button)`
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  transition: none;
`;

const PopOverOpen = styled(Typography)`
  padding: 0 !important;
  /* background: #1e1f25; */
`;

const SubMenuContainerPopOver = styled.div``;

const SubLinkPopOver = styled(NavLink)`
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 185px;

  padding: 12px;
  gap: 8px;

  &.active {
    ${ArrowRightIcon} {
      color: #246cf9;
    }
    ${SubMenuText} {
      color: #246cf9;
    }
  }
`;

const NavlinkSection = styled(NavLink)`
  padding: 0px 16px;
  gap: 16px;

  display: flex;
  align-items: center;

  width: 100%;
  height: 44px;
  text-decoration: none;
  color: #757b8c;

  &:hover {
    color: #757b8c;
  }

  &.active {
    background: #282c38;
    color: #ffffff !important;
  }

  /* &.active ${MenuIcon} {
  background: red;
} */
`;

const VatandasFormSection = styled.a`
  padding: 0px 16px;
  gap: 16px;

  display: flex;
  align-items: center;

  width: 100%;
  height: 44px;
  text-decoration: none;
  color: #757b8c;

  &:hover {
    color: #757b8c;
  }
`;

const SideBarNavLinks = ({ item, index, isOpen }) => {
  // MiniSubMenu
  const { pathname } = useLocation();
  const parentPath = pathname.split("/")[1];
  const itemPath = item.path.replace("/", "").replace("/", "").replace("home", "");

  //  opensubmenu
  const [subMenu, setsubMenu] = useState(false);

  // MuiPopover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  if (item.moreCategories && isOpen) {
    const itemPath = item.path.replace("/", "").replace("/", "");
    return (
      // <div className={`MenuItemContent ${subMenu ? "active" : ""}`} onClick={() => setsubMenu(!subMenu)}>
      <>
        <DropDownSection
          style={{
            background: subMenu || itemPath === parentPath ? "#282C38" : "#1E1F25",
          }}
          onClick={() => setsubMenu(!subMenu)}
        >
          <MenuIcon src={subMenu ? item.icon2 : item.icon} />
          <MenuTitle
            style={{
              display: isOpen ? "block" : "none",
              color: subMenu || itemPath === parentPath ? "#FFFFFF" : "#757B8C",
            }}
          >
            {item.name}
          </MenuTitle>
          <MenuNotification className="text-end">
            {item.notification !== "" ? (
              isOpen ? (
                <MenuNotificationSpanActive>{item.notification}</MenuNotificationSpanActive>
              ) : (
                <MenuNotificationSpan>{item.notification}</MenuNotificationSpan>
              )
            ) : (
              <MenuMoreCategories>
                <MenuMoreCategoriesImg
                  style={{
                    width: isOpen ? "20px" : "12px",
                    height: isOpen ? "20px" : "12px",
                  }}
                  src={subMenu ? ArrowDownCircle : ArrowRightCircle}
                />
              </MenuMoreCategories>
            )}
          </MenuNotification>
        </DropDownSection>

        <SubMenuContainer>
          {item.moreCategories && subMenu && isOpen ? (
            <>
              {item.moreCategoriesObject.map((i) => (
                <SubLink to={i.path} key={i.id}>
                  {/* <SubMenuIcon src={ArrowRight} /> */}
                  <ArrowRightIcon />
                  <SubMenuText> {i.title} </SubMenuText>
                </SubLink>
              ))}
            </>
          ) : (
            <></>
          )}
        </SubMenuContainer>
      </>
    );
  }

  if (item.moreCategories && !isOpen) {
    const itemPath = item.path.replace("/", "").replace("/", "").replace("home", "");

    return (
      <>
        <PopOverButton aria-describedby={id} variant="contained" onClick={handleClick}>
          <DropDownSection
            style={{
              background: itemPath === parentPath ? "#282C38" : "#1E1F25",
            }}
            onClick={() => setsubMenu(!subMenu)}
          >
            <MenuIcon src={item.icon} />
            <MenuTitle
              style={{
                display: isOpen ? "block" : "none",
                color: subMenu ? "#FFFFFF" : "#757B8C",
              }}
            >
              {item.name}
            </MenuTitle>
            <MenuNotification className="text-end">
              {item.notification !== "" ? (
                isOpen ? (
                  <MenuNotificationSpanActive>{item.notification}</MenuNotificationSpanActive>
                ) : (
                  <MenuNotificationSpan>{item.notification}</MenuNotificationSpan>
                )
              ) : (
                <MenuMoreCategories>
                  <MenuMoreCategoriesImg
                    style={{
                      width: isOpen ? "20px" : "12px",
                      height: isOpen ? "20px" : "12px",
                    }}
                    src={ArrowRightCircle}
                  />
                </MenuMoreCategories>
              )}
            </MenuNotification>
          </DropDownSection>
        </PopOverButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          PaperProps={{
            sx: {
              background: "rgba(47, 50, 65, 0.5)",
              borderRadius: "8px",
              backdropFilter: "blur(15px)",
              margin: "0px 8px 0px 8px",
            },
          }}
        >
          <PopOverOpen>
            <SubMenuContainerPopOver>
              {item.moreCategories ? (
                <>
                  {item.moreCategoriesObject.map((i) => (
                    <SubLinkPopOver to={i.path} key={i.id} onClick={handleClose}>
                      {/* <SubMenuIcon src={ArrowRight} /> */}
                      <ArrowRightIcon />
                      <SubMenuText> {i.title} </SubMenuText>
                    </SubLinkPopOver>
                  ))}
                </>
              ) : (
                <></>
              )}
            </SubMenuContainerPopOver>
          </PopOverOpen>
        </Popover>
      </>
    );
  }

  return (
    <div>
      {item.path === "/vatandasformu" ? (
        <VatandasFormSection
          href="https://docs.google.com/spreadsheets/d/1mpg_wt9bNjkLYpP4YfP2THOEVfLxfAL82EHrkXp4IiI/edit#gid=1733120399"
          target="_blank"
        >
          <MenuIcon src={itemPath === parentPath ? item.icon2 : item.icon} />

          <MenuTitle style={{ display: isOpen ? "block" : "none" }}>{item.name}</MenuTitle>

          <MenuNotification className="text-end">
            {item.notification !== "" ? (
              isOpen ? (
                <MenuNotificationSpanActive>{item.notification}</MenuNotificationSpanActive>
              ) : (
                <MenuNotificationSpan>{item.notification}</MenuNotificationSpan>
              )
            ) : (
              <></>
            )}
          </MenuNotification>
        </VatandasFormSection>
      ) : (
        <NavlinkSection
          to={{
            pathname: item.path,
          }}
          key={index}
        >
          <MenuIcon src={itemPath === parentPath ? item.icon2 : item.icon} />

          <MenuTitle style={{ display: isOpen ? "block" : "none" }}>{item.name}</MenuTitle>

          {/* <MenuNotification className="text-end">
            {item.notification !== "" ? (
              isOpen ? (
                <MenuNotificationSpanActive>
                  {item.notification}
                </MenuNotificationSpanActive>
              ) : (
                <MenuNotificationSpan>{item.notification}</MenuNotificationSpan>
              )
            ) : (
              <></>
            )}
          </MenuNotification> */}
        </NavlinkSection>
      )}
    </div>
  );
};

export default SideBarNavLinks;
