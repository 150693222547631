import React, { useState } from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import image from "../../../../Assests/image 1.png";
import BBB_Logo_Small from "../../../../Assests/BBB_Logo_Small.png";
import TableUser from "./TableUser";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const HeaderTitle = styled.div``;

const BodyUsersContent = styled.div`
  margin: 0px;
  padding: 12px 24px;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;
  overflow: hidden;
`;

const GirisListesiBody = ({ data, getLogList, loading }) => {
  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row">
          <HeaderTitle className="col">Kullanıcı Adı</HeaderTitle>
          <HeaderTitle className="col">Tarih</HeaderTitle>
          <HeaderTitle className="col">IP</HeaderTitle>
          <HeaderTitle className="col text-start">Tarayıcı</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? (
          <NoneDataError />
        ) : (
          data.map((i, index) => (
            <TableUser
              key={index}
              item={i}
              index={index}
              getLogList={getLogList}
            />
          ))
        )}
      </Container>
    </>
  );
};

export default GirisListesiBody;
