import React, { useState } from 'react'
import HeadersMain from "../../../../Components/PageComponents/HeadersMain"
import Discovery from "../../../../Assests/Discovery.png"
import IletisimBilgileriBody from './IletisimBilgileriBody'

const IletisimBilgileriHome = () => {

    const [Props, setProps] = useState({
        title: "İletişim Bilgileri",
        img: Discovery,
    })


  return (
    <div>
        <HeadersMain Props={Props} />
        <IletisimBilgileriBody />
    </div>
  )
}

export default IletisimBilgileriHome