import React from "react";
import styled from "styled-components";

const Container = styled.span`
  color: red;
  display: block;
`;

const AlertTextYup = ({ Props }) => {
  return <Container>{Props}</Container>;
};

export default AlertTextYup;
