import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

const AlertContainer = styled(Alert)`
margin-top: 24px;
display: flex;
justify-content: space-between;
`

const AlertButton = styled(Button)`

`

const Alerts = ({result}) => {
  const [show, setShow] = useState(true);

  return (
    <>
      <AlertContainer show={show} variant={result  ? "success" : "danger"}>
        <Alert.Heading>{result ? "İşlem Başarılı !!" : "Hatalı İşlem !!"}</Alert.Heading>
          <AlertButton onClick={() => setShow(false)} variant="outline-success">
            X
          </AlertButton>
      </AlertContainer>

      {/* {!show && <Button onClick={() => setShow(true)}>Show Alert</Button>} */}
    </>
  )
}

export default Alerts