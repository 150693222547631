import React, { useEffect, useState } from "react";
import styled from "styled-components";

// ckEditor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import { useFormik } from "formik";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaPrivacyPolicy } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const Container = styled.div``;

const Row = styled.form`
  display: flex;
  margin-top: 16px;
  gap: 8px;
`;

const RowRight = styled.div`
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
  flex: 7;
`;

const InputContainer = styled.div`
  margin-bottom: 24px;
`;

const InputTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CkEditorSection = styled.div``;

const GizlilikPolitikasiBody = () => {
  const [responseData, setResponseData] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getProjectPurpose();
  }, []);

  const getProjectPurpose = async () => {
    const response = await axios.get("privacyPolicy/privacyPolicy");
    try {
      await response;
      setResponseData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const projectPurposeForm = useFormik({
    initialValues: {
      text: "",
    },
    validationSchema: validationSchemaPrivacyPolicy,

    onSubmit: (values) => {
      updateProjectPurpose(values);
    },
  });

  useEffect(() => {
    projectPurposeForm.setValues({
      text: responseData.text ?? "",
    });
  }, [responseData]);

  // updateProjectPurpose Update
  const updateProjectPurpose = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`privacyPolicy/privacyPolicy`, params);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        {loading && <OverlayLoading />}
        {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
        <Row onSubmit={projectPurposeForm.handleSubmit}>
          <RowRight>
            <InputContainer>
              <InputTitle>Sayfa İçeriği</InputTitle>
              <CkEditorSection className="editor">
                <CKEditor
                  editor={ClassicEditor}
                  data={projectPurposeForm.values.text || ""}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    projectPurposeForm.setFieldValue("text", data);
                  }}
                />
              </CkEditorSection>
              {projectPurposeForm.touched.text && projectPurposeForm.errors.text ? (
                <AlertTextYup Props={projectPurposeForm.errors.text} />
              ) : null}
            </InputContainer>

            {/* <code>updatedInfo : {JSON.stringify(projectPurposeForm.values.updatedInfo)}</code>{" "}
              <br />
              <code>text : {JSON.stringify(projectPurposeForm.values.text)}</code>{" "}
              <br />
              <code>images: {JSON.stringify(projectPurposeForm.values.images)}</code>
              <br />
              <code>imageExtension : {JSON.stringify(projectPurposeForm.values.imageExtension)}</code>{" "} */}

            <ModalButton type="submit">Sayfayı Güncelle</ModalButton>
          </RowRight>
        </Row>
      </Container>
    </>
  );
};

export default GizlilikPolitikasiBody;
