import React from 'react'
import styled from 'styled-components';
import SpinnerImg from "../../Assests/loadingAnim640.gif"


const Container = styled.div`
  /* background-color: white; */
  width: 100%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoadingImg = styled.img`
  width: 300px;
  height: 300px;
`;
const LoadingTable = () => {
  return (
    <Container>
        <LoadingImg src={SpinnerImg} />
    </Container>
  )
}

export default LoadingTable