import React from "react";
import styled from "styled-components";

import Edit from "../../../../Assests/Edit2.png";
import Logout from "../../../../Assests/Logout2.png";
import Profile2 from "../../../../Assests/Profile2.png";
import Profile4 from "../../../../Assests/Profile4.png";

import { NavLink } from "react-router-dom";

// React Dropdown
import Dropdown from "react-bootstrap/Dropdown";

// const Container = styled.div``
const Container = styled.div`
  width: 100%;
  /* border: solid 1px orange; */
  background: #1e1f25;
  border-radius: 20px;
  padding: 24px 16px;
`;

const Row = styled.div``;

const RowLeft = styled.div`
  width: 100%;
`;

const RowRight = styled.div``;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;
`;

const HeaderLogoContent = styled.div`
  width: 5%;
  align-items: center;
  justify-content: center;
`;

const HeaderLogo = styled.img`
  width: 29px;
  height: 29px;
`;

const HeaderCalenderSection = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #282c38;
`;

const HeaderIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const HeaderSpan = styled.span`
  margin-top: 1px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #246cf9;
`;

const HeaderSpan2 = styled.span`
  margin-top: 1px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const HeaderText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  overflow: hidden; // Alanın dışına taşanları gizle
  white-space: nowrap; // Alt satıra geçmeyi engelle
  text-overflow: ellipsis;

  /* identical to box height */
  color: #ffffff;
`;

// React Dropwdon Bootstrap

const ProfileDropdown = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const NavlinkProfilim = styled(NavLink)`
  padding: 16px 0px 16px 0px;
  display: flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  &.active {
    text-decoration: none;
    background: none;
  }
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const HaberlerDuzenleHeader = ({ responseData }) => {
  return (
    <Container>
      <Row className="d-flex justify-content-between ">
        <RowLeft className="row">
          <HeaderLogoContent className="col-1 d-flex justify-content-start">
            <HeaderLogo src={Edit} />
          </HeaderLogoContent>
          <HeaderTitle className="col-2 d-flex justify-content-start">Haber Düzenle</HeaderTitle>
          <HeaderCalenderSection className="col d-flex justify-content-start">
            <HeaderSpan>Haberler</HeaderSpan>
            <HeaderText className="ms-1">{">"} Haber Düzenle</HeaderText>
          </HeaderCalenderSection>
          <HeaderCalenderSection className="col-7 d-flex justify-content-start">
            <HeaderSpan2>Düzenlenen Haber</HeaderSpan2>
            <HeaderText className="ms-1">
              {">"} {responseData.title}
            </HeaderText>
          </HeaderCalenderSection>
        </RowLeft>

        <RowRight className="row">
          <ProfileDropdown className="col">
            <DropDownButton id="dropdown-basic">
              <HeaderIcon className="me-1" src={Profile2} />
              <HeaderText className="me-1">Dijital Sahne</HeaderText>
            </DropDownButton>

            <DropDownMenu>
              <DropDownMenuItem>
                <NavlinkProfilim
                  to={{
                    pathname: "/profilim",
                  }}
                >
                  <DropdownIcon src={Profile4} alt="" />
                  <DropdownText>Profilim</DropdownText>
                </NavlinkProfilim>
              </DropDownMenuItem>
              <DropDownMenuItem>
                <NavlinkProfilim>
                  <DropdownIcon src={Logout} alt="" />
                  <DropdownText>Çıkış</DropdownText>
                </NavlinkProfilim>
              </DropDownMenuItem>
            </DropDownMenu>
          </ProfileDropdown>
        </RowRight>
      </Row>
    </Container>
  );
};

export default HaberlerDuzenleHeader;
