import React from 'react'
import styled from 'styled-components'
import ProfilimBody from './ProfilimBody'
import Profile from "../../../../Assests/Profile.png";
import HeadersMain from '../../../../Components/PageComponents/HeadersMain';


const Container = styled.div``

const Profilim = () => {

  const Props = {
    title: "Profilim",
    img: Profile
  }

  return (
    <Container>
        <HeadersMain Props={Props} />
        <ProfilimBody />
    </Container>
  )
}

export default Profilim