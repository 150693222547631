import React, { useState } from "react";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Profile from "../../../../Assests/Profile.png";
import BaskaninMesajiBody from "./BaskaninMesajiBody";

const BaskaninMesajiHome = () => {
  const [Props, setProps] = useState({
    title: "Başkanın Mesajı",
    img: Profile,
  });

  return (
    <div className="">
      <HeadersMain Props={Props} />
      <BaskaninMesajiBody />
    </div>
  );
};

export default BaskaninMesajiHome;
