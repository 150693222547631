import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";

import ArrowDown from "../../Assests/Arrow - Down 3.png";
import Logout from "../../Assests/Logout2.png";
import Profile2 from "../../Assests/Profile2.png";
import Profile4 from "../../Assests/Profile4.png";

// React Dropdown
import Dropdown from "react-bootstrap/Dropdown";

// const Container = styled.div``
const Container = styled.div`
  width: 100%;
  /* border: solid 1px orange; */
  background: #1e1f25;
  border-radius: 20px;
  padding: 24px 16px;
`;

const Row = styled.div``;

const RowLeft = styled.div``;

const RowRight = styled.div``;

const HeaderTitle = styled.div`
  overflow: hidden; // Alanın dışına taşanları gizle
  white-space: nowrap; // Alt satıra geçmeyi engelle
  text-overflow: ellipsis;

  display: flex;
  align-items: center;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  margin-left: -5%;
`;

const HeaderLogoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderLogo = styled.img`
  width: 29px;
  height: 29px;
`;

const HeaderCalenderSection = styled.div`
  display: flex;
  align-items: center;
  border-left: 2px solid #282c38;
`;

const HeaderIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const HeaderSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const HeaderText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  overflow: hidden; // Alanın dışına taşanları gizle
  white-space: nowrap; // Alt satıra geçmeyi engelle
  text-overflow: ellipsis;

  /* identical to box height */
  color: #ffffff;
`;

const HeaderInputSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  /* margin-right: -35%; */
`;

const InputIcon = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 32px;
`;

const Input = styled.input`
  box-sizing: border-box;
  padding: 8px 16px 8px 48px;
  gap: 8px;

  width: 278px;
  height: 40px;
  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const HeaderUserSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
`;

const HeaderIconDropDown = styled.img`
  width: 8.17px;
  height: 4.08px;
`;

// React Dropwdon Bootstrap

const ProfileDropdown = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  padding: 16px;
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const NavlinkProfilim = styled(NavLink)`
  /* padding: 16px 0px 16px 0px; */
  display: flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  &.active {
    text-decoration: none;
    background: none;
  }
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const LogoutSection = styled.div`
  /* padding: 16px 0px 16px 0px; */
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const HeadersMain = ({ Props }) => {
  const tempTitle = Props.title === "Kullanıcı Giriş Listesi (LOG’s)" ? true : false;

  const navigate = useNavigate();

  const LogOutFunc = async () => {
    await localStorage.clear();
    await navigate("/auth/login");
  };

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const today = new Date().toLocaleString("tr-TR", options);

  return (
    <Container>
      <Row className="d-flex justify-content-between">
        <RowLeft style={{ width: tempTitle ? "60%" : "" }} className="row">
          <HeaderLogoContent className="col-2">
            <HeaderLogo src={Props.img} />
          </HeaderLogoContent>
          <HeaderTitle className="col">{Props.title}</HeaderTitle>
          <HeaderCalenderSection className="col">
            <HeaderSpan>Bugün:</HeaderSpan>
            <HeaderText className="ms-1">{today}</HeaderText>
          </HeaderCalenderSection>
        </RowLeft>

        <RowRight className="row">
          {/* <HeaderInputSection className="col">
            <InputIcon src={Search} />
            <Input placeholder="Sistem İçinde Arama Yap" />
          </HeaderInputSection> */}

          <ProfileDropdown className="col">
            <DropDownButton id="dropdown-basic">
              <HeaderIcon className="me-1" src={Profile2} />
              <HeaderText className="me-1">Dijital Sahne</HeaderText>
              <HeaderIcon className="me-1" style={{ width: "14px", height: "14px" }} src={ArrowDown} />
            </DropDownButton>

            <DropDownMenu>
              <DropDownMenuItem as={NavlinkProfilim} to={`/profilim`}>
                <DropdownIcon src={Profile4} alt="" />
                <DropdownText>Profilim</DropdownText>
              </DropDownMenuItem>

              <DropDownMenuItem onClick={LogOutFunc}>
                <LogoutSection>
                  <DropdownIcon src={Logout} alt="" />
                  <DropdownText>Çıkış</DropdownText>
                </LogoutSection>
              </DropDownMenuItem>
            </DropDownMenu>
          </ProfileDropdown>
        </RowRight>
      </Row>
    </Container>
  );
};

export default HeadersMain;
