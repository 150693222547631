import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HaberlerMainBody from "./HaberlerMainBody";

import Edit from "../../../../Assests/Edit2.png";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Navbars from "../../../../Components/PageComponents/Navbars";
import NavbarNew from "../../../../Components/PageComponents/NavbarNew";

// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

import axios from "../../../../utils/axios";

const Container = styled.div``;

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HaberlerMain = () => {
  const [Props, setProps] = useState({
    title: "Haberler",
    img: Edit,
    navbarInputTitle: "Haberler İçinde Ara",
    navbarFilter: false,
    navbarButtonTitle: "Yeni Haber Ekle",
    path: "/haberler/haberlerEkle",
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  useEffect(() => {
    getNews(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getNews = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 10 };
      setFilterParams(params);
      const response = await axios.get("news/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      title: "",
      isActive: "",
    },
    getFilterData: getNews,
    setPage: setPage,
    titleInput: "Başlık İçinde Ara",
    yayinDropDown: [
      {
        id: 1,
        title: "Pasif",
        durum: false,
      },
      {
        id: 2,
        title: "Aktif",
        durum: true,
      },
    ],
  };

  return (
    <Container>
      <HeadersMain Props={Props} />
      {/* <Navbars Props={Props} FilterCategories={FilterCategories} /> */}
      <NavbarNew Props={Props} FilterCategories={FilterCategories} />

      <HaberlerMainBody
        data={responseData.length > 0 ? responseData : []}
        getNews={(filterParams) => getNews(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination
            color="primary"
            count={endPage}
            page={page}
            onChange={(event, value) => setPage(value)}
          />
        </Stack>
      </PaginationSection>
    </Container>
  );
};

export default HaberlerMain;
