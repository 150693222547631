import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Ayarlar from "../../Assests/Ayarlar.png";
import Bagislar from "../../Assests/Bagislar.png";
import BagisNoktalari from "../../Assests/BagisNoktalari.png";
import BBB_Logo_Small from "../../Assests/BBB_Logo_Small.png";
import Category from "../../Assests/Category.png";
import Category2 from "../../Assests/Category2.png";
import Duyurular from "../../Assests/Duyurular.png";
import Edit3 from "../../Assests/Edit3.png";
import Fill1 from "../../Assests/Fill 1.png";
import Frame128 from "../../Assests/Frame 128.png";
import Frame129 from "../../Assests/Frame 129.png";
import Frame130 from "../../Assests/Frame 130.png";
import Graph from "../../Assests/Graph.png";
import Haberler from "../../Assests/Haberler.png";
import Heart from "../../Assests/Heart.png";
import Location from "../../Assests/Location.png";
import Logout from "../../Assests/Logout.png";
import needHelp from "../../Assests/needHelp.png";
import Password from "../../Assests/Password.png";
import Raporlar from "../../Assests/Raporlar.png";
import SabitSayfalar from "../../Assests/Sabit Sayfalar.png";
import Slider from "../../Assests/Slider.png";
import Tanimlar from "../../Assests/Tanimlar.png";
import VatandasFormu from "../../Assests/VatandasFormu.png";
import Voice from "../../Assests/Voice 3.png";
import Work from "../../Assests/Work.png";
import YardimTalepleri from "../../Assests/YardimTalepleri.png";
import SideBarNavLinks from "./SideBarNavLinks";

import axios from "../../utils/axios";

// const Container = styled.div``
const Container = styled.div`
  padding-top: 2vh;
  padding-bottom: 2vh;
  width: 100%;
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 24px;

  /* border: 3px solid red; */
  /* margin-left: 24px; */
`;

const RowSideBar = styled.div`
  position: sticky;
  top: 2vh;
  height: 100vh;
  width: 15%;
  background: #1e1f25;
  border-radius: 20px;
  padding: 24px 0px 0px;
  /* gap: 24px; */

  /* overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
  } */

  transition: all 0.4s ease-in-out;
`;

const NextPanel = styled.div`
  /* margin-top: 2.5%; */
  /* border: 1px solid white; */
  color: white;
`;

const HeaderContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 5px;
`;

const HeaderImgContent = styled.div``;

const HeaderLogo = styled.img`
  width: 32.18px;
  height: 32px;
`;

const HeaderTitle = styled.div`
  /* height: 8px; */
  margin-left: 2px;

  /* Caps/Caps 1 - Semibold */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;
`;

const HeaderIconBig = styled.img`
  width: 37px;
  height: 21px;
  color: #757b8c;
  margin-left: 8px;
`;
const HeaderIconBack = styled.img`
  width: 20px;
  height: 21px;
  cursor: pointer;
`;

const HeaderIconContent = styled.div`
  display: ${(props) => (props.isOpen ? "" : "flex")};
`;

const Hr = styled.hr`
  width: 100%;
  height: 2px;

  /* Base Color/Grey 80 */

  background: #282c38;
`;

// const MenuItemContext = styled.div`
// padding: 0px 16px;
// gap: 16px;

// display: flex;
// align-items: center;

// width: 285px;
// height: 44px;

// /* Base Color/Grey 80 */

// `

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const MenuTitle = styled.div`
  width: 90%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */
  /* color: #757b8c; */
`;

const MenuNotification = styled.div`
  width: 10%;
`;

const MenuNotificationSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12px;
  height: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;

  padding-bottom: 35%;
  padding-right: 15%;

  /* Accent Color/Red Primary */

  background: #fa2256;
  border-radius: 12px;
`;

const MenuNotificationSpanActive = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  color: #ffffff;

  /* Accent Color/Red Primary */

  background: #fa2256;
  border-radius: 12px;
`;

const MenuMoreCategories = styled.div`
  background: #757b8c;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const MenuMoreCategoriesImg = styled.img``;

const BottomExit = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 18px 16px;
  gap: 8px;

  width: 100%;
  height: 60px;

  /* Base Color/Grey 80 */

  background: #282c38;
  border-radius: 0px 0px 20px 20px;
`;

const ExitTitle = styled.div`
  width: 62px;
  height: 17px;

  /* Heading/Heading 5 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const NavLinksContainer = styled.div`
  height: 77vh;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0px;
  }
`;

const SideBar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  // const [moreCategoriesActive, setMoreCategoriesActive] = useState(false);
  const navigate = useNavigate();
  const [responseData, setResponseData] = useState([]);

  useEffect(() => {
    getDonations();
  }, []);

  const getDonations = async () => {
    const response = await axios.get("account/dashboard");
    try {
      await response;
      setResponseData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: Category2,
      icon2: Category,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/slider",
      name: "Slider Ayarları",
      icon: Slider,
      icon2: Slider,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/bagislar",
      name: "Bağışlar",
      icon: Bagislar,
      icon2: Heart,
      notification: responseData.donationWaitingCount,
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/haberler",
      name: "Haberler",
      icon: Haberler,
      icon2: Edit3,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/duyurular",
      name: "Duyurular",
      icon: Duyurular,
      icon2: Voice,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/yardimtalepleri",
      name: "Yardim Talepleri",
      icon: YardimTalepleri,
      icon2: needHelp,
      notification: responseData.donationRequestWaitingCount,
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/bagisnoktalari",
      name: "Bağış Noktaları",
      icon: BagisNoktalari,
      icon2: Location,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/vatandasformu",
      name: "Vatandas Formu",
      icon: VatandasFormu,
      icon2: VatandasFormu,
      notification: "",
      moreCategories: "",
      moreCategoriesObject: "",
    },
    {
      path: "/tanimlar",
      name: "Tanimlar",
      icon: Tanimlar,
      icon2: Work,
      notification: "",
      moreCategories: Fill1,
      moreCategoriesObject: [
        {
          id: 1,
          title: "Kullanıcılar",
          path: "/tanimlar/kullanicilar",
        },
        {
          id: 2,
          title: "Bağış Tipleri",
          path: "/tanimlar/bagisTipleri",
        },
      ],
    },
    {
      path: "/sabitsayfalar",
      name: "Sabit Sayfalar",
      icon: SabitSayfalar,
      icon2: Password,
      notification: "",
      moreCategories: Fill1,
      moreCategoriesObject: [
        {
          id: 1,
          title: "Başkanın Mesajı",
          path: "/sabitsayfalar/baskaninMesaji",
        },
        {
          id: 2,
          title: "Projenin Amacı",
          path: "/sabitsayfalar/projeninAmaci",
        },
        {
          id: 3,
          title: "İşbirliklerimiz",
          path: "/sabitsayfalar/isbirliklerimiz",
        },
        {
          id: 4,
          title: "Gizlilik Politikası",
          path: "/sabitsayfalar/gizlilikPolitikasi",
        },
        {
          id: 5,
          title: "İletişim Bilgileri",
          path: "/sabitsayfalar/iletisimBilgileri",
        },
      ],
    },
    {
      path: "/raporlar",
      name: "Raporlar",
      icon: Raporlar,
      icon2: Graph,
      notification: "",
      moreCategories: Fill1,
      moreCategoriesObject: [
        {
          id: 1,
          title: "Hata Listesi",
          path: "/raporlar/hataListesi",
        },
        {
          id: 2,
          title: "Bildirim Listesi",
          path: "/raporlar/bildirimListesi",
        },
        {
          id: 3,
          title: "Tablo Listesi",
          path: "/raporlar/tabloListesi",
        },
        {
          id: 4,
          title: "Kullanıcı Giriş Listesi(LOG's)",
          path: "/raporlar/girisListesi",
        },
      ],
    },
    {
      path: "/ayarlar",
      name: "Ayarlar",
      icon: Ayarlar,
      icon2: Graph,
      notification: "",
      moreCategories: Fill1,
      moreCategoriesObject: [
        {
          id: 1,
          title: "E-Mail Ayarları",
          path: "/ayarlar/emailAyarlari",
        },
      ],
    },
  ];

  const LogOutFunc = async () => {
    await localStorage.clear();
    await navigate("/auth/login");
  };

  return (
    <>
      <Container>
        <RowSideBar style={{ width: isOpen ? "15%" : "5%" }}>
          <HeaderContent style={{ flexDirection: isOpen ? "" : "column" }}>
            <HeaderImgContent>
              <HeaderLogo src={BBB_Logo_Small} />
            </HeaderImgContent>
            <HeaderTitle style={{ display: isOpen ? "block" : "none" }}>İMECE WEB PANEL</HeaderTitle>
            <HeaderIconContent>
              <HeaderIconBig src={Frame128} />
              {isOpen ? (
                <HeaderIconBack onClick={() => setIsOpen(!isOpen)} className="ms-1" src={Frame129} />
              ) : (
                <HeaderIconBack onClick={() => setIsOpen(!isOpen)} className="ms-1" src={Frame130} />
              )}
            </HeaderIconContent>
          </HeaderContent>
          <Hr />

          <NavLinksContainer>
            {menuItem.map((item, index) => (
              <SideBarNavLinks item={item} index={index} key={index} isOpen={isOpen} responseData={responseData} />
            ))}
          </NavLinksContainer>

          <BottomExit onClick={LogOutFunc} style={{ justifyContent: isOpen ? "start" : "center" }}>
            <MenuIcon src={Logout} />
            <ExitTitle style={{ display: isOpen ? "block" : "none" }}>Çıkış Yap</ExitTitle>
          </BottomExit>
        </RowSideBar>
        <NextPanel style={{ width: isOpen ? "80%" : "90%" }}>{children}</NextPanel>
      </Container>
    </>
  );
};

export default SideBar;
