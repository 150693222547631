import * as Yup from "yup";

export const validationSchemaLogin = Yup.object().shape({
  email: Yup.string()
    .email("Geçerli bir formatta giriniz")
    .required("E-mail adresinizi giriniz")
    .typeError("Geçerli bir formatta giriniz"),
  password: Yup.string().required("Şifrenizi giriniz").typeError(""),
});

export const validationSchemaResetPassword = Yup.object().shape({
  email: Yup.string().email("Geçerli bir formatta giriniz").required("E-mail alanı zorunludur"),
});

export const validationSchemaDonationDetails = Yup.object().shape({
  publishTitle: Yup.string().required("Yayın Başlığı girilmesi zorunludur"),
  publishExplanation: Yup.string().required("Açıklama girilmesi zorunludur"),
});

export const validationSchemaNews = Yup.object().shape({
  title: Yup.string().required("Başlık Zorunlu Alan!"),
  text: Yup.string().required("İçerik Zorunlu Alan!"),
});

export const validationSchemaAnnouncement = Yup.object().shape({
  title: Yup.string().required("Başlık alanı girilmesi zorunludur"),
  text: Yup.string().required("İçerik alanı girilmesi zorunludur"),
});

export const validationSchemaDonation = Yup.object().shape({
  name: Yup.string().required("İsim alanı zorunludur"),
  tel: Yup.string()
    .required("Telefon numarası alanı zorunludur")
    .matches(/^[0-9]{10}$/, "Geçerli bir telefon numarası giriniz")
    .max(10, "maximum 10 karakter olmalı"),
  email: Yup.string().required("E-mail alanı zorunludur"),
  explanation: Yup.string().required("Bağış açıklama alanını doldurunuz"),
});

export const validationSchemaDonationRequest = Yup.object().shape({
  result: Yup.string().required("Talep Sonucu girilmesi zorunludur").typeError("Talep Sonucu girilmesi zorunludur"),
  tc: Yup.number().typeError("sayı girilmesi zorunludur").required("sayı girilmesi zorunludur"),
});

export const validationSchemaDonationLocation = Yup.object().shape({
  name: Yup.string().required("Zorunlu Alan İsim!"),
  address: Yup.string().required("Zorunlu Alan Adres!"),
});

export const validationSchemaUsers = Yup.object().shape({
  name: Yup.string().required("İsim alanı zorunludur"),
  lastname: Yup.string().required("Soyad alanı zorunludur"),
  email: Yup.string().email("Mail Formatı Zorunlu !!").required("Mail alanı zorunludur"),
  tel: Yup.string()
    .matches(/^[0-9]{10}$/, "Geçerli bir telefon numarası giriniz")
    .max(10, "maximum 10 karakter olmalı")
    .required("Telefon numarası alanı zorunludur"),
  userTypeId: Yup.string().required("Kullanıcı Tipi alanı zorunludur"),
});

export const validationSchemaDonationType = Yup.object().shape({
  name: Yup.string().required("Zorunlu Alan name!!"),
  image: Yup.string().nullable().required("Zorunlu Alan image!!"),
});

export const validationMayorMessage = Yup.object().shape({
  updatedInfo: Yup.string().required("Zorunlu Alan updatedInfo!!"),
  text: Yup.string().required("Zorunlu Alan text!!"),
});

export const validationPartnerMessage = Yup.object().shape({
  updatedInfo: Yup.string().nullable(),
  text: Yup.string().nullable(),
});

export const validationSchemaPrivacyPolicy = Yup.object().shape({
  text: Yup.string().required("Zorunlu Alan text!!"),
});

export const validationSchemaContactInformation = Yup.object().shape({
  email: Yup.string().email("Mail Formatı Zorunlu !!").required("Zorunlu Alan Mail !!"),
  address: Yup.string().required("Zorunlu Alan Adres!!"),
  tel: Yup.string().required("Zorunlu Alan tel!!"),
});

export const validationSchemaSettings = Yup.object().shape({
  emailAddress: Yup.string().email("emailAddress Formatı Zorunlu !!").required("Zorunlu Alan emailAddress !!"),
  emailHost: Yup.string().required("Zorunlu Alan emailHost!!"),
  emailPassword: Yup.string().required("Zorunlu Alan emailPassword !!"),
  emailPort: Yup.string().required("Zorunlu Alan emailPort !!"),
});

export const validationSchemaProfile = Yup.object().shape({
  currentPassword: Yup.string().required("Zorunlu Alan!"),
  newPassword: Yup.string()
    .when("currentPassword", (currentPassword, field) => (currentPassword ? field.required() : field))
    .required("Zorunlu Alan!"),
  newPasswordConfirm: Yup.string()
    .when("newPassword", (newPassword, field) =>
      newPassword ? field.required().oneOf([Yup.ref("newPassword")], "Yeni şifre ile uyuşmuyor") : field
    )
    .required("Zorunlu Alan!"),
});
