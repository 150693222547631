import React, { useState } from 'react'
import HeadersMain from "../../../../Components/PageComponents/HeadersMain"
import Message from "../../../../Assests/Message.png"
import EmailAyarlariBody from './EmailAyarlariBody'

const EmailAyarlariHome = () => {

    const [Props, setProps] = useState({
        title: "E-Mail Ayarları",
        img: Message,
    })


  return (
    <div>
        <HeadersMain Props={Props} />
        <EmailAyarlariBody />
    </div>
  )
}

export default EmailAyarlariHome