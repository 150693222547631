import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HaberlerDuzenleBody from "./HaberlerDuzenleBody";
import HaberlerDuzenleHeader from "./HaberlerDuzenleHeader";

import axios from "../../../../utils/axios";

const HaberlerDuzenle = () => {
  const [responseData, setResponseData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    getDonationId();
  }, []);

  const getDonationId = async () => {
    const params = {
      id: id,
    };

    try {
      const response = await axios.get("news/new", {
        params: params,
      });

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HaberlerDuzenleHeader responseData={responseData} />
      <HaberlerDuzenleBody responseData={responseData} setResponseData={setResponseData} />
    </>
  );
};

export default HaberlerDuzenle;
