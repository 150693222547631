import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import MailIcon from "../Assests/MailIcon.svg";
import PasswordIcon from "../Assests/PasswordIcon.svg";
import { setSession } from "../utils/session";
import axios from "../utils/axios";
import { useFormik } from "formik";
import OverlayLoading from "../Components/PageComponents/OverlayLoading";
import { validationSchemaLogin } from "../Components/FormValidations/FomikYup";
import AlertTextYup from "../Components/FormValidations/AlertTextYup";
import Button from "react-bootstrap/Button";
import TextField from "@mui/material/TextField";
const Container = styled.div`
  background: #1e1e1e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.form``;

const HeaderTitle = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3c4254;
`;
const InputContainer = styled.div`
  position: relative;
`;
const Input = styled.input`
  box-sizing: border-box;
  padding: 16px 16px 16px 12%;
  gap: 8px;
  width: 416px;
  height: 56px;
  background: #1e1f25;
  border: ${({ hasError }) => (hasError ? "#ff0000" : "transparent")};
  border-radius: 10px;

  /* Input Text */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #757b8c;
  }

  &:focus {
    outline: none;
    border: 1px solid
      ${({ hasError }) => (hasError ? "#ff0000" : "transparent")};
  }
`;

const InputSpanImg = styled.img`
  width: 20px;
  height: 18px;
  position: absolute;
  left: 5%;
  right: 8.33%;
  top: 34%;
  bottom: 12.5%;
`;

const InputSpanImgRight = styled.img`
  width: 20px;
  height: 18px;
  position: absolute;
  /* right: 2%; */
  right: 5%;
  top: 34%;
  bottom: 12.5%;
  cursor: pointer;
`;

const ForgetText = styled.p`
  width: 416px;
  text-align: end;
`;

const ForgetNavlink = styled(NavLink)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;

  &:hover {
    color: #ffffff;
  }
`;

const Buttons = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;
  border: none;

  width: 416px;
  height: 51px;

  background: #246cf9;
  border-radius: 10px;
`;

const ButtonSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const SignIn = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: validationSchemaLogin,

    onSubmit: async (values) => {
      await loginFormSend(values);
      await loginForm.setValues({
        email: "",
        password: "",
      });
    },
  });

  // loginFormSend Update
  const loginFormSend = async (values) => {
    setLoading(true);
    try {
      const headers = {
        "x-token-static": "8f7z?25aE52_F9?4k63yBxd2_75yB7!393bd37",
      };
      const response = await axios.post(`account/login`, values, {
        headers: headers,
      });
      await response;

      setSuccess("Login successful!");
      const { token } = response.data;
      setSession(token);
      navigate("/");
    } catch (error) {
      setError("Kullanıcı adı veya Parola yanlış");
      // setError(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Row onSubmit={loginForm.handleSubmit}>
        <HeaderTitle className="mb-4">Sisteme Giriş Yap</HeaderTitle>
        <InputContainer className="my-2">
          <InputSpanImg src={MailIcon} alt="" />
          <Input
            name="email"
            type="email"
            value={loginForm.values.email}
            placeholder="E-Mail Adresiniz"
            onBlur={loginForm.handleBlur}
            onChange={loginForm.handleChange}
            hasError={
              (loginForm.touched.email && loginForm.errors.email) ||
              (error && !loading)
            }
          />
        </InputContainer>
        {loginForm.touched.email && loginForm.errors.email ? (
          <AlertTextYup Props={loginForm.errors.email} />
        ) : null}
        <InputContainer className="mb-2">
          <InputSpanImg src={PasswordIcon} alt="" />
          <Input
            name="password"
            value={loginForm.values.password}
            placeholder="Şifreniz"
            onChange={loginForm.handleChange}
            onBlur={loginForm.handleBlur}
            type={showPassword ? "text" : "password"}
            hasError={
              (loginForm.touched.password && loginForm.errors.password) ||
              (error && !loading)
            }
          />

          <Button
            onClick={() => setShowPassword(!showPassword)}
            style={{
              color: "white",
              height: "18px",
              position: "absolute",
              right: "0",
              top: "22%",
              cursor: "pointer",
              background: "transparent",
              border: "none",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="white"
              class="bi bi-eye"
              viewBox="0 0 16 16"
            >
              <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
              <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
            </svg>
          </Button>
        </InputContainer>
        {loginForm.touched.password && loginForm.errors.password ? (
          <AlertTextYup Props={loginForm.errors.password} />
        ) : null}
        <ForgetText className="my-3">
          <ForgetNavlink
            to={{
              pathname: "/auth/forgetpassword",
            }}
          >
            Şifrenizi mi unuttunuz?
          </ForgetNavlink>
        </ForgetText>

        {loading && <OverlayLoading />}
        {error && <p className="text-danger">{error}</p>}
        {success && <p className="text-success">{success}</p>}

        <Buttons type="submit">
          <ButtonSpan>Giriş Yap</ButtonSpan>
        </Buttons>
      </Row>
    </Container>
  );
};

export default SignIn;
