import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Heart from "../../../../Assests/needHelp.png";
import Headers from "../../../../Components/PageComponents/HeadersMain";
import NavbarNew from "../../../../Components/PageComponents/NavbarNew";
import axios from "../../../../utils/axios";
import YardimTalepleriBody from "./YardimTalepleriBody";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const YardimTaleperiHome = () => {
  const [Props, setProps] = useState({
    title: "Yardim Talepleri",
    img: Heart,
    navbarButtonTitle: "",
    path: "",
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(10);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");

  // Dropdowns

  const [responseDataTypes, setResponseDataTypes] = useState([]);
  const [responseDataChannels, setResponseDataChannels] = useState([]);
  const [responseDataStatus, setResponseDataStatus] = useState([]);

  // DonationTypes - Bebek bezi, Bebek maması
  const getDonationRequestTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationTypes", {
      params: params,
    });
    try {
      await response;
      setResponseDataTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // DonationTypes - Mobil Web
  const getDonationRequestChannels = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestChannels", {
      params: params,
    });
    try {
      await response;
      setResponseDataChannels(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Donations Status - Beklemede, İptal
  const getDonationRequestStatus = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestStatus", {
      params: params,
    });
    try {
      await response;
      setResponseDataStatus(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getDonationRequestStatus();
    getDonationRequestChannels();
    getDonationRequestTypes();
  }, []);

  useEffect(() => {
    getDonationRequest(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getDonationRequest = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = { ...valuesFilter, page: page, take: 20 };
      setFilterParams(params);
      const response = await axios.get("donationRequests/list", {
        params: params,
      });
      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const FilterCategories = {
    initialValues: {
      name: "",
      address: "",
      tc: "",
      createdDate: "",
      donationRequestChannelId: "",
      donationRequestStatuId: "",
      donationTypeId: "",
    },
    getFilterData: getDonationRequest,
    setPage: setPage,
    adInput: "Ad ile Arama Yapın",
    tcInput: "TC ile Arama Yapın",
    addressInput: "Adres ile Arama Yapın",
    kanalDropDown: responseDataChannels,
    tipDropDown: responseDataTypes,
    durumDropDown: responseDataStatus,
    talepDurumuDropwn: responseDataStatus,
  };

  return (
    <div>
      <Headers Props={Props} />
      <NavbarNew Props={Props} FilterCategories={FilterCategories} data={responseData.length > 0 ? responseData : []} />
      <YardimTalepleriBody
        data={responseData.length > 0 ? responseData : []}
        getData={(filterParams) => getDonationRequest(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default YardimTaleperiHome;
