import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../utils/axios";

// Checkbox React Bootstrap
import { useFormik } from "formik";

import GoogleMapReact from "google-map-react";

import { FaMapMarkerAlt } from "react-icons/fa";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaContactInformation } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const Container = styled.div``;

const Row = styled.form``;

const RowTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin-bottom: 8px;
  margin-top: 16px;
`;

const RowContainer = styled.div`
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
`;

const RowBottom = styled.div``;

const RowContainerTitle = styled.div`
  margin-top: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const Input = styled.input`
  width: 100%;
  margin-top: 8px;
  padding: 16px;
  gap: 8px;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const ButtonBottom = styled.button`
  width: 100%;
  flex: 1;
  padding: 16px 8px;
  gap: 8px;

  width: 661.5px;
  height: 51px;

  /* Main Color/Royalblue 100 */

  /* background: #246CF9; */
  background: ${(props) => (props.blue ? "#246CF9" : "#3C4254")};
  border-radius: 10px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const TextareaContainer = styled.div``;

const TextareaContainerInside = styled.div`
  position: relative;
  display: flex;
`;

const TextareaLeft = styled.textarea`
  padding: 16px 32px 16px 16px;
  gap: 8px;

  height: 130px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  width: 100%;

  resize: none;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #ffffff;

  &::placeholder {
    color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};
  }
`;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};
  margin-top: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const GoogleMapSection = styled.div`
  border-radius: 20px;
  height: 300px;
  position: relative;
  overflow: hidden;
`;

const IletisimBilgileriBody = () => {
  const [responseData, setResponseData] = useState({});

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  // google maps
  const [marker, setMarker] = useState(null);
  const [latRes, setLatRes] = useState(null);
  const [lngRes, setLngRes] = useState(null);

  useEffect(() => {
    getDonationRequestId();
  }, []);

  const getDonationRequestId = async () => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.get("contacts/contact");
      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // requestForm Update
  const UpdateDonationRequestForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };
      const response = await axios.put(`contacts/contact`, params);
      await response;
      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const requestForm = useFormik({
    initialValues: {
      email: "",
      tel: "",
      address: "",
      location: "",
      updatedInfo: "",
      submitType: "cancel",
    },

    validationSchema: validationSchemaContactInformation,

    onSubmit: (values, { setSubmitting }) => {
      if (values.submitType === "cancel") {
        // TestSettingsForm(values)
        getDonationRequestId();
      } else if (values.submitType === "update") {
        UpdateDonationRequestForm(values);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    requestForm.setValues({
      email: responseData.email ?? "",
      tel: responseData.tel ?? "",
      address: responseData.address ?? "",
      location: responseData.location ?? "",
      updatedInfo: responseData.updatedInfo ?? "",
      submitType: "cancel",
    });

    var string = responseData.location ?? "";
    var index = string.indexOf(",");
    var lat = string.substring(0, index);
    var lng = string.substring(index + 1);
    setMarker({ lat, lng });

    setLatRes(parseFloat(lat));
    setLngRes(parseFloat(lng));
  }, [responseData]);

  const defaultCenter = responseData ? { lat: latRes, lng: lngRes } : { lat: 37.7749, lng: -122.4194 };

  const handleMapClick = (event) => {
    const lat = event.lat;
    const lng = event.lng;
    setMarker({ lat, lng });
    requestForm.setFieldValue("location", lat + "," + lng);
  };

  return (
    <Container>
      {loading && <OverlayLoading />}
      {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
      <Row className="row" onSubmit={requestForm.handleSubmit}>
        <RowBottom>
          <RowTitle>Bağış Noktası Bilgileri</RowTitle>
          <RowContainer>
            <RowContainerTitle>Email</RowContainerTitle>
            <Input
              onChange={requestForm.handleChange}
              name="email"
              value={requestForm.values.email || ""}
              onBlur={requestForm.handleBlur}
            />

            {requestForm.touched.email && requestForm.errors.email ? (
              <AlertTextYup Props={requestForm.errors.email} />
            ) : null}

            <RowContainerTitle>Tel</RowContainerTitle>
            <Input
              onChange={requestForm.handleChange}
              name="tel"
              value={requestForm.values.tel}
              onBlur={requestForm.handleBlur}
            />

            {requestForm.touched.tel && requestForm.errors.tel ? <AlertTextYup Props={requestForm.errors.tel} /> : null}

            <TextareaContainer>
              <InputTitle>Adres</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  onChange={requestForm.handleChange}
                  name="address"
                  value={requestForm.values.address}
                  onBlur={requestForm.handleBlur}
                />
              </TextareaContainerInside>
            </TextareaContainer>

            {requestForm.touched.address && requestForm.errors.address ? (
              <AlertTextYup Props={requestForm.errors.address} />
            ) : null}

            <InputTitle>Konum</InputTitle>
            {responseData !== null && requestForm.values.location.length > 0 && (
              <GoogleMapSection>
                <GoogleMapReact
                  onClick={handleMapClick}
                  defaultCenter={defaultCenter}
                  defaultZoom={15}
                  bootstrapURLKeys={{
                    key: "AIzaSyC-W01cZQc3tf0-U9ncIeZYhMLuAwcKthc",
                  }}
                >
                  {marker && <Marker lat={marker.lat} lng={marker.lng} />}
                </GoogleMapReact>
              </GoogleMapSection>
            )}

            <ButtonContainer>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="cancel"
                onClick={() => {
                  requestForm.setFieldValue("submitType", "cancel");
                }}
              >
                Düzenlemeyi İptal Et
              </ButtonBottom>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="update"
                onClick={() => {
                  requestForm.setFieldValue("submitType", "update");
                }}
                blue
              >
                Değişiklikleri Kaydet
              </ButtonBottom>
            </ButtonContainer>
          </RowContainer>
        </RowBottom>
      </Row>
    </Container>
  );
};

const Marker = () => (
  <div style={{ color: "red", fontSize: "24px" }}>
    <FaMapMarkerAlt />
  </div>
);

export default IletisimBilgileriBody;
