import React from "react";
import styled from "styled-components";

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const HeaderTitle = styled.div``;

const BodyUsersContent = styled.div`
  margin: 0px;
  padding: 12px 24px;

  background: #282c38;
`;

const TitleName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;
  overflow: hidden;
`;

const TableUser = ({ item, index }) => {
  return (
    <BodyUsersContent
      className="row text-center"
      style={{
        background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
      }}
      key={index}
    >
      <TitleName className="col"> {item.userName} </TitleName>
      <TitleName className="col"> {item.date} </TitleName>
      <TitleName className="col"> {item.ip} </TitleName>
      <TitleName className="col text-start"> {item.browser} </TitleName>
    </BodyUsersContent>
  );
};

export default TableUser;
