import React from "react";
import styled from "styled-components";
import RightSide from "../Assests/RightSide.png";
import Ornament_1 from "../Assests/Ornament_1.png";
import Ornament_2 from "../Assests/Ornament_2.png";
import { Outlet } from "react-router-dom";

const Container = styled.div``;

const Row = styled.div`
  background: #1e1f25;
  max-height: 100vh !important;
`;

const RowLeft = styled.div``;

const RowRight = styled.div`
  position: relative;
`;

const RowLeftImg = styled.img`
  width: 100%;
  height: 100vh;
  object-fit: contain;
`;

const HandImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  width: 398px;
  height: 333px;
`;

const HandImg2 = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 375.33px;
  height: 161px;
`;

const SidePanel = styled.div``;

const StarterHomePage = () => {
  return (
    <Container className="container-fluid p-0">
      <Row className="row g-0">
        <RowLeft className="col-6">
          <RowLeftImg src={RightSide} alt="" />
        </RowLeft>

        <RowRight className="col-6">
          <HandImg src={Ornament_1} />
          <HandImg2 src={Ornament_2} />

          <SidePanel>
            <Outlet />
          </SidePanel>
        </RowRight>
      </Row>
    </Container>
  );
};

export default StarterHomePage;
