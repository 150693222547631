import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Checkbox React Bootstrap
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";

import MailIcon from "../../../../Assests/MailIcon.svg";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaSettings } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const Container = styled.div`
  margin-top: 24px;
  padding: 16px;
  gap: 24px;

  background: #1e1f25;
  border-radius: 20px;
`;

const Row = styled.form``;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
  position: relative;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const ButtonBottom = styled.button`
  width: 100%;
  flex: 1;
  padding: 16px 8px;
  gap: 8px;

  width: 661.5px;
  height: 51px;

  /* Main Color/Royalblue 100 */

  /* background: #246CF9; */
  background: ${(props) => (props.blue ? "#246CF9" : "#3C4254")};
  border-radius: 10px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const InputSpan = styled.img`
  width: 20px;
  height: 18px;
  position: absolute;
  /* right: 2%; */
  right: 5%;
  top: 55%;
  bottom: 12.5%;
  cursor: pointer;
`;

const EmailAyarlariBody = () => {
  const [responseData, setResponseData] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    getSettings();
  }, []);

  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const getSettings = async () => {
    setLoading(true);

    try {
      const response = await axios.get("settings/setting");

      setResponseData(response.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const settingsForm = useFormik({
    initialValues: {
      emailAddress: "",
      emailHost: "",
      emailPassword: "",
      emailPort: "",
      emailSsl: "",
      updatedInfo: "",
      submitType: "test",
    },

    validationSchema: validationSchemaSettings,

    onSubmit: (values, { setSubmitting }) => {
      if (values.submitType === "test") {
        TestSettingsForm(values);
      } else if (values.submitType === "update") {
        UpdateSettingsForm(values);
      }
      setSubmitting(false);
    },
  });

  useEffect(() => {
    settingsForm.setValues({
      emailAddress: responseData.emailAddress ?? "",
      emailHost: responseData.emailHost ?? "",
      emailPassword: responseData.emailPassword ?? "",
      emailPort: responseData.emailPort ?? "",
      emailSsl: responseData.emailSsl ?? "",
      updatedInfo: responseData.updatedInfo ?? "",
      submitType: "test",
    });
  }, [responseData]);

  const UpdateSettingsForm = async (values) => {
    setLoadingOverlay(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`settings/setting`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoadingOverlay(false);
    }
  };

  const TestSettingsForm = async (values) => {
    setLoadingOverlay(true);
    setError("");
    setSuccess("");

    try {
      const response = await axios.post(`settings/emailTest?email=${values.emailAddress}`);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <Container className="container-fluid">
          {loadingOverlay && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
          <Row className="row" onSubmit={settingsForm.handleSubmit}>
            <ModalInputContainer className="col-6">
              <ModalTitle>E-Mail Adresi</ModalTitle>
              <ModalInput
                name="emailAddress"
                value={settingsForm.values.emailAddress}
                onChange={settingsForm.handleChange}
                onBlur={settingsForm.handleBlur}
              />
              {settingsForm.touched.emailAddress && settingsForm.errors.emailAddress ? (
                <AlertTextYup Props={settingsForm.errors.emailAddress} />
              ) : null}
            </ModalInputContainer>
            <ModalInputContainer className="col-6">
              <ModalTitle>E-Mail Host</ModalTitle>
              <ModalInput
                name="emailHost"
                value={settingsForm.values.emailHost}
                onChange={settingsForm.handleChange}
                onBlur={settingsForm.handleBlur}
              />
              {settingsForm.touched.emailHost && settingsForm.errors.emailHost ? (
                <AlertTextYup Props={settingsForm.errors.emailHost} />
              ) : null}
            </ModalInputContainer>

            <ModalInputContainer className="col-6">
              <ModalTitle>E-Mail Şifresi</ModalTitle>
              <ModalInput
                name="emailPassword"
                value={settingsForm.values.emailPassword}
                onChange={settingsForm.handleChange}
                type={showPassword ? "text" : "password"}
                onBlur={settingsForm.handleBlur}
              />
              {settingsForm.touched.emailPassword && settingsForm.errors.emailPassword ? (
                <AlertTextYup Props={settingsForm.errors.emailPassword} />
              ) : null}
              <InputSpan onClick={() => setShowPassword(!showPassword)} src={MailIcon} alt="" />
            </ModalInputContainer>
            <ModalInputContainer className="col-6">
              <ModalTitle>PORT</ModalTitle>
              <ModalInput
                name="emailPort"
                value={settingsForm.values.emailPort}
                onChange={settingsForm.handleChange}
                onBlur={settingsForm.handleBlur}
              />
              {settingsForm.touched.emailPort && settingsForm.errors.emailPort ? (
                <AlertTextYup Props={settingsForm.errors.emailPort} />
              ) : null}
            </ModalInputContainer>

            <ModalInputContainer>
              <ModalTitle>Durumu</ModalTitle>
              <ModalInputSection>
                {/* <ModalInputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    name="emailSsl"
                    checked={settingsForm.values.emailSsl}
                    onChange={() => settingsForm.setFieldValue("emailSsl", !settingsForm.values.emailSsl)}
                  />
                </div>
                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>

            <ButtonContainer>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="test"
                onClick={() => {
                  settingsForm.setFieldValue("submitType", "test");
                }}
              >
                Test Et
              </ButtonBottom>
              <ButtonBottom
                type="submit"
                name="submitType"
                value="update"
                onClick={() => {
                  settingsForm.setFieldValue("submitType", "update");
                }}
                blue
              >
                Ayarları Güncelle
              </ButtonBottom>
            </ButtonContainer>
          </Row>
        </Container>
      )}
    </>
  );
};

export default EmailAyarlariBody;
