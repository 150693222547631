import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import styled from "styled-components";
import axios from "../../../utils/axios";

// CK Editor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";

//Dropdown Bootstrap

// Formik for Form
import { useFormik } from "formik";

// Calender Date Picker Mui
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AlertTextYup from "../../../Components/FormValidations/AlertTextYup";
import { validationSchemaDonation } from "../../../Components/FormValidations/FomikYup";
import Alerts from "../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../Components/PageComponents/OverlayLoading";

const Container = styled.div`
  /* height: 100%; */
`;

const HeaderTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.small ? "10px" : "16px")};
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin: 8px 0px;
`;

const Row = styled.form``;

const RowInside = styled.div`
  position: relative;
  height: 625px;
  padding: 16px;

  display: flex;
  flex-direction: column;
  row-gap: 12px;
  background: #1e1f25;
  border-radius: 20px;
`;

const InputContainer = styled.div`
  margin-bottom: 3px;
`;

const InputContainerInside = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputLeft = styled.input`
  width: 100%;

  padding: 16px;

  background: #282c38;

  border: 1px solid #3c4254;
  border-radius: 10px;
  /* Base Color/Grey 60 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const InputIcon = styled.img`
  width: 24px;
  height: 24px;

  position: absolute;
  right: 8px;

  top: ${(props) => (props.textarea ? "12px" : "")};
`;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const TextareaContainer = styled.div``;

const TextareaContainerInside = styled.div`
  position: relative;
  display: flex;
`;

const TextareaLeft = styled.textarea`
  padding: 16px 32px 16px 16px;
  gap: 8px;

  height: 130px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  width: 100%;

  resize: none;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};

  &::placeholder {
    color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};
  }
`;

const RowLeft = styled.div``;

const RowRight = styled.div``;

const RowInsideTop = styled.div``;

const InputSelectedContainer = styled.div`
  /* margin-top: -1%; */
  display: flex;
  align-items: center;
  padding: 0;
`;

const RowBottom = styled.div`
  padding: 16px;
  /* gap: 16px; */
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  background: #1e1f25;
  border-radius: 20px;
`;

const RowImages = styled.div`
  padding: 8px;
  /* gap: 16px; */

  width: 50%;

  background: #1e1f25;
  border-radius: 20px;
`;

const CalenderContainer = styled.div`
  width: 50%;
`;

const ButtonContainer = styled.button`
  position: absolute;
  height: 42px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #246cf9;
  border-radius: 10px;
  border: none;

  width: 95%;
`;

const Button = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  padding: 9px 10px;
  gap: 8px;

  color: #ffffff;
`;

const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CkEditorSection = styled.div``;

const ButtonContainerBottom = styled.button`
  height: 42px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;

  background: #246cf9;
  border-radius: 10px;
`;

const SelectDropDown = styled.select`
  cursor: pointer;
  width: 100%;

  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #ffffff;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
`;

const UploadImagesContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const UploadImages = styled.img`
  width: 200px;
  height: 200px;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;

const NoneImgContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const NoneImg = styled.img``;

const NoneImgText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const UploadImagesSection = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: scroll;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #282c38;
    border-radius: 12px;
    border: solid 5px #1e1f25;
  }
`;

const MakeDonation = ({}) => {
  const [responseData, setResponseData] = useState({});
  const [responseDataLocation, setResponseDataLocation] = useState([]);
  const [responseDataTypes, setResponseDataTypes] = useState([]);
  const [responseDataRequestStatus, setResponseDataRequestStatus] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    // getAnnouncements();
  };
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const matchedLocation = responseDataLocation.find((location) => location.id === responseData.donationLocationId);
  const placeholderText = matchedLocation ? matchedLocation.name : "";

  const { id } = useParams();

  useEffect(() => {
    donationForm.setValues({
      id: 0,
      donationStatuId: 0,
      donationTypeId: donationForm?.values.donationTypeId,
      donationLocationId: donationForm?.values.donationLocationId,
      name: "",
      tc: donationForm?.values.tc,
      tel: "",
      address: "",
      email: "",
      explanation: "",
      publishTitle: "",
      publishExplanation: "",
      publishDate: dayjs().format("YYYY-MM-DD"),
      image: "",
      imageExtension: "",
      isSelfShipping: donationForm?.values.isSelfShipping,
      isHomeShipping: donationForm?.values.isHomeShipping,
      isPublished: donationForm?.values.isPublished,
      isActive: donationForm?.values.isActive,
    });
    setError("");
    setSuccess("");
  }, [show]);

  useEffect(() => {
    getDonationLocaiton();
    getDonationTypes();
    getDonationRequestStatus();
  }, []);
  const donationForm = useFormik({
    initialValues: {
      id: 0,
      donationStatuId: 0,
      donationTypeId: 0,
      donationLocationId: 0,
      name: "",
      tc: "",
      tel: "",
      address: "",
      email: "",
      explanation: "",
      publishTitle: "",
      publishExplanation: "",
      publishDate: "2024-03-27T07:43:37.746Z",
      image: "",
      imageExtension: "",
      isSelfShipping: true,
      isHomeShipping: true,
      isPublished: false,
      images: [
        {
          id: 0,
          image: "string",
          imageExtension: "string",
        },
      ],
      createdInfo: "string",
      updatedInfo: "string",
      isActive: true,
    },

    validationSchema: validationSchemaDonation,

    onSubmit: async (values) => {
      setLoading(true);
      try {
        const payload = {
          donationStatuId: Number(values.donationStatuId),
          donationTypeId: Number(values.donationTypeId),
          donationLocationId: Number(values.donationLocationId),
          name: values.name,
          tc: Number(values.tc),
          tel: values.tel,
          address: values.address,
          email: values.email,
          explanation: values.explanation,
          publishTitle: values.publishTitle,
          publishExplanation: values.publishExplanation,
          publishDate: values.publishDate,
          isSelfShipping: Boolean(values.isSelfShipping),
          isHomeShipping: Boolean(values.isHomeShipping),
          isPublished: Boolean(values.isPublished),
          isActive: Boolean(values.isActive),
        };
        const response = await axios.post("donations/donation", payload);
        await response;
        setSuccess("Donation submitted successfully!");
      } catch (error) {
        setError("Submission failed. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  console.log("error", donationForm.errors);
  const getDonationLocaiton = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationLocations", {
      params: params,
    });
    try {
      await response;
      setResponseDataLocation(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const getDonationTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationTypes", {
      params: params,
    });
    try {
      await response;
      setResponseDataTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Dropdown Reequest Ulaşıldı..
  const getDonationRequestStatus = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestStatus", {
      params: params,
    });
    try {
      await response;
      setResponseDataRequestStatus(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // UpdatedonationForm Update
  const UpdatedonationForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
        images: null,
      };

      const response = await axios.post(`donations/donation`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading && <OverlayLoading />}
      {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

      <Row className="row" onSubmit={donationForm.handleSubmit}>
        <RowLeft className="col">
          <HeaderTitle>Bağışçının Kişisel Bilgileri</HeaderTitle>
          <RowInside>
            <InputContainer>
              <InputTitle>Ad Soyad</InputTitle>
              <InputContainerInside>
                <InputLeft
                  name="name"
                  value={donationForm.values.name}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </InputContainerInside>
              {donationForm.touched.name && donationForm.errors.name ? (
                <AlertTextYup Props={donationForm.errors.name} />
              ) : null}
            </InputContainer>

            <InputContainer>
              <InputTitle>T.C. Kimlik No</InputTitle>
              <InputContainerInside>
                <InputLeft
                  name="tc"
                  value={donationForm.values.tc}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>Telefon Numarası</InputTitle>
              <InputContainerInside>
                <InputMask
                  mask="5999999999"
                  placeholder="53* *** ** **"
                  name="tel"
                  value={donationForm.values.tel}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    width: "100%",
                    background: "#282c38",
                    border: "1px solid #3c4254",
                    borderRadius: "10px",
                    color: "white",
                  }}
                />
              </InputContainerInside>
              {donationForm.touched.tel && donationForm.errors.tel ? (
                <AlertTextYup Props={donationForm.errors.tel} />
              ) : null}
            </InputContainer>

            <InputContainer>
              <InputTitle>E-Mail Adresi</InputTitle>
              <InputContainerInside>
                <InputLeft
                  name="email"
                  value={donationForm.values.email}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </InputContainerInside>
              {donationForm.touched.email && donationForm.errors.email ? (
                <AlertTextYup Props={donationForm.errors.email} />
              ) : null}
            </InputContainer>

            <InputContainer>
              <InputTitle>Adres</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  name="address"
                  value={donationForm.values.address}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </TextareaContainerInside>
            </InputContainer>
          </RowInside>
        </RowLeft>

        <RowRight className="col">
          <HeaderTitle>Bağış Bilgileri</HeaderTitle>

          <RowInside>
            <RowInsideTop className="row">
              <InputContainer className="col">
                <InputTitle>Bağış Tipi</InputTitle>
                <InputContainerInside>
                  <SelectDropDown
                    aria-label="Default select example"
                    onChange={donationForm.handleChange}
                    name="donationTypeId"
                    value={donationForm.donationTypeId}
                  >
                    <SelectOption disabled value="" selected>
                      Lütfen bir seçim yapın
                    </SelectOption>
                    {responseDataTypes.map((i) => (
                      <SelectOption key={i.id} value={i.id}>
                        {i.name}
                      </SelectOption>
                    ))}
                  </SelectDropDown>
                </InputContainerInside>
              </InputContainer>

              <InputContainer className="col">
                <InputTitle>Bağış Durumu</InputTitle>
                <InputContainerInside>
                  <SelectDropDown
                    aria-label="Default select example"
                    onChange={donationForm.handleChange}
                    name="donationStatuId"
                    value={donationForm.donationStatuId}
                  >
                    <SelectOption disabled value="" selected>
                      Lütfen bir seçim yapın
                    </SelectOption>
                    {responseDataRequestStatus.map((i) => (
                      <SelectOption key={i.id} value={i.id}>
                        {i.name}
                      </SelectOption>
                    ))}
                  </SelectDropDown>
                </InputContainerInside>
              </InputContainer>
            </RowInsideTop>

            <InputContainer>
              <InputTitle>Bağış Konumu</InputTitle>
              <InputContainerInside>
                <SelectDropDown
                  aria-label="Default select example"
                  onChange={donationForm.handleChange}
                  name="donationLocationId"
                  value={donationForm.donationLocationId}
                >
                  <SelectOption disabled value="" selected>
                    Lütfen bir seçim yapın
                  </SelectOption>
                  {responseDataLocation.map((i) => (
                    <SelectOption key={i.id} value={i.id}>
                      {i.name}
                    </SelectOption>
                  ))}
                </SelectDropDown>
              </InputContainerInside>
            </InputContainer>

            <TextareaContainer>
              <InputTitle>Bağış Açıklaması</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft
                  name="explanation"
                  value={donationForm.values.explanation}
                  onChange={donationForm.handleChange}
                  onBlur={donationForm.handleBlur}
                />
              </TextareaContainerInside>
              {donationForm.touched.explanation && donationForm.errors.explanation ? (
                <AlertTextYup Props={donationForm.errors.explanation} />
              ) : null}
            </TextareaContainer>

            <InputTitle selected className="mt-3">
              Kendi Gönderim?
            </InputTitle>

            <InputSelectedContainer className="d-flex gap-2">
              <div>
                <Form.Check
                  type="checkbox"
                  onChange={donationForm.handleChange}
                  name="isSelfShipping"
                  checked={donationForm.values.isSelfShipping}
                />
              </div>
              <HeaderTitle className="ms-1" small>
                KENDİSİ GÖNDERECEK
              </HeaderTitle>
            </InputSelectedContainer>
          </RowInside>
        </RowRight>
      </Row>

      <Row onSubmit={donationForm.handleSubmit}>
        <HeaderTitle className="mt-4 pt-2">Yayın Bilgileri</HeaderTitle>

        <RowBottom>
          <InputTitle>Uygulama Yayınlansın mı?</InputTitle>

          <InputSelectedContainer>
            <div>
              <Form.Check
                type="checkbox"
                onChange={(e) => setCheckedShow(e.target.checked)}
                checked={donationForm.values.isPublished}
                name="isPublished"
              />
            </div>

            <HeaderTitle className="ms-1" small>
              YAYINLANSIN
            </HeaderTitle>
          </InputSelectedContainer>

          {checkedShow && (
            <>
              <InputContainer>
                <InputTitle className="mt-2">Yayın Başlığı</InputTitle>
                <InputContainerInside>
                  <InputLeft
                    name="publishTitle"
                    value={donationForm.values.publishTitle}
                    onChange={donationForm.handleChange}
                    onBlur={donationForm.handleBlur}
                  />
                </InputContainerInside>
              </InputContainer>

              {donationForm.touched.publishTitle && donationForm.errors.publishTitle ? (
                <AlertTextYup Props={donationForm.errors.publishTitle} />
              ) : null}

              <CalenderContainer>
                <InputTitle>Tarih</InputTitle>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      name="publishDate"
                      value={donationForm.values.publishDate}
                      onChange={(date) => donationForm.setFieldValue("publishDate", date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </CalenderContainer>

              <InputContainer className="mt-2">
                <InputTitle>Yayın İçeriği</InputTitle>

                <CkEditorSection className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    data={donationForm.values.publishExplanation || ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      donationForm.setFieldValue("publishExplanation", data);
                    }}
                  />
                </CkEditorSection>
              </InputContainer>

              {donationForm.touched.publishExplanation && donationForm.errors.publishExplanation ? (
                <AlertTextYup Props={donationForm.errors.publishExplanation} />
              ) : null}

              <InputTitle selected className="mt-2">
                Yayın Durumu
              </InputTitle>
              <InputSelectedContainer className="d-flex  align-items-center gap-2 mb-2">
                <div>
                  <Form.Check
                    type="checkbox"
                    onChange={donationForm.handleChange}
                    name="isActive"
                    checked={donationForm.values.isActive}
                  />
                </div>
                <HeaderTitle className="ms-1 " small>
                  AKTİF
                </HeaderTitle>
              </InputSelectedContainer>
            </>
          )}
          <ButtonContainerBottom type="submit" onClick={donationForm.handleSubmit}>
            <Button>Kaydet</Button>
          </ButtonContainerBottom>
        </RowBottom>
      </Row>
    </Container>
  );
};

export default MakeDonation;
