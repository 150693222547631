import axios from './axios'

export const setSession = (accessToken) => {

    if (accessToken) {
      localStorage.setItem('x-token', accessToken);
      localStorage.setItem('x-token-static', '8f7z?25aE52_F9?4k63yBxd2_75yB7!393bd37');
      axios.defaults.headers.common = {
        'x-token' : accessToken,
        'x-token-static' : '8f7z?25aE52_F9?4k63yBxd2_75yB7!393bd37',
      }

    } else {
      localStorage.removeItem('x-token');
      localStorage.removeItem('x-token-static');
      
      delete axios.defaults.headers.common;
    }

  };