import React, { useState } from "react";
import styled from "styled-components";
import Location from "../../../../Assests/Location2.png";
import Arrow from "../../../../Assests/Arrow - Right.png";
import Logo from "../../../../Assests/BBB_Logo.png";
import { NavLink } from "react-router-dom";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  margin-top: 24px;
`;

const Row = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 2%;
`;

const Card = styled.div`
  height: 412px;
  padding: 16px;
  gap: 16px;
  background: #1e1f25;
  border-radius: 20px;
  margin-bottom: 16px;
  width: 18%;
`;

const CardImgContainer = styled.div`
  padding: 16px 0px;
  background: #282c38;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardImg = styled.img`
  width: 68.98px;
  height: 118px;
`;

const CardTitle = styled.div`
  margin-top: 8px;
  height: 57px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  /* Base Color/Grey 20 */

  color: #e5e6ed;
`;

const CardIconContainer = styled.div`
  margin: 8px 0 8px 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CardText = styled.div`
  height: 70px;
  /* margin-bottom: 16px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const CardButton = styled.div`
  margin-top: 16px;
  height: 42px;
  text-align: center;
  padding: 9px 10px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const CardButtonImg = styled.img``;

const BagisNoktalariBody = ({ data, getData, loading }) => {
  return (
    <Container>
      <Row>
        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? (
          <NoneDataError />
        ) : (
          data.map((i) => (
            <Card key={i.id}>
              <CardImgContainer>
                <CardImg src={i.image} />
              </CardImgContainer>
              <CardTitle>{i.name}</CardTitle>
              <CardIconContainer>
                <CardIcon src={Location} />
              </CardIconContainer>
              <CardText>{i.address}</CardText>

              <NavLink
                to={{
                  pathname: `/bagisnoktalari/${i.id}`,
                }}
                style={{ background: "transparent" }}
              >
                <CardButton>
                  Detaylar <CardButtonImg src={Arrow} />{" "}
                </CardButton>
              </NavLink>
            </Card>
          ))
        )}
      </Row>
    </Container>
  );
};

export default BagisNoktalariBody;
