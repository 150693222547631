import React, { useEffect } from "react";
import Add from "../../../../Assests/Add3.png";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaAnnouncement } from "../../../../Components/FormValidations/FomikYup";

// CK Editor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import axios from "../../../../utils/axios";

// Checkbox React Bootstrap
import { useFormik } from "formik";
import Form from "react-bootstrap/Form";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  &:hover {
    background: #246cf9;
  }

  &:focus {
    background: #246cf9;
  }

  &:active {
    background: #246cf9;
  }
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const IconSection = styled.div``;

const HeaderTitle = styled.div`
  margin: 32px 0px;
  color: #ffffff;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const HeaderTitleSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 60 */
  color: #3c4254;
`;

const ModalHeader = styled.div`
  margin-bottom: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelected = styled.input`
  width: 20px;
  height: 20px;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CkEditorSection = styled.div``;

const InputContainer = styled.div``;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const YeniDuyuruModal = ({ getAnnouncements }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getAnnouncements();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    announcementForm.setValues({
      title: "",
      text: "",
      isActive: false,
    });
    setError("");
    setSuccess("");
  }, [show]);

  const announcementForm = useFormik({
    initialValues: {
      title: "",
      text: "",
      isActive: false,
    },

    validationSchema: validationSchemaAnnouncement,

    onSubmit: (values) => {
      UpdateAnnouncementForm(values);
      // getAnnouncements();
      // handleClose();
      // announcementForm.setValues({
      //   title: "",
      //   text: "",
      //   isActive: "",
      // });
    },
  });

  // DontaionForm Update
  const UpdateAnnouncementForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...values,
      };

      const response = await axios.post(`announcements/announcement`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
      handleClose();
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledButton onClick={handleShow}>
        <DropdownIcon src={Add} alt="" />
        <DropdownText>Yeni Duyuru/Bildirim Ekle</DropdownText>
      </StyledButton>

      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container onSubmit={announcementForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
          <ModalHeader>Yeni Duyuru/Bildirim Ekle</ModalHeader>
          <ModalInputContainer>
            <ModalTitle>Duyuru Başlığı</ModalTitle>
            <ModalInput
              name="title"
              value={announcementForm.values.title}
              onChange={announcementForm.handleChange}
              onBlur={announcementForm.handleBlur}
            />
            {announcementForm.touched.title && announcementForm.errors.title ? (
              <AlertTextYup Props={announcementForm.errors.title} />
            ) : null}
          </ModalInputContainer>
          <InputContainer className="mb-4">
            <InputTitle>Duyuru İçeriği</InputTitle>
            <CkEditorSection className="editor">
              <CKEditor
                editor={ClassicEditor}
                data={announcementForm.values.text || ""}
                onChange={(event, editor) => {
                  const data = editor.getData();

                  announcementForm.setFieldValue("text", data);
                }}
              />
            </CkEditorSection>
            {announcementForm.touched.text && announcementForm.errors.text ? (
              <AlertTextYup Props={announcementForm.errors.text} />
            ) : null}
          </InputContainer>
          <ModalInputContainer>
            <ModalTitle>Duyuru Durumu</ModalTitle>
            <ModalInputSection>
              {/* <ModalInputSelected type="checkbox" /> */}
              <Form.Check
                type="checkbox"
                checked={announcementForm.values.isActive}
                onChange={() => {
                  announcementForm.setFieldValue("isActive", !announcementForm.values.isActive);
                }}
              />
              <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
            </ModalInputSection>
          </ModalInputContainer>
          {/* <code>title : {JSON.stringify(announcementForm.values.title)}</code>{" "}
          <br />
          <code>text: {JSON.stringify(announcementForm.values.text)}</code>
          <br />
          <code>
            isActive: {JSON.stringify(announcementForm.values.isActive)}
          </code> */}
          {/* {/* <ModalButton onClick={handleClose}>Duyuruyu Düzenle</ModalButton> */}
          <div>
            {/* <ModalButton type="submit" onClick={()=> {
              announcementForm.handleSubmit()
              handleClose()
              // announcementForm.setValues({
              //   title: "",
              //   text: "",
              //   isActive: "",
              // });
            }} */}
            <ModalButton type="submit" onClick={announcementForm.handleSubmit}>
              Duyuruyu Ekle
            </ModalButton>
          </div>
        </Container>
      </OpenModal>
    </>
  );
};

export default YeniDuyuruModal;
