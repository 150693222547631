import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import styled from "styled-components";
import Dots from "../../../../Assests/Dots.png";

import Danger from "../../../../Assests/Danger.png";
import Delete from "../../../../Assests/Delete.png";
import Edit from "../../../../Assests/Edit2.png";
import Upload from "../../../../Assests/ImageUpload.png";

// Bootstrap Modal
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import axios from "../../../../utils/axios";

import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

// Bootstrap Dropdown
import { useFormik } from "formik";
import Dropdown from "react-bootstrap/Dropdown";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

// Dropdown Islemler

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

// PopUp Modal

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const Row = styled.div``;

const StyledButton = styled(Button)`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;

  background: transparent;
  border: none;

  &:hover {
    background: transparent !important;
  }

  &:focus {
    background: transparent !important;
  }

  &:active {
    background: transparent !important;
  }
`;

const ModalHeader = styled.div`
  margin-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  cursor: pointer;
  border: none;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const AlertSection = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const UploadContainer = styled.div`
  padding: 0;
  /* margin-top: 16px; */
  margin-left: 12px;
  /* gap: 18px; */
  background: rgba(20, 21, 24, 0.7);
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Base Color/Grey 40 */

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const InputUpload = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  height: 100%;
  color: white;
  /* padding: 12px 24px; */
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #757b8c;
  border-radius: 10px;
  cursor: pointer;
`;

const UploadImgBig = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  border: 1px solid #757b8c;
  border-radius: 10px;
`;

const UploadImgSmall = styled.img`
  position: absolute;
  width: 56px;
  height: 56px;
`;

const CodeSection = styled.div`
  overflow-y: scroll;
  max-height: 100px;
  border: 1px solid white;
`;

const TableUser = ({ i, index, checkedAll, getDonationsTypes }) => {
  // const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   setChecked(checkedAll === true ? true : false);
  // }, [checkedAll]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const deleteItem = async () => {
    const params = {
      id: i.id,
    };
    const response = await axios.delete("donationTypes/donationType", {
      params: params,
    });
    try {
      await response;

      getDonationsTypes();
    } catch (error) {
      console.error(error);
    }
  };

  // PopUp Modal
  const [responseData, setResponseData] = useState({});

  // useEffect(() => {
  //   getDonationTypes();
  // }, []);

  const getDonationTypes = async () => {
    const params = {
      id: i.id,
    };

    try {
      const response = await axios.get("donationTypes/donationType", {
        params: params,
      });

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const donationTypeForm = useFormik({
    initialValues: {
      id: "",
      name: "",
      image: null,
      imageExtension: null,
      isActive: "",
    },

    onSubmit: async (values) => {
      await UpdateDonationTypeForm(values);
      // await getDonationsTypes();
      // handleClose();
    },
  });

  useEffect(() => {
    donationTypeForm.setValues({
      id: responseData.id ?? "",
      name: responseData.name ?? "",
      isActive: responseData.isActive ?? "",
      image: null,
      imageExtension: null,
    });
  }, [responseData]);

  // DontaionForm Update
  const UpdateDonationTypeForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`donationTypes/donationType?id=${i.id}`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      donationTypeForm.setFieldValue("image", reader.result.split(",")[1]);

      donationTypeForm.setFieldValue(
        "imageExtension",
        file.type.replace("image/", "") !== "jpg" || "png" ? "png" : file.type.replace("image/", "")
      );
    };
    reader.readAsDataURL(file);
  };

  const URLimg = "data:image/png;base64,";

  // bootstrap modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getDonationsTypes();
  };
  const handleShow = () => setShow(true);

  return (
    <BodyUsersContent
      className="row text-center"
      style={{
        background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
      }}
      // style={{
      //   background:
      //     checked === true
      //       ? "rgba(36, 108, 249, 0.2)"
      //       : index % 2 === 0
      //       ? "rgba(47, 50, 65, 0.5)"
      //       : "#282C38",
      // }}
      key={index}
    >
      {/* <div className="col-1 p-0">
        <Form.Check
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          type="checkbox"
          id={`default-checkbox`}
        />
      </div> */}
      <TitleName className="col ps-5 text-start"> {i.name} </TitleName>
      {i.isActive ? (
        <TitleButton className="col-1">Aktif</TitleButton>
      ) : (
        <TitleButtonRed className="col-1">Pasif</TitleButtonRed>
      )}

      <DropdownSection className="col-1 ">
        <DropDownButton id="dropdown-basic">
          <IconDots src={Dots} />
        </DropDownButton>

        <DropDownMenu>
          <DropDownMenuItem>
            <StyledButton
              onClick={() => {
                handleShow();
                getDonationTypes();
              }}
            >
              <DropdownIcon src={Edit} alt="" />
              <DropdownText>Düzenle</DropdownText>
            </StyledButton>
          </DropDownMenuItem>

          <DropDownMenuItem>
            <DeleteSection onClick={deleteItem}>
              <DropdownIcon src={Delete} alt="" />
              <DropdownText>Sil</DropdownText>
            </DeleteSection>
          </DropDownMenuItem>
        </DropDownMenu>
      </DropdownSection>

      {/* Open Modal */}
      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container className="container-fluid" onSubmit={donationTypeForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
          <Row className="row">
            <ModalHeader>Bağış Tipini Düzenle</ModalHeader>
            <ModalInputContainer className="col">
              <ModalTitle>Bağış Tipi Adı</ModalTitle>
              <ModalInput name="name" value={donationTypeForm.values.name} onChange={donationTypeForm.handleChange} />
            </ModalInputContainer>
            <ModalInputContainer>
              <ModalTitle>Durumu</ModalTitle>
              <ModalInputSection>
                {/* <ModalInputSelected type="checkbox" /> */}
                <Form.Check
                  type="checkbox"
                  name="isActive"
                  checked={donationTypeForm.values.isActive}
                  onChange={() => donationTypeForm.setFieldValue("isActive", !donationTypeForm.values.isActive)}
                />
                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>
            <ModalTitle>Küçük Resim</ModalTitle>

            <UploadContainer>
              <StyledLabel htmlFor="fileUpload">
                {responseData.image && (
                  <UploadImgBig
                    src={donationTypeForm.values.image ? URLimg + donationTypeForm.values.image : responseData?.image}
                  />
                )}

                <UploadImgSmall src={Upload} />
              </StyledLabel>

              <InputUpload type="file" id="fileUpload" onChange={handleImgUpload} />
            </UploadContainer>

            <AlertSection className="col-12">
              <AlertIcon src={Danger} />
              <AlertText>Desteklenen format sadece PNG,JPG’dir.</AlertText>
            </AlertSection>

            {/* ***************************************** */}

            <ModalButton type="submit" onClick={donationTypeForm.handleSubmit}>
              Bağış Tipini Düzenle
            </ModalButton>
          </Row>
        </Container>
      </OpenModal>
    </BodyUsersContent>
  );
};

export default TableUser;
