import axios from "axios";
// config
import { HOST_API_URL } from "../config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API_URL });

axiosInstance.interceptors.request.use((config) => {
  config.params = {
    ...config.params,
  };
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject({
      status: (error.response && error.response.status) || 500,
      statusText: (error.response && error.response.statusText) || "Something went wrong",
    })
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.status || 402 === error.status) {
      localStorage.removeItem("x-token");
      localStorage.removeItem("x-token-static");
      window.location = "/auth/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
