import React from "react";
import styled from "styled-components";

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Alert from "../../../../Assests/Danger.png";
import Lock from "../../../../Assests/Lock.png";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaProfile } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import LoadingTable from "../../../../Components/PageComponents/LoadingTable";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const Container = styled.form``;

const ProfilHeader = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ProfilBilgileri = styled.div`
  padding: 16px;
  gap: 24px;

  background: #1e1f25;
  border-radius: 20px;
`;

const BilgilerTop = styled.div``;

const InputSecitonTop = styled.div``;

const InputSecitonTopRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputName = styled.div`
  margin-bottom: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const InputTop = styled.input`
  width: 100%;
  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #ffffff;

  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const InputIcon = styled.img`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
`;

const BilgilerBottom = styled.div``;

const InputSecitonBottomRow = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const InputSecitonBottom = styled.div``;

const Uyari = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const UyariIcon = styled.img``;

const UyariText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const SifreIslemleriSection = styled.div`
  padding: 16px;
  gap: 24px;

  background: #1e1f25;
  border-radius: 20px;
`;

const SifreButtonSection = styled.div``;

const SifreButton = styled.button`
  padding: 16px 8px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  border: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const CodeSection = styled.div`
  overflow-y: scroll;
  max-height: 100px;
  border: 1px solid white;
`;

const ProfilimBody = () => {
  const [responseData, setResponseData] = useState({});
  const [showPasswordOld, setShowPasswordOld] = useState(false);
  const [showPasswordNew, setShowPasswordNew] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingOverlay, setLoadingOverlay] = useState(false);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getAccount();
  }, []);

  const getAccount = async () => {
    setLoading(true);

    try {
      const response = await axios.get("account/profile");

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const accountForm = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },

    validationSchema: validationSchemaProfile,

    onSubmit: async (values) => {
      UpdatAaccountForm(values);
    },
  });

  // DontaionForm Update
  const UpdatAaccountForm = async (values) => {
    setLoadingOverlay(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
      };

      const response = await axios.put(`account/passwordChange`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoadingOverlay(false);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingTable />
      ) : (
        <Container className="mt-4" onSubmit={accountForm.handleSubmit}>
          {loadingOverlay && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}
          <ProfilHeader className="mb-2">Profil Bilgileri</ProfilHeader>

          <ProfilBilgileri>
            <BilgilerTop className="row">
              <InputSecitonTop className="col">
                <InputName>Ad</InputName>
                <InputSecitonTopRow>
                  <InputTop disabled placeholder={responseData.name} />
                  <InputIcon src={Lock} />
                </InputSecitonTopRow>
              </InputSecitonTop>

              <InputSecitonTop className="col">
                <InputName>Soyad</InputName>
                <InputSecitonTopRow>
                  <InputTop disabled placeholder={responseData.lastname} />
                  <InputIcon src={Lock} />
                </InputSecitonTopRow>
              </InputSecitonTop>
            </BilgilerTop>

            <BilgilerBottom className="row mt-3">
              <InputSecitonBottom className="col">
                <InputName>Kullanıcı Tipi</InputName>
                <InputSecitonBottomRow>
                  <InputTop disabled placeholder={responseData.userTypeName} />
                  <InputIcon src={Lock} />
                </InputSecitonBottomRow>
              </InputSecitonBottom>

              <InputSecitonBottom className="col">
                <InputName>E-Mail</InputName>
                <InputSecitonBottomRow>
                  <InputTop disabled placeholder={responseData.email} />
                  <InputIcon src={Lock} />
                </InputSecitonBottomRow>
              </InputSecitonBottom>

              <InputSecitonBottom className="col">
                <InputName>Kullanıcı Numarası</InputName>
                <InputSecitonBottomRow>
                  <InputTop disabled placeholder={responseData.tel} />
                  <InputIcon src={Lock} />
                </InputSecitonBottomRow>
              </InputSecitonBottom>
            </BilgilerBottom>

            <Uyari>
              <UyariIcon src={Alert} />
              <UyariText className="ms-2">
                Profil ve Hesap Bilgilerinizde değişiklik yapmak için lütfen sistem yöneticiniz ile iletişime geçin.
              </UyariText>
            </Uyari>
          </ProfilBilgileri>

          <ProfilHeader className="mb-2 mt-4">Şifre İşlemleri</ProfilHeader>

          <SifreIslemleriSection>
            <BilgilerBottom className="row">
              <InputSecitonBottom className="col">
                <InputName>Eski Şifreniz</InputName>
                <InputSecitonBottomRow>
                  <InputTop
                    placeholder="Eski Şifrenizi Giriniz"
                    name="currentPassword"
                    value={accountForm.values.currentPassword}
                    onChange={accountForm.handleChange}
                    type={showPasswordOld ? "text" : "password"}
                    onBlur={accountForm.handleBlur}
                  />
                  <InputIcon onClick={() => setShowPasswordOld(!showPasswordOld)} src={Lock} />
                </InputSecitonBottomRow>
                {accountForm.touched.currentPassword && accountForm.errors.currentPassword ? (
                  <AlertTextYup Props={accountForm.errors.currentPassword} />
                ) : null}
              </InputSecitonBottom>

              <InputSecitonBottom className="col">
                <InputName>Yeni Şifreniz</InputName>
                <InputSecitonBottomRow>
                  <InputTop
                    placeholder="Yeni Şifrenizi Giriniz"
                    name="newPassword"
                    value={accountForm.values.newPassword}
                    type={showPasswordNew ? "text" : "password"}
                    onBlur={accountForm.handleBlur}
                    onChange={accountForm.handleChange}
                  />
                  <InputIcon onClick={() => setShowPasswordNew(!showPasswordNew)} src={Lock} />
                </InputSecitonBottomRow>
                {accountForm.touched.newPassword && accountForm.errors.newPassword ? (
                  <AlertTextYup Props={accountForm.errors.newPassword} />
                ) : null}
              </InputSecitonBottom>

              <InputSecitonBottom className="col">
                <InputName>Yeni Şifreniz (Tekrar)</InputName>
                <InputSecitonBottomRow>
                  <InputTop
                    placeholder="Yeni Şifrenizi Tekrar Giriniz"
                    name="newPasswordConfirm"
                    value={accountForm.values.newPasswordConfirm}
                    onBlur={accountForm.handleBlur}
                    type={showPasswordRepeat ? "text" : "password"}
                    onChange={accountForm.handleChange}
                  />
                  <InputIcon onClick={() => setShowPasswordRepeat(!showPasswordRepeat)} src={Lock} />
                </InputSecitonBottomRow>
                {accountForm.touched.newPasswordConfirm && accountForm.errors.newPasswordConfirm ? (
                  <AlertTextYup Props={accountForm.errors.newPasswordConfirm} />
                ) : null}
              </InputSecitonBottom>
            </BilgilerBottom>

            {/* ***************************************** */}
            {/* 
            <div>
              <CodeSection className="mt-3">
                <code>
                  currentPassword :{" "}
                  {JSON.stringify(accountForm.values.currentPassword)}
                </code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  newPassword : {JSON.stringify(accountForm.values.newPassword)}
                </code>{" "}
              </CodeSection>
              <CodeSection>
                <code>
                  newPasswordConfirm :{" "}
                  {JSON.stringify(accountForm.values.newPasswordConfirm)}
                </code>{" "}
              </CodeSection>
            </div> */}

            <SifreButtonSection className="mt-4 w-100">
              <SifreButton type="submit" className="w-100">
                Şifremi Değiştir
              </SifreButton>
            </SifreButtonSection>
          </SifreIslemleriSection>
        </Container>
      )}
    </>
  );
};

export default ProfilimBody;
