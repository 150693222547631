import React, { useState } from 'react'
import HeadersMain from "../../../../Components/PageComponents/HeadersMain"
import Paper from "../../../../Assests/Paper.png"
import GizlilikPolitikasiBody from './GizlilikPolitikasıBody'

const GizlilikPolitikasiHome = () => {

    const [Props, setProps] = useState({
        title: "Gizlilik Politikası",
        img: Paper,
    })

  return (
    <div>
        <HeadersMain Props={Props} />
        <GizlilikPolitikasiBody />
    </div>
  )
}

export default GizlilikPolitikasiHome