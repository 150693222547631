import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setSession } from "../../utils/session";

const AuthGuard = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = typeof window !== "undefined" ? localStorage.getItem("x-token") : "";

    if (accessToken) {
      setSession(accessToken);
    } else {
      setSession(accessToken);
      navigate("/auth/login");
    }
  }, [pathname]);

  return <>{children}</>;
};

export default AuthGuard;
