import React, { useState } from "react";
import User from "../../../../Assests/User3.png";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import GirisListesiBody from "./GirisListesiBody";

import axios from "../../../../utils/axios";
// Pagination Mui
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import styled from "styled-components";
import Navbars from "../../../../Components/PageComponents/NavbarNew";

const PaginationSection = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const GirisListesiHome = () => {
  const [Props, setProps] = useState({
    title: "Kullanıcı Giriş Listesi (LOG’s)",
    img: User,
  });

  const [page, setPage] = useState(1);
  const [endPage, setEndPage] = useState(0);
  const [responseData, setResponseData] = useState([]);
  const [filterParams, setFilterParams] = useState("");
  const [responseTableLogTypes, setResponseTableLogTypes] = useState([]);
  const [responseTables, setResponseTables] = useState([]);
  const [responseUsers, setResponseUsers] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getLogList(filterParams);
  }, [page]);

  const [loading, setLoading] = useState(false);

  const getLogList = async (valuesFilter) => {
    setLoading(true);

    try {
      const params = {
        date1: "1000-02-21",
        date2: "5000-02-21",
        tableLogTypeId: "",
        userId: "",
        tableId: "",
        tableRecordId: "",
        ...valuesFilter,
        page: page,
        take: 20,
      };

      setFilterParams(params);
      const response = await axios.get(
        `reports/userLoginLogList?&userId=${params.userId}&date1=${new Date(params.date1).toDateString(
          "yyyyMMdd"
        )}&date2=${new Date(params.date2).toDateString("yyyyMMdd")}&page=${params.page}&take=${params.take}`
      );

      await response;
      setResponseData(Object.values(response.data));
      setEndPage(Math.ceil(response.headers["x-total-count"] / params.take));
      // setEndPage(response.data.length / params.take);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const FilterCategories = {
    initialValues: {
      date1: yesterday,
      date2: today,
      userId: "",
    },
    getFilterData: getLogList,
    setPage: setPage,
    Users: responseUsers,
  };

  const getUsers = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/users", {
      params: params,
    });
    try {
      await response;
      setResponseUsers(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <HeadersMain Props={Props} />
      <Navbars Props={Props} FilterCategories={FilterCategories} />
      <GirisListesiBody
        data={responseData.length > 0 ? responseData : []}
        getLogList={(filterParams) => getLogList(filterParams)}
        loading={loading}
      />

      <PaginationSection>
        <Stack spacing={2}>
          <Pagination color="primary" count={endPage} page={page} onChange={(event, value) => setPage(value)} />
        </Stack>
      </PaginationSection>
    </div>
  );
};

export default GirisListesiHome;
