import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Delete from "../../../../Assests/Delete.png";
import Dots from "../../../../Assests/Dots.png";
import Edit from "../../../../Assests/Edit2.png";

// aixos
import axios from "../../../../utils/axios";

// Bootstrap Dropdown
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: start;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

const IconSection = styled.div``;

// Dropdown Islemler

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const NavlinkDuzenle = styled(NavLink)`
  padding: 10px 0px 6px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  &.active {
    text-decoration: none;
    background: none;
  }
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const HeaderText = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
`;

const TitleImg = styled.img`
  /* width: 75%; */
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
`;

const Container = styled.form`
  border-radius: 20px;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const TableUser = ({ i, index, checkedAll, getNews }) => {
  const parse = require("html-react-parser");
  const [checked, setChecked] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    setChecked(checkedAll === true ? true : false);
  }, [checkedAll]);

  const deleteItem = async () => {
    const params = {
      id: i.id,
    };
    const response = await axios.delete("news/new", {
      params: params,
    });
    try {
      await response;

      getNews();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <BodyUsersContent
        className="row text-start"
        style={{
          background:
            checked === true ? "rgba(36, 108, 249, 0.2)" : index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
        }}
      >
        {/* <div className="col p-0">
        <Form.Check
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          type="checkbox"
          id={`default-checkbox`}
        />
      </div> */}
        <TitleName className="col">
          {" "}
          <TitleImg src={i.image} alt="" />{" "}
        </TitleName>
        <TitleName className="col"> {i.title} </TitleName>
        <TitleName className="col-5"> {parse(i.text)} </TitleName>
        {/* <TitleName className="col"> {i.olusturmaTarihi} </TitleName> */}
        {i.isActive ? (
          <TitleButton className="col-1">Aktif</TitleButton>
        ) : (
          <TitleButtonRed className="col-1">Pasif</TitleButtonRed>
        )}
        <DropdownSection className="col-1">
          <DropDownButton id="dropdown-basic">
            <IconDots src={Dots} />
          </DropDownButton>

          <DropDownMenu>
            <DropDownMenuItem as={NavlinkDuzenle} to={`/haberler/${i.id}`}>
              <DropdownIcon src={Edit} alt="" />
              <DropdownText>Düzenle</DropdownText>
            </DropDownMenuItem>

            <DropDownMenuItem
              onClick={() => {
                setDeleteModal(true);
              }}
            >
              <DeleteSection>
                <DropdownIcon src={Delete} alt="" />
                <DropdownText>Sil</DropdownText>
              </DeleteSection>
            </DropDownMenuItem>
          </DropDownMenu>
        </DropdownSection>
      </BodyUsersContent>

      <OpenModal show={deleteModal} size="md" centered onHide={() => setDeleteModal(false)} animation={false}>
        <Container>
          <h4 style={{ color: "#ffff", marginBottom: "32px" }}>Silme İstediğinize Emin Misiniz?</h4>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "16px" }}>
            <button
              onClick={() => setDeleteModal(false)}
              style={{
                cursor: "pointer",
                border: "none",
                border: "1px solid #ffff",
                backgroundColor: "transparent",
                borderRadius: "10px",
                color: "#ffff",
                width: "120px",
                padding: "6px",
              }}
            >
              Hayır
            </button>

            <button
              onClick={() => deleteItem()}
              style={{
                cursor: "pointer",
                border: "1px solid #ff0000",
                background: "#ff0000",
                borderRadius: "10px",
                width: "120px",
                padding: "6px",
                color: "#ffff",
              }}
            >
              Evet
            </button>
          </div>
        </Container>
      </OpenModal>
    </>
  );
};

export default TableUser;
