import React, { useEffect } from "react";
import Add from "../../../../Assests/Add2.png";
import Danger from "../../../../Assests/Danger.png";

import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";

import { useFormik } from "formik";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaUsers } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";
import axios from "../../../../utils/axios";

const OpenModal = styled(Modal)`
  backdrop-filter: blur(5px);
  border-radius: 20px;
`;

const Container = styled.form`
  border-radius: 20px;
  width: 100%;
  height: 100%;
  padding: 48px;
  gap: 40px;
  background: #141518;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
`;

const Row = styled.div``;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 10px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;

  &:hover {
    background: #246cf9;
  }

  &:focus {
    background: #246cf9;
  }

  &:active {
    background: #246cf9;
  }
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const IconSection = styled.div``;

const ModalHeader = styled.div`
  margin-bottom: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  /* identical to box height */

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalTitle = styled.div`
  margin-bottom: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const ModalInputContainer = styled.div`
  margin-bottom: 24px;
`;

const ModalInput = styled.input`
  padding: 16px;
  gap: 8px;

  width: 100%;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const ModalInputSection = styled.div`
  display: flex;
  align-items: center;
`;

const ModalInputSelected = styled.input`
  width: 20px;
  height: 20px;
`;

const ModalInputSelectedSpan = styled.span`
  margin-left: 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Base Color/Grey 60 */

  color: #3c4254;
`;

const ModalButton = styled.button`
  border: none;
  cursor: pointer;
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const HaberEkleContent = styled.div`
  display: flex;
  align-items: center;

  height: 42px;

  padding: 9px 10px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;
`;

const HaberEkleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
`;

const HaberEkleIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const AlertSection = styled.div`
  display: flex;
  align-items: center;
`;

const AlertIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 8px;
`;

const AlertText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  /* identical to box height */

  /* Accent Color/Orange */

  color: #ffb545;
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const SelectDropDown = styled.select`
  cursor: pointer;
  width: 100%;

  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #757b8c;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
  color: #ffffff;
`;

const KullaniciEkleModal = ({ item, getUsers }) => {
  const [responseUserTypes, setResponseUserTypes] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    getUserTypes();
  }, []);

  const userForm = useFormik({
    initialValues: {
      name: "",
      lastname: "",
      email: "",
      tel: "",
      isActive: false,
      userTypeId: "",
    },

    validationSchema: validationSchemaUsers,

    onSubmit: async (values) => {
      await UpdateUserForm(values);
      // await getUsers();
      // handleClose();
    },
  });

  const getUserTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/userTypes", {
      params: params,
    });
    try {
      await response;
      setResponseUserTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // DontaionForm Update
  const UpdateUserForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...values,
      };

      const response = await axios.post(`users/user`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      console.error(error);
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    getUsers();
    userForm.resetForm();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    userForm.setValues({
      name: "",
      lastname: "",
      email: "",
      tel: "",
      isActive: false,
      userTypeId: "",
    });
    setError("");
    setSuccess("");
  }, [show]);

  return (
    <>
      <StyledButton onClick={handleShow}>
        <DropdownIcon src={Add} alt="" />
        <DropdownText>Yeni Sistem Kullanıcısı Ekle</DropdownText>
      </StyledButton>

      <OpenModal show={show} size="lg" centered onHide={handleClose} animation={false}>
        <Container className="container-fluid" onSubmit={userForm.handleSubmit}>
          {loading && <OverlayLoading />}
          {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

          <Row className="row">
            <ModalHeader>Yeni Sistem Kullanıcısı Ekle</ModalHeader>

            <ModalInputContainer className="col">
              <ModalTitle>Ad</ModalTitle>
              <ModalInput
                placeholder="Adınızı giriniz .."
                name="name"
                value={userForm.values.name}
                onChange={userForm.handleChange}
                onBlur={userForm.handleBlur}
              />
              {userForm.touched.name && userForm.errors.name ? <AlertTextYup Props={userForm.errors.name} /> : null}
            </ModalInputContainer>
            <ModalInputContainer className="col">
              <ModalTitle>Soyad</ModalTitle>
              <ModalInput
                placeholder="Soyadınızı giriniz .."
                name="lastname"
                value={userForm.values.lastname}
                onChange={userForm.handleChange}
                onBlur={userForm.handleBlur}
              />
              {userForm.touched.lastname && userForm.errors.lastname ? (
                <AlertTextYup Props={userForm.errors.lastname} />
              ) : null}
            </ModalInputContainer>
            <ModalInputContainer className="col-12">
              <ModalTitle>E-Mail Adresi</ModalTitle>
              <ModalInput
                placeholder="Email adresinizi giriniz .."
                name="email"
                value={userForm.values.email}
                onBlur={userForm.handleBlur}
                onChange={userForm.handleChange}
              />
              {userForm.touched.email && userForm.errors.email ? <AlertTextYup Props={userForm.errors.email} /> : null}
            </ModalInputContainer>
            <ModalInputContainer className="col">
              <ModalTitle>Telefon Numarası</ModalTitle>
              <ModalInput
                placeholder="Telefon numaranızı giriniz .."
                name="tel"
                type="tel"
                value={userForm.values.tel}
                onBlur={userForm.handleBlur}
                maxLength="10"
                onChange={(event) => {
                  const inputPhoneNumber = event.target.value;

                  const numericPhoneNumber = inputPhoneNumber.replace(/[^0-9]/g, "");

                  const trimmedPhoneNumber = numericPhoneNumber.slice(0, 10);
                  userForm.setFieldValue("tel", trimmedPhoneNumber);
                }}
              />
              {userForm.touched.tel && userForm.errors.tel ? <AlertTextYup Props={userForm.errors.tel} /> : null}
            </ModalInputContainer>

            <ModalInputContainer className="col">
              <ModalTitle>Kullanıcı Tipi</ModalTitle>
              <SelectDropDown
                aria-label="Default select example"
                onChange={userForm.handleChange}
                name="userTypeId"
                value={userForm.values.userTypeId}
              >
                <SelectOption style={{ display: "none" }} value={""}>
                  Kullanıcı Tipini Seçiniz ..
                </SelectOption>
                {responseUserTypes.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>

              {userForm.touched.userTypeId && userForm.errors.userTypeId ? (
                <AlertTextYup Props={userForm.errors.userTypeId} />
              ) : null}
            </ModalInputContainer>

            <ModalInputContainer>
              <ModalTitle>Durumu</ModalTitle>
              <ModalInputSection>
                {/* <ModalInputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    name="isActive"
                    checked={userForm.values.isActive}
                    onChange={() => userForm.setFieldValue("isActive", !userForm.values.isActive)}
                  />
                </div>
                <ModalInputSelectedSpan>Aktif</ModalInputSelectedSpan>
              </ModalInputSection>
            </ModalInputContainer>

            <AlertSection className="col-12">
              <AlertIcon src={Danger} />
              <AlertText>
                Kullanıcı oluşturulduktan sonra Kullanıcı Şifresi ve gerekli giriş bilgileri girdiğiniz E-Mail adresine
                otomatik olarak gönderilcektir.
              </AlertText>
            </AlertSection>

            <ModalButton type="submit" onClick={userForm.handleSubmit}>
              Değişiklikleri Kaydet
            </ModalButton>
          </Row>
        </Container>
      </OpenModal>
    </>
  );
};

export default KullaniciEkleModal;
