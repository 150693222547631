import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Dots from "../../../Assests/Dots.png";

import Edit from "../../../Assests/Edit2.png";
import Delete from "../../../Assests/Delete.png";

// Bootstrap Dropdown
import Dropdown from "react-bootstrap/Dropdown";

// axios
import axios from "../../../utils/axios";

const BodyUsersContent = styled.div`
  margin: 0px 0px 0px 0px;
  padding: 12px 24px;

  display: flex;
  align-items: center;

  background: #282c38;
`;

const TitleName = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleButton = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(48, 224, 161, 0.3);
  /* Accent Color/Light Green */

  border: 1px solid #30e0a1;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #30e0a1;
`;

const IconDots = styled.img`
  width: 24px;
  height: 24px;
`;

const TitleButtonRed = styled.div`
  zoom: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 31px;
  background: rgba(250, 34, 86, 0.15);
  /* Accent Color/Red Primary */

  border: 1px solid #fa2256;
  border-radius: 5px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-transform: uppercase;

  /* Accent Color/Light Green */

  color: #fa2256;
`;

// Dropdown Islemler

const DropdownSection = styled(Dropdown)``;

const DropDownButton = styled(Dropdown.Toggle)`
  background: none;
  border: none;

  &:hover {
    background: none !important;
  }

  &:active {
    background: none !important;
  }

  &:focus {
    background: none !important;
  }
`;

const DropDownMenu = styled(Dropdown.Menu)`
  padding: 0;

  background: rgba(20, 21, 24, 0.5);
  border: 1px solid #1e1f25;
  box-shadow: 0px 0px 50px rgba(36, 108, 249, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px 0px 10px 10px;
`;

const DropDownMenuItem = styled(Dropdown.Item)`
  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const NavlinkDuzenle = styled(NavLink)`
  padding: 10px 0px 6px 16px;
  display: flex;
  align-items: center;
  gap: 8px;

  text-decoration: none;
  &.active {
    text-decoration: none;
    background: none;
  }
`;

const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const DropdownText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;
`;

const DeleteSection = styled.button`
  background: none;
  width: 100%;
  padding: 6px 0px 6px 0px;
  display: flex;
  border: none;
  align-items: center;
  gap: 8px;
`;

const TableUser = ({ item, index, getData }) => {
  const [checked, setChecked] = useState(false);

  // useEffect(() => {
  //   setChecked(CheckProps.checkedAll === true ? true : false);
  // }, [CheckProps.checkedAll]);

  const deleteItem = async () => {
    const params = {
      id: item.id,
    };
    const response = await axios.delete("donations/donation", {
      params: params,
    });
    try {
      await response;

      getData();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BodyUsersContent
      className="row text-center"
      style={{
        background: index % 2 === 0 ? "rgba(47, 50, 65, 0.5)" : "#282C38",
      }}
    >
      {/* <div className="col p-0">
        <Form.Check
          checked={checked}
          onChange={(event) => setChecked(event.target.checked)}
          onClick={ () => checkFunc(i.id) }
          type="checkbox"
          id={`default-checkbox`}
        />
      </div> */}

      <TitleName className="col"> {item.name} </TitleName>
      <TitleName className="col"> {item.tel} </TitleName>
      <TitleName className="col"> {item.donationRequestChannelName} </TitleName>
      <TitleName className="col"> {item.donationTypeName} </TitleName>
      <TitleName className="col"> {item.donationLocationName} </TitleName>
      <TitleName className="col"> {item.publishTitle} </TitleName>
      <TitleName className="col"> {item.explanation} </TitleName>
      <TitleName className="col"> {item.createdDate} </TitleName>
      {item.publishStatu === "Aktif" ? (
        <TitleButton className="col">Aktif</TitleButton>
      ) : (
        <TitleButtonRed className="col">Pasif</TitleButtonRed>
      )}

      <DropdownSection className="col">
        <DropDownButton id="dropdown-basic">
          <IconDots src={Dots} />
        </DropDownButton>

        <DropDownMenu>
          <DropDownMenuItem as={NavlinkDuzenle} to={`/bagislar/${item.id}`}>
            <DropdownIcon src={Edit} alt="" />
            <DropdownText>Düzenle</DropdownText>
          </DropDownMenuItem>

          <DropDownMenuItem>
            <DeleteSection onClick={deleteItem}>
              <DropdownIcon src={Delete} alt="" />
              <DropdownText>Sil</DropdownText>
            </DeleteSection>
          </DropDownMenuItem>
        </DropDownMenu>
      </DropdownSection>
    </BodyUsersContent>
  );
};

export default TableUser;
