import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import LoadingPage from "../../../Components/PageComponents/OverlayLoading";

import Dots from "../../../Assests/Dots.png";
import { NavLink } from "react-router-dom";

// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import TableUser from "./TableUser";
import LoadingTable from "../../../Components/PageComponents/LoadingTable";
import NoneDataError from "../../../Components/PageComponents/NoneDataError";

const Container = styled.div`
  padding: 0px;
  border-radius: 20px;
`;

const HeaderTitles = styled.div`
  margin: 0px;
  padding: 24px;

  display: flex;
  align-items: center;

  border-radius: 20px 20px 0px 0px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  text-align: center;

  /* Base Color/Grey 40 */

  color: #757b8c;

  background: #1e1f25;
`;

const HeaderTitle = styled.div``;

const BagislarBody = ({ data, getData, checkFunc, checkProps, loading }) => {
  return (
    <>
      <Container className="mt-3">
        <HeaderTitles className="row text-center">
          {/* <div className="col p-0">
            <Form.Check type="checkbox" checked={checkProps.selectedRowsAll} onChange={(event) => checkProps.setSelectedRowsAll(event.target.checked)} id={`default-checkbox`} />
          </div> */}

          <HeaderTitle className="col">Ad</HeaderTitle>
          <HeaderTitle className="col">Telefon</HeaderTitle>
          <HeaderTitle className="col">Bağış Kanalı</HeaderTitle>
          <HeaderTitle className="col">Bağış Tipi</HeaderTitle>
          <HeaderTitle className="col">Bağış Konumu</HeaderTitle>
          <HeaderTitle className="col">Yayın Başlığı</HeaderTitle>
          <HeaderTitle className="col">Açıklama</HeaderTitle>
          <HeaderTitle className="col">Oluşturma Tarihi</HeaderTitle>
          <HeaderTitle className="col">Yayın Durumu</HeaderTitle>
          <HeaderTitle className="col">İşlemler</HeaderTitle>
        </HeaderTitles>

        {loading ? (
          <LoadingTable />
        ) : data.length === 0 ? (
          <NoneDataError />
        ) : (
          data.map((i, index) => (
            <TableUser
              key={i.id}
              item={i}
              index={index}
              getData={getData}
              checkFunc={checkFunc}
            />
          ))
        )}
      </Container>
    </>
  );
};

export default BagislarBody;
