import { createTheme, ThemeProvider } from "@mui/material";
import "./App.css";
import PanelHomeLayout from "./PanelPages/PanelHomeLayout";
import StarterHomePage from "./StarterPages/StarterHomePage";

import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import ForgetPassword from "./StarterPages/ForgetPassword";
import SignIn from "./StarterPages/SignIn";
// import Dashboard from "./PanelPages/PanelCategories/Dashboard/Dashboard";

import AuthGuard from "./Components/AuthGuard-GuestGuard/AuthGuard";
import GuestGuard from "./Components/AuthGuard-GuestGuard/GuestGuard";
import Ayarlar from "./PanelPages/PanelCategories/Ayarlar/Ayarlar";
import EmailAyarlariHome from "./PanelPages/PanelCategories/Ayarlar/EmailAyarlari/EmailAyarlariHome";
import BagisDetaylari from "./PanelPages/PanelCategories/Bagislar/BagisDetaylari/BagisDetaylari";
import Bagislar from "./PanelPages/PanelCategories/Bagislar/Bagislar";
import MakeDonation from "./PanelPages/PanelCategories/Bagislar/MakeDonation";
import BagisNoktalari from "./PanelPages/PanelCategories/BagisNoktalari/BagisNoktalari";
import BagisNoktalariDetaylariHome from "./PanelPages/PanelCategories/BagisNoktalari/BagisNoktalariDetaylari/BagisNoktalariDetaylariHome";
import BagisNoktalariEkleHome from "./PanelPages/PanelCategories/BagisNoktalari/BagisNoktalariEkle/BagisNoktalariEkleHome";
import Dashboard from "./PanelPages/PanelCategories/Dashboard/Dashboard";
import Profilim from "./PanelPages/PanelCategories/Dashboard/Profilim/Profilim";
import Duyurular from "./PanelPages/PanelCategories/Duyurular/Duyurular";
import Haberler from "./PanelPages/PanelCategories/Haberler/Haberler";
import HaberlerDuzenle from "./PanelPages/PanelCategories/Haberler/HaberlerDuzenle/HaberlerDuzenle";
import HaberlerEkle from "./PanelPages/PanelCategories/Haberler/HaberlerEkle/HaberlerEkle";
import BildirimListesiHome from "./PanelPages/PanelCategories/Raporlar/BildirimListesi/BildirimListesiHome";
import GirisListesiHome from "./PanelPages/PanelCategories/Raporlar/GirisListesi/GirisListesiHome";
import HataListesiHome from "./PanelPages/PanelCategories/Raporlar/HataListesi/HataListesiHome";
import Raporlar from "./PanelPages/PanelCategories/Raporlar/Raporlar";
import TabloListesiHome from "./PanelPages/PanelCategories/Raporlar/TabloListesi/TabloListesiHome";
import BaskaninMesajiHome from "./PanelPages/PanelCategories/SabitSayfalar/BaskaninMesaji/BaskaninMesajiHome";
import GizlilikPolitikasiHome from "./PanelPages/PanelCategories/SabitSayfalar/GizlilikPolitikası/GizlilikPolitikasiHome";
import IletisimBilgileriHome from "./PanelPages/PanelCategories/SabitSayfalar/IletisimBilgileri/IletisimBilgileriHome";
import ColloborationsHome from "./PanelPages/PanelCategories/SabitSayfalar/OurCollaborations/ColloborationsHome";
import ProjeninAmaciHome from "./PanelPages/PanelCategories/SabitSayfalar/PorjeninAmaci/ProjeninAmaciHome";
import SabitSayfalar from "./PanelPages/PanelCategories/SabitSayfalar/SabitSayfalar";
import Slider from "./PanelPages/PanelCategories/Slider/SliderHome/Slider";
import BagisTipleriMain from "./PanelPages/PanelCategories/Tanimlar/BagisTipleri/BagisTipleriMain";
import KullanicilarMain from "./PanelPages/PanelCategories/Tanimlar/Kullanicilar/KullanicilarMain";
import Tanimlar from "./PanelPages/PanelCategories/Tanimlar/Tanimlar";
import VatandasFormu from "./PanelPages/PanelCategories/VatandasFormu/VatandasFormu";
import TalepDetaylariHome from "./PanelPages/PanelCategories/YardimTalepleri/TalepDetaylari/TalepDetaylariHome";
import YardimTalepleri from "./PanelPages/PanelCategories/YardimTalepleri/YardimTalepleri";
import { setSession } from "./utils/session";

const theme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

const router = createBrowserRouter([
  {
    path: "auth",
    element: (
      <GuestGuard>
        <StarterHomePage />
      </GuestGuard>
    ),
    children: [
      { element: <Navigate to="/auth/login" />, index: true },
      { path: "login", element: <SignIn /> },
      { path: "forgetpassword", element: <ForgetPassword /> },
    ],
  },
  {
    path: "/",
    element: (
      <AuthGuard>
        <PanelHomeLayout />
      </AuthGuard>
    ),
    children: [
      {
        element: <Dashboard />,
        index: true,
      },
      {
        path: "profilim",
        element: <Profilim />,
      },
      {
        path: "slider",
        element: <Slider />,
      },
      {
        path: "bagislar",
        children: [
          { element: <Bagislar />, index: true },
          { path: ":id", element: <BagisDetaylari /> },
          // { element: <MakeDonation />, index: true },
          { path: ":bagis/yeni", element: <MakeDonation /> },
        ],
      },
      {
        path: "haberler",
        children: [
          { element: <Haberler />, index: true },
          { path: ":id", element: <HaberlerDuzenle /> },
          { element: <Haberler />, index: true },
          { path: "haberlerEkle", element: <HaberlerEkle /> },
        ],
      },
      {
        path: "duyurular",
        element: <Duyurular />,
      },
      {
        path: "yardimtalepleri",
        children: [
          { element: <YardimTalepleri />, index: true },
          { path: ":id", element: <TalepDetaylariHome /> },
        ],
      },
      {
        path: "bagisnoktalari",
        children: [
          { element: <BagisNoktalari />, index: true },
          { path: ":id", element: <BagisNoktalariDetaylariHome /> },
          { path: "ekle", element: <BagisNoktalariEkleHome /> },
        ],
      },
      {
        path: "vatandasformu",
        element: <VatandasFormu />,
      },
      {
        path: "tanimlar",
        children: [
          { element: <Tanimlar />, index: true },
          { path: "kullanicilar", element: <KullanicilarMain /> },

          { path: "bagisTipleri", element: <BagisTipleriMain /> },
        ],
      },
      {
        path: "sabitsayfalar",
        children: [
          { element: <SabitSayfalar />, index: true },
          { path: "baskaninMesaji", element: <BaskaninMesajiHome /> },
          { path: "projeninAmaci", element: <ProjeninAmaciHome /> },
          { path: "gizlilikPolitikasi", element: <GizlilikPolitikasiHome /> },
          { path: "iletisimBilgileri", element: <IletisimBilgileriHome /> },
          { path: "isbirliklerimiz", element: <ColloborationsHome /> },
        ],
      },
      {
        path: "raporlar",
        children: [
          { element: <Raporlar />, index: true },
          { path: "hataListesi", element: <HataListesiHome /> },
          { path: "bildirimListesi", element: <BildirimListesiHome /> },
          { path: "tabloListesi", element: <TabloListesiHome /> },
          { path: "girisListesi", element: <GirisListesiHome /> },
        ],
      },
      {
        path: "ayarlar",
        children: [
          { element: <Ayarlar />, index: true },
          { path: "emailAyarlari", element: <EmailAyarlariHome /> },
        ],
      },
    ],
  },
]);

function App() {
  const accessToken = typeof window !== "undefined" ? localStorage.getItem("x-token") : "";
  setSession(accessToken);

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
