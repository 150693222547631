import React from "react";
import error from "../../Assests/error.png";
const NoneDataError = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center my-4 h-100 py-4 mx-auto">
      <img className="object-fit-contain " alt="" src={error}></img>
      <div className="col-12 primary text-center mt-4 opacity-25">
        Görüntülenecek Sonuç Bulunamadı!
      </div>
    </div>
  );
};

export default NoneDataError;
