import React, { useState } from 'react'
import HeadersPageInside from "../../../../Components/PageComponents/HeadersPagesInside";
import Heart from "../../../../Assests/needHelp.png";
import TalepDetaylariBody from './TalepDetaylariBody';


const TalepDetaylariHome = () => {

  const [Props, setProps] = useState({
    img:Heart,
    title:"Talep Detayları",
    main:"Yardım Talepleri",
    titleText:"Talep Detayları",
  })

  return (
    <div>
      <HeadersPageInside Props={Props} />
      <TalepDetaylariBody />
    </div>
  )
}

export default TalepDetaylariHome