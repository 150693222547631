import React, { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "../../../../utils/axios";

import Edit from "../../../../Assests/Edit.png";
import Lock from "../../../../Assests/Lock.png";

// CK Editor
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";

// Checkbox React Bootstrap
import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";

//Dropdown Bootstrap

// Formik for Form
import { useFormik } from "formik";

// Calender Date Picker Mui
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import AlertTextYup from "../../../../Components/FormValidations/AlertTextYup";
import { validationSchemaDonationDetails } from "../../../../Components/FormValidations/FomikYup";
import Alerts from "../../../../Components/PageComponents/Alerts";
import OverlayLoading from "../../../../Components/PageComponents/OverlayLoading";

const Container = styled.div`
  /* height: 100%; */
`;

const HeaderTitle = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: ${(props) => (props.small ? "10px" : "16px")};
  line-height: 19px;

  /* Base Color/Grey 60 */

  color: #3c4254;
  margin: 8px 0px;
`;

const Row = styled.form``;

const RowInside = styled.div`
  position: relative;
  height: 530px;
  padding: 16px;
  gap: 8px;

  background: #1e1f25;
  border-radius: 20px;
`;

const InputContainer = styled.div``;

const InputContainerInside = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 8px;
`;

const InputLeft = styled.input`
  width: 100%;

  padding: 16px;
  gap: 8px;

  background: #282c38;

  border: 1px solid #3c4254;
  border-radius: 10px;
  /* Base Color/Grey 60 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    /* Base Color/Grey 40 */

    color: #757b8c;
  }
`;

const InputIcon = styled.img`
  width: 24px;
  height: 24px;

  position: absolute;
  right: 8px;

  top: ${(props) => (props.textarea ? "12px" : "")};
`;

const InputTitle = styled.div`
  margin-bottom: ${(props) => (props.selected ? "0px" : "8px")};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const TextareaContainer = styled.div``;

const TextareaContainerInside = styled.div`
  position: relative;
  display: flex;
`;

const TextareaLeft = styled.textarea`
  padding: 16px 32px 16px 16px;
  gap: 8px;

  height: 130px;

  &::-webkit-scrollbar {
    width: 0px;
  }

  width: 100%;

  resize: none;

  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};

  &::placeholder {
    color: ${(props) => (props.white ? "#FFFFFF" : "#757b8c")};
  }
`;

const RowLeft = styled.div``;

const RowRight = styled.div``;

const RowInsideTop = styled.div``;

const InputSelectedContainer = styled.div`
  /* margin-top: -1%; */
  display: flex;
  align-items: center;
  padding: 0;
`;

const RowBottom = styled.div`
  padding: 16px;
  /* gap: 16px; */

  background: #1e1f25;
  border-radius: 20px;
`;

const RowImages = styled.div`
  padding: 8px;
  /* gap: 16px; */

  width: 50%;

  background: #1e1f25;
  border-radius: 20px;
`;

const CalenderContainer = styled.div`
  width: 50%;
`;

const ButtonContainer = styled.button`
  position: absolute;
  height: 42px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #246cf9;
  border-radius: 10px;
  border: none;

  width: 95%;
`;

const Button = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  padding: 9px 10px;
  gap: 8px;

  color: #ffffff;
`;

const ButtonIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CkEditorSection = styled.div``;

const ButtonContainerBottom = styled.button`
  height: 42px;
  bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;

  background: #246cf9;
  border-radius: 10px;
`;

const SelectDropDown = styled.select`
  cursor: pointer;
  width: 100%;

  padding: 16px;
  gap: 8px;
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #ffffff;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
`;

const UploadImagesContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  gap: 8px;
`;

const UploadImages = styled.img`
  width: 200px;
  height: 200px;
  border: 1px solid #e5e6ed;
  border-radius: 10px;
`;

const NoneImgContainer = styled.div`
  padding: 8px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const NoneImg = styled.img``;

const NoneImgText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  /* Base Color/Grey 40 */

  color: #757b8c;
`;

const UploadImagesSection = styled.div`
  gap: 8px;
  display: flex;
  overflow-x: scroll;
  max-width: 100%;

  &::-webkit-scrollbar {
    height: 20px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #282c38;
    border-radius: 12px;
    border: solid 5px #1e1f25;
  }
`;

const BagisDetaylariBody = () => {
  const [responseData, setResponseData] = useState({});
  const [responseDataLocation, setResponseDataLocation] = useState([]);
  const [responseDataTypes, setResponseDataTypes] = useState([]);
  const [responseDataRequestStatus, setResponseDataRequestStatus] = useState([]);
  const [checkedShow, setCheckedShow] = useState(true);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const matchedLocation = responseDataLocation.find((location) => location.id === responseData.donationLocationId);
  const placeholderText = matchedLocation ? matchedLocation.name : "";

  const { id } = useParams();

  const donationForm = useFormik({
    initialValues: {
      id: id,
      isSelfShipping: "",
      donationStatuId: "",
    },

    onSubmit: (values) => {
      UpdateDonationForm(values);
    },
  });

  const donationFormPublish = useFormik({
    initialValues: {
      id: id,
      publishTitle: "",
      publishDate: "",
      publishExplanation: "",
      isPublished: "",
    },

    validationSchema: validationSchemaDonationDetails,

    onSubmit: async (values) => {
      await UpdateDonationFormPublish(values);
    },
  });

  useEffect(() => {
    getDonationLocaiton();
    getDonationTypes();
    getDonationRequestStatus();
    getDonationId();
  }, []);

  const getDonationId = async () => {
    try {
      const params = {
        id: id,
      };
      const response = await axios.get("donations/donation", {
        params: params,
      });

      setResponseData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getDonationLocaiton = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationLocations", {
      params: params,
    });
    try {
      await response;
      setResponseDataLocation(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  const getDonationTypes = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationTypes", {
      params: params,
    });
    try {
      await response;
      setResponseDataTypes(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // Dropdown Reequest Ulaşıldı..
  const getDonationRequestStatus = async () => {
    const params = {
      id: "",
      name: "",
      extra: "",
    };
    const response = await axios.get("dropdowns/donationRequestStatus", {
      params: params,
    });
    try {
      await response;
      setResponseDataRequestStatus(Object.values(response.data));
    } catch (error) {
      console.error(error);
    }
  };

  // DontaionForm Update
  const UpdateDonationForm = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");
    try {
      const params = {
        ...responseData,
        ...values,
        images: null,
      };

      const response = await axios.put(`donations/donation?id=${id}`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  // UpdateDonationFormPublish Update
  const UpdateDonationFormPublish = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const params = {
        ...responseData,
        ...values,
        images: null,
      };

      const response = await axios.put(`donations/donation?id=${id}`, params);
      await response;

      setSuccess("Kayıt Güncelleme Başarılı");
    } catch (error) {
      setError("Kayıt Güncelleme Başarısız!!!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    donationForm.setValues({
      id: id ?? "",
      isSelfShipping: responseData.isSelfShipping ?? "",
      donationStatuId: responseData.donationStatuId ?? "",
      images: responseData.images ?? "",
    });

    donationFormPublish.setValues({
      id: id ?? "",
      publishTitle: responseData.publishTitle ?? "",
      // publishDate:
      //   responseData.publishDate !== null
      //     ? dayjs(responseData.publishDate).toDate()
      //     : "2023-09-11",
      publishDate: dayjs(new Date()),
      publishExplanation: responseData.publishExplanation ?? "",
      isPublished: responseData.isPublished ?? "",
    });
  }, [responseData]);

  return (
    <Container>
      {loading && <OverlayLoading />}
      {error ? <Alerts result={false} /> : success ? <Alerts result={true} /> : <></>}

      {/* <AlertSuccess /> */}

      <Row className="row" onSubmit={donationForm.handleSubmit}>
        <RowLeft className="col">
          <HeaderTitle>Bağışçının Kişisel Bilgileri</HeaderTitle>
          <RowInside>
            <InputContainer>
              <InputTitle>Ad Soyad</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.name}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>T.C. Kimlik No</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.tc === null ? "" : responseData.tc}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>Telefon Numarası</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.tel}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>E-Mail Adresi</InputTitle>
              <InputContainerInside>
                <InputLeft disabled placeholder={`${responseData.email === null ? "" : responseData.email}`} />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <InputContainer>
              <InputTitle>Adres</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft disabled placeholder={`${responseData.address}`} />
                <InputIcon textarea src={Lock} />
              </TextareaContainerInside>
            </InputContainer>
          </RowInside>
        </RowLeft>

        <RowRight className="col">
          <HeaderTitle>Bağış Bilgileri</HeaderTitle>

          <RowInside>
            <RowInsideTop className="row">
              <InputContainer className="col">
                <InputTitle>Bağış Tipi</InputTitle>
                <InputContainerInside>
                  <InputLeft
                    disabled
                    placeholder={responseDataTypes
                      .map((i) => (i.id === responseData.donationTypeId ? `${i.name}` : ``))
                      .join("")}
                  />
                  <InputIcon src={Lock} />
                </InputContainerInside>
              </InputContainer>

              <InputContainer className="col">
                <InputTitle>Bağış Durumu</InputTitle>

                <SelectDropDown
                  aria-label="Default select example"
                  onChange={donationForm.handleChange}
                  name="donationStatuId"
                  value={donationForm.values.donationStatuId}
                >
                  {responseDataRequestStatus.map((i) => (
                    <SelectOption key={i.id} value={i.id}>
                      {i.name}
                    </SelectOption>
                  ))}
                </SelectDropDown>
              </InputContainer>
            </RowInsideTop>

            <InputContainer>
              <InputTitle>Bağış Konumu</InputTitle>
              <InputContainerInside>
                <InputLeft
                  // placeholder={`${responseDataLocation[0].name}`}
                  disabled
                  placeholder={placeholderText}
                  style={{ padding: "16px 15px 16px 24px" }}
                />
                <InputIcon src={Lock} />
              </InputContainerInside>
            </InputContainer>

            <TextareaContainer>
              <InputTitle>Bağış Açıklaması</InputTitle>
              <TextareaContainerInside>
                <TextareaLeft disabled placeholder={`${responseData.explanation}`} />
                <InputIcon textarea src={Lock} />
              </TextareaContainerInside>
            </TextareaContainer>

            <InputTitle selected className="mt-3">
              Kendi Gönderim?
            </InputTitle>

            <InputSelectedContainer className="d-flex gap-2">
              <div>
                <Form.Check
                  type="checkbox"
                  onChange={donationForm.handleChange}
                  name="isSelfShipping"
                  checked={donationForm.values.isSelfShipping}
                />
              </div>
              <HeaderTitle className="ms-1" small>
                KENDİSİ GÖNDERECEK
              </HeaderTitle>
            </InputSelectedContainer>

            <ButtonContainer type="submit">
              <Button>Bağış Durumunu Güncelle</Button>
              <ButtonIcon src={Edit} />
            </ButtonContainer>

            {/* <code>{JSON.stringify(donationForm.values)}</code> */}
          </RowInside>
        </RowRight>
      </Row>

      {/* <HeaderTitle className="mt-4">İletilen Fotoğraflar</HeaderTitle>

      <RowImages className="mt-3 text-center">
        {donationForm.values.images?.length ? (
          <UploadImagesContainer>
            <UploadImagesSection>
              {donationForm.values.images.map((image, index) => (
                <UploadImages
                  key={image.id}
                  src={image.image}
                  alt={`Image ${index}`}
                />
              ))}
            </UploadImagesSection>
          </UploadImagesContainer>
        ) : (
          <NoneImgContainer>
            <NoneImg src={NoneImage} />
            <NoneImgText>
              Talebi oluşturan kişi tarafından iletilen herhangi bir fotoğraf
              bulunmamaktadır.
            </NoneImgText>
          </NoneImgContainer>
        )}
      </RowImages> */}

      <Row onSubmit={donationFormPublish.handleSubmit}>
        <HeaderTitle className="mt-4">Yayın Bilgileri</HeaderTitle>

        <RowBottom>
          <InputTitle>Uygulama Yayınlansın mı?</InputTitle>

          <InputSelectedContainer>
            {/* <InputSelected type="checkbox" /> */}
            <div>
              <Form.Check type="checkbox" onChange={(e) => setCheckedShow(e.target.checked)} checked={checkedShow} />
            </div>

            <HeaderTitle className="ms-1" small>
              YAYINLANSIN
            </HeaderTitle>
          </InputSelectedContainer>

          {checkedShow && (
            <>
              <InputContainer>
                <InputTitle className="mt-2">Yayın Başlığı</InputTitle>
                <InputContainerInside>
                  <InputLeft
                    name="publishTitle"
                    value={donationFormPublish.values.publishTitle}
                    onChange={donationFormPublish.handleChange}
                    onBlur={donationFormPublish.handleBlur}
                  />
                </InputContainerInside>
              </InputContainer>

              {donationFormPublish.touched.publishTitle && donationFormPublish.errors.publishTitle ? (
                <AlertTextYup Props={donationFormPublish.errors.publishTitle} />
              ) : null}

              <CalenderContainer>
                <InputTitle>Tarih</InputTitle>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <DesktopDatePicker
                      inputFormat="DD/MM/YYYY"
                      name="publishDate"
                      value={donationFormPublish.values.publishDate}
                      onChange={(date) => donationFormPublish.setFieldValue("publishDate", date)}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Stack>
                </LocalizationProvider>
              </CalenderContainer>

              <InputContainer className="mt-2">
                <InputTitle>Yayın İçeriği</InputTitle>

                <CkEditorSection className="editor">
                  <CKEditor
                    editor={ClassicEditor}
                    data={donationFormPublish.values.publishExplanation || ""}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      donationFormPublish.setFieldValue("publishExplanation", data);
                    }}
                  />
                </CkEditorSection>
              </InputContainer>

              {donationFormPublish.touched.publishExplanation && donationFormPublish.errors.publishExplanation ? (
                <AlertTextYup Props={donationFormPublish.errors.publishExplanation} />
              ) : null}

              <InputTitle selected className="mt-2">
                Yayın Durumu
              </InputTitle>
              <InputSelectedContainer className="d-flex  align-items-center gap-2 mb-2">
                {/* <InputSelected type="checkbox" /> */}
                <div>
                  <Form.Check
                    type="checkbox"
                    onChange={donationFormPublish.handleChange}
                    name="isPublished"
                    checked={donationFormPublish.values.isPublished}
                  />
                </div>
                <HeaderTitle className="ms-1 " small>
                  AKTİF
                </HeaderTitle>
              </InputSelectedContainer>
            </>
          )}
        </RowBottom>
        <ButtonContainerBottom type="submit" className="mt-2 ">
          <Button>Yayın Bilgilerini Kaydet ve Bağışı Uygulamada Yayımla</Button>
          <ButtonIcon src={Edit} />
        </ButtonContainerBottom>
      </Row>
    </Container>
  );
};

export default BagisDetaylariBody;
