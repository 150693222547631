import { useFormik } from "formik";
import React from "react";
import styled from "styled-components";

import Add from "../../Assests/Add2.png";
import FilterIcon from "../../Assests/Stroke 3.png";

import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";

// Calender Date Picker Mui
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import "dayjs/locale/tr";
import { NavLink } from "react-router-dom";

const Container = styled.div`
  // margin-top: 12px;
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  gap: 12px;
`;

const RowSection = styled.div`
  margin: 0;
  padding: 0;
`;

const ButtonPopOver = styled(Button)`
  padding: 12px 16px !important;
  gap: 10px !important;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #ffffff !important;
  border-radius: 10px !important;

  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff !important;

  &:hover {
    background: #1e1f25 !important;
  }
`;

const DropDownMenuItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 8px;
  gap: 8px;
  /* justify-content: space-around; */
  // width: 840px;
  max-width: 940px;

  &:hover {
    background: none;
  }
  &:active {
    background: none;
  }
  &:focus {
    background: none;
  }
`;

const DropInputSection = styled.div`
  flex-basis: 30%;
  flex-grow: 1;
`;

const DropInput = styled.input`
  height: 56px;
  width: 100%;
  padding: 12px;
  /* gap: 8px; */
  background: #282c38;
  /* Base Color/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base Color/Grey 0 - White */

  color: #ffffff;

  ::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #757b8c;
  }
`;

const SelectDropDown = styled.select`
  flex-basis: 40%;
  flex-grow: 1;
  height: 56px;
  max-width: 200px;
  cursor: pointer;
  /* padding: 12px 16px; */
  padding: 12px;
  /* gap: 16px; */
  background: #282c38;
  /* Base/Grey 60 */

  border: 1px solid #3c4254;
  border-radius: 10px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  /* Base/Grey 40 */

  color: #757b8c;

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #3c4254;
    background: #282c38;
  }
`;

const SelectOption = styled.option`
  padding: 12px 16px;
  cursor: pointer;
`;

const CalendersNavbar = styled.div`
  display: flex;
  width: 840px;
  gap: 8px;
  max-width: 840px;
`;

const CalenderContainer = styled.div`
  width: 59%;
`;

const FormButton = styled.button`
  width: 20%;
  margin: 8px;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 8px;
  background: #246cf9;
  border-radius: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  border: none;
  color: #ffffff;
`;

const HaberEkleContent = styled.div`
  display: flex;
  align-items: center;

  height: 42px;

  padding: 9px 10px;
  gap: 8px;

  background: #246cf9;
  border-radius: 10px;
`;

const HaberEkleText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #ffffff;
`;

const HaberEkleIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const PopUp = styled.div`
  padding: 0;
  margin: 0;
`;

const NavbarNew = ({ Props, FilterCategories }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const FilterForm = useFormik({
    initialValues: {
      ...FilterCategories.initialValues,
      createdDate: null,
      submitType: "search",
    },

    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (values.submitType === "search") {
        FilterCategories.setPage(1);

        FilterCategories.getFilterData(values);
        handleClose();
      } else if (values.submitType === "clear") {
        resetForm();
      }
      setSubmitting(false);
    },
  });

  return (
    <Container>
      <div></div>
      <Row>
        <RowSection>
          <ButtonPopOver
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
            open={open}
            sx={{ background: open ? "#246CF9" : "#1E1F25" }}
          >
            <img src={FilterIcon} className="me-1" alt="" />
            Filtrele
          </ButtonPopOver>
        </RowSection>

        {Props.navbarButtonTitle && (
          <RowSection>
            <NavLink
              to={{
                pathname: Props.path,
              }}
              style={{ background: "none", textDecoration: "none" }}
            >
              <HaberEkleContent>
                <HaberEkleText>{Props.navbarButtonTitle}</HaberEkleText>
                <HaberEkleIcon src={Add} />
              </HaberEkleContent>
            </NavLink>
          </RowSection>
        )}

        {Props.popUp && (
          <RowSection>
            {" "}
            <PopUp>{Props.popUp}</PopUp>
          </RowSection>
        )}
      </Row>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            background: "rgba(20, 21, 24, 0.7)",
            borderRadius: "10px 0px 10px 10px",
            backdropFilter: "blur(10px)",
            border: "1px solid #246CF9",
            padding: "24px",
            margin: "12px 0px 0px -20px",
          },
        }}
      >
        <form onSubmit={FilterForm.handleSubmit}>
          <DropDownMenuItem>
            {FilterCategories.initialValues.name === "" && (
              <DropInputSection>
                <DropInput
                  name="name"
                  value={FilterForm.values.name}
                  onChange={FilterForm.handleChange}
                  placeholder="Ad ile arama yapınız..."
                />
              </DropInputSection>
            )}

            {FilterCategories.initialValues.lastname === "" && (
              <DropInputSection>
                <DropInput
                  name="lastname"
                  value={FilterForm.values.lastname}
                  onChange={FilterForm.handleChange}
                  placeholder="Soyad ile arama yapınız..."
                />
              </DropInputSection>
            )}

            {FilterCategories.initialValues.email === "" && (
              <DropInputSection>
                <DropInput
                  name="email"
                  value={FilterForm.values.email}
                  onChange={FilterForm.handleChange}
                  placeholder="Email ile arama yapınız..."
                />
              </DropInputSection>
            )}

            {FilterCategories.initialValues.sent === "" && (
              <DropInputSection>
                <DropInput
                  name="sent"
                  value={FilterForm.values.sent}
                  onChange={FilterForm.handleChange}
                  placeholder="Gönderilen ile arama yapınız..."
                />
              </DropInputSection>
            )}

            {FilterCategories.addressInput && (
              <DropInputSection>
                <DropInput
                  name="address"
                  value={FilterForm.values.address}
                  onChange={FilterForm.handleChange}
                  placeholder={FilterCategories.addressInput}
                />
              </DropInputSection>
            )}

            {FilterCategories.titleInput && (
              <DropInputSection>
                <DropInput
                  name="title"
                  value={FilterForm.values.title}
                  onChange={FilterForm.handleChange}
                  placeholder={FilterCategories.titleInput}
                />
              </DropInputSection>
            )}

            {FilterCategories.tcInput && (
              <DropInputSection>
                <DropInput
                  name="tc"
                  value={FilterForm.values.tc}
                  // onChange={FilterForm.handleChange}
                  type="number"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (!isNaN(value)) {
                      FilterForm.setFieldValue("tc", parseInt(value, 10));
                    }
                  }}
                  placeholder={FilterCategories.tcInput}
                />
              </DropInputSection>
            )}

            {FilterCategories.baslikInput && (
              <DropInputSection>
                <DropInput
                  name="publishTitle"
                  value={FilterForm.values.publishTitle}
                  onChange={FilterForm.handleChange}
                  placeholder={FilterCategories.baslikInput}
                />
              </DropInputSection>
            )}
          </DropDownMenuItem>

          {/* Dropdowns */}
          <DropDownMenuItem>
            {FilterCategories.initialValues.isPublished === "" && FilterCategories.yayinDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="isPublished"
                value={FilterForm.values.isPublished}
              >
                <SelectOption value={""}>Yayın Durumu (Tümü)</SelectOption>
                {FilterCategories.yayinDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.durum}>
                    {i.title}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.userTypes && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="userTypeId"
                value={FilterForm.values.userTypeId}
              >
                <SelectOption value={""}>Kullanıcı Tipi (Tümü)</SelectOption>
                {FilterCategories.userTypes.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.NotificationLogTypes && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="notificationLogTypeId"
                value={FilterForm.values.notificationLogTypeId}
              >
                <SelectOption value={""}>Tip (Tümü)</SelectOption>
                {FilterCategories.NotificationLogTypes.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.Users && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="userId"
                value={FilterForm.values.userId}
              >
                <SelectOption value={""}>Kullanıcı (Tümü)</SelectOption>
                {FilterCategories.Users.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.TableLogTypes && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="tableLogTypeId"
                value={FilterForm.values.tableLogTypeId}
              >
                <SelectOption value={""}>Tip (Tümü)</SelectOption>
                {FilterCategories.TableLogTypes.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.Tables && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="tableId"
                value={FilterForm.values.tableId}
              >
                <SelectOption value={""}>Tablo (Tümü)</SelectOption>
                {FilterCategories.Tables.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name} - {i.id}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.isActive === "" && FilterCategories.yayinDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="isActive"
                value={FilterForm.values.isActive}
              >
                <SelectOption value={""}>Yayın Durumu (Tümü)</SelectOption>
                {FilterCategories.yayinDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.durum}>
                    {i.title}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.donationRequestChannelId === "" && FilterCategories.kanalDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="donationRequestChannelId"
                value={FilterForm.values.donationRequestChannelId}
              >
                <SelectOption value={""}>Kanal (Tümü)</SelectOption>
                {FilterCategories.kanalDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.donationTypeId === "" && FilterCategories.tipDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="donationTypeId"
                value={FilterForm.values.donationTypeId}
              >
                <SelectOption value={""}>Tip (Tümü)</SelectOption>
                {FilterCategories.tipDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.donationRequestStatuId === "" && FilterCategories.talepDurumuDropwn && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="donationRequestStatuId"
                value={FilterForm.values.donationRequestStatuName}
              >
                <SelectOption value={""}>Talep Durumu</SelectOption>
                {FilterCategories.talepDurumuDropwn.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.id === 3 ? "Ulaşılamadı" : `${i.name} `}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.donationLocationId === "" && FilterCategories.konumDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="donationLocationId"
                value={FilterForm.values.donationLocationId}
              >
                <SelectOption value={""}>Konum (Tümü)</SelectOption>
                {FilterCategories.konumDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.donationStatuId === "" && FilterCategories.durumDropDown && (
              <SelectDropDown
                aria-label="Default select example"
                onChange={FilterForm.handleChange}
                name="donationStatuId"
                value={FilterForm.values.donationStatuId}
              >
                <SelectOption value={""}>Durum (Tümü)</SelectOption>
                {FilterCategories.durumDropDown.map((i) => (
                  <SelectOption key={i.id} value={i.id}>
                    {i.name}
                  </SelectOption>
                ))}
              </SelectDropDown>
            )}

            {FilterCategories.initialValues.createdDate && (
              <DropDownMenuItem>
                <CalenderContainer>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                    <Stack spacing={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          name="createdDate"
                          value={FilterForm.values.createdDate}
                          onChange={(createdDate) =>
                            FilterForm.setFieldValue("createdDate", dayjs(createdDate).format("YYYY-MM-DD"))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                input: { color: "white", backgroundColor: "#282c38" },
                                "& .MuiOutlinedInput-notchedOutline": {
                                  borderColor: "#3c4254",
                                },
                              }}
                              InputLabelProps={{
                                style: { color: "white" },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </LocalizationProvider>
                </CalenderContainer>
              </DropDownMenuItem>
            )}
          </DropDownMenuItem>

          {FilterCategories.dateInput && (
            <DropDownMenuItem>
              <CalenderContainer>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                      <DesktopDatePicker
                        inputFormat="DD/MM/YYYY"
                        name="publishDate"
                        // value={donationFormPublish.values.publishDate}
                        // onChange={(date) =>
                        //   donationFormPublish.setFieldValue("publishDate", date)
                        // }

                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                  </Stack>
                </LocalizationProvider>
              </CalenderContainer>
            </DropDownMenuItem>
          )}

          {FilterCategories.initialValues.date1 && FilterCategories.initialValues.date2 && (
            <DropDownMenuItem>
              <CalendersNavbar>
                <CalenderContainer>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          name="date1"
                          value={FilterForm.values.date1}
                          onChange={(date) => FilterForm.setFieldValue("date1", date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </LocalizationProvider>
                </CalenderContainer>

                <CalenderContainer>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
                        <DesktopDatePicker
                          inputFormat="DD/MM/YYYY"
                          name="date2"
                          value={FilterForm.values.date2}
                          onChange={(date) => FilterForm.setFieldValue("date2", date)}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </LocalizationProvider>
                </CalenderContainer>
              </CalendersNavbar>
            </DropDownMenuItem>
          )}

          <FormButton
            type="submit"
            name="submitType"
            value="search"
            onClick={() => {
              FilterForm.setFieldValue("submitType", "search");
            }}
            style={{ width: "100px", padding: "16px 5px" }}
          >
            Arama Yap
          </FormButton>
          <FormButton
            type="submit"
            name="submitType"
            value="clear"
            onClick={() => {
              FilterForm.setFieldValue("submitType", "clear");
            }}
            style={{ background: "red", width: "150px", padding: "16px 5px" }}
          >
            Filtreleme temizle
          </FormButton>
        </form>
      </Popover>
    </Container>
  );
};

export default NavbarNew;
