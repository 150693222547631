import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MailIcon from "../Assests/MailIcon.svg";
import AlertTextYup from "../Components/FormValidations/AlertTextYup";
import { validationSchemaResetPassword } from "../Components/FormValidations/FomikYup";
import axios from "../utils/axios";

const Container = styled.div`
  background: #1e1e1e;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Row = styled.form``;

const HeaderTitle = styled.h1`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  color: #3c4254;
`;
const InputContainer = styled.div`
  position: relative;
`;
const Input = styled.input`
  box-sizing: border-box;
  padding: 16px 16px 16px 12%;
  gap: 8px;
  width: 416px;
  height: 56px;
  background: #1e1f25;
  border: 1px solid #3c4254;
  border-radius: 10px;

  /* Input Text */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #757b8c;
  }
`;

const InputSpanImg = styled.img`
  width: 20px;
  height: 18px;
  position: absolute;
  left: 5%;
  right: 8.33%;
  top: 34%;
  bottom: 12.5%;
`;

const Buttons = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 8px;
  gap: 8px;
  border: none;

  width: 416px;
  height: 51px;

  background: #246cf9;
  border-radius: 10px;
`;

const ButtonSpan = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #ffffff;
`;

const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const loginForm = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: validationSchemaResetPassword,

    onSubmit: async (values) => {
      loginFormSend(values);
    },
  });

  // loginForm Update
  const loginFormSend = async (values) => {
    setLoading(true);
    try {
      const headers = {
        "x-token-static": "8f7z?25aE52_F9?4k63yBxd2_75yB7!393bd37",
      };
      const response = await axios.post(`account/passwordReminder`, values, {
        headers: headers,
      });
      await response;
      setSuccess("Mesaj gönderildi");
    } catch (error) {
      setError("E-Mail adresi kayıtlı değil");
      // setError(error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="d-flex flex-column justify-content-between">
      <Row
        onSubmit={loginForm.handleSubmit}
        className="d-flex flex-column align-item-center justify-content-center vh-100 ;
        
        "
      >
        <HeaderTitle>Şifrenizi mi unuttunuz ?</HeaderTitle>
        <InputContainer className="my-4">
          <InputSpanImg src={MailIcon} alt="" />
          <Input
            name="email"
            value={loginForm.values.email}
            onChange={loginForm.handleChange}
            onBlur={loginForm.handleBlur}
            placeholder="Sisteme Kayıtlı Mail Adresiniz"
          />
        </InputContainer>
        {loginForm.touched.email && loginForm.errors.email ? <AlertTextYup Props={loginForm.errors.email} /> : null}
        {loading && <p className="text-primary">Loading...</p>}
        {error && <p className="text-danger">{error}</p>}
        {success && <p className="text-success">{success}</p>}
        <Buttons type="submit">
          <ButtonSpan>Şifremi Sıfırla</ButtonSpan>
        </Buttons>
      </Row>
      <Link
        to={"/auth/login"}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
          background: "transparent",
          color: "transparent",
        }}
      >
        <Button className="border-0 bg-transparent">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="white"
            class="bi bi-arrow-left"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
            />
          </svg>
        </Button>
      </Link>
    </Container>
  );
};

export default ForgetPassword;
