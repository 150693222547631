import React, { useState } from "react";
import HeadersMain from "../../../../Components/PageComponents/HeadersMain";
import Profile from "../../../../Assests/Profile.png";
import CollaborationsBody from "./CollaborationsBody";

const ColloborationsHome = () => {
  const [Props, setProps] = useState({
    title: "İşbirliklerimiz",
    img: Profile,
  });

  return (
    <div className="">
      <HeadersMain Props={Props} />
      <CollaborationsBody />
    </div>
  );
};

export default ColloborationsHome;
